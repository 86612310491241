.receive-card {
    border-radius: 8px;
    max-width: 300px;
  }
  
  .card-item {
    display: flex;
    margin-bottom: 1px;
    gap: 5px;
    font-size: 14px;
    color: #333;
  }
  
  .card-item:last-child {
    margin-bottom: 0;
  }
  
  .icon {
    font-size: 20px;
    margin-right: 8px;
    color: #666;
  }
  