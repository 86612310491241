.authorizationModal .ant-modal-content {
	width: 24.37rem !important;
}
.authorizationModal .ant-modal {
	width: 24.37rem !important;
}

.authorization-sign-in {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	/* height: 100vh;  */
	/* width: 24.37rem !important;
  margin: auto;
  height: 27.8rem;
  flex-grow: 0;
  padding: 2.375rem 1.87rem 1rem;
  border-radius: 4px;
  background-color: #fff; */
}
.sub-div {
	display: flex;
	width: 80%;
	max-width: 800px;
	padding-top: 30px;
}
.sg-div {
	flex: 1;
	padding: 20px 20px 0px 20px;
	border: 1px solid #e4e4e4;
}
.sg-heading {
	font-size: 28px;
	font-weight: 600;
	line-height: 38px;
	text-align: center;
}
.sg-sub-heading {
	font-size: 18px;
	font-weight: 500;
	line-height: 20px;
	text-align: center;
	color: #5e5e5e;
}
.scan-text {
	font-size: 16px;
	font-weight: 400;
	line-height: 19.36px;
	text-align: center;
	color: #5e5e5e;
	padding-top: 5px;
}
.download-text {
	font-size: 16px;
	font-weight: 500;
	line-height: 19.36px;
	color: #5e5e5e;
	padding-top: 6px;
	padding-left: 12px;
}
.sg-icons {
	padding-top: 5px;
	width: 100%;
	display: flex;
	justify-content: space-around;
}
/* @media screen and (max-width: 768px) {
  .authorization-sign-in {
    width: 100% !important;
    position: relative;
    top: -10px;
  }
} */
@media (max-width: 768px) {
	.sub-div {
		flex-direction: column;
	}

	.sg-div:first-child {
		display: none;
		height: 0;
	}

	.sg-div:last-child {
		flex: 0;
		margin: auto;
		width: 100% !important;
		position: relative;
		border: none;
    margin-top: -20px;
	}
}
