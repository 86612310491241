.radioInputParentContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.radioInputTitleContainer {
  display: flex;
  /* align-items: center; */
}
.radioInputTitleContainer-cell{
  display: flex;
  /* flex-direction: column; */
  align-items: center;
}
.radioInputTitle {
  color: #212121;
  font-weight: bolder;
  font-size: 1.5rem;
  margin-right: 1.5rem;
}
.radioInputType {
  position: absolute;
  margin-top: 10px;
  color: #7E7E7E;
  /* margin-left: 0.7rem; */
  font-size: 0.625rem;
  font-weight: 400;

}

.radioInputType-cell {
  color: #7E7E7E;
  /* margin-left: 0.7rem; */
  margin-top: 0.4rem;
  font-size: 0.625rem;
  font-weight: 400;

}
.radioInputContainer {
  width: 50%;
}

.radioinput-styles .ant-radio-inner{
  height: 1.5rem;
  width: 1.5rem;
}
.radioinput-styles .ant-radio-checked .ant-radio-inner::after{
  width: 1.5rem;
  height: 1.5rem;
}
.radioinput-styles .ant-radio-inner::after {
  margin-top: -.75rem;
  margin-left: -.75rem;
}
.radioinput-styles .ant-radio-checked::after {
  border: .0625rem solid #118936 !important;
}
.radioinput-group{
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    /* gap:15px; */
}
.errorIcon{
  color:#dc2d2d;
  margin-right: 5px;
}
.errorMsg{
  color:#dc2d2d;
  margin-top: 0.8rem;
}

@media screen and (min-width: 768px){
  .radioinput-styles {
    height: 3.2rem;
    font-size: 1.5rem !important;
  }
}
@media screen and (max-width: 768px) {
  .radioInputContainer {
    width: 90%;
  }
  .radioInputTitle {
    color: #3e3e3e;
    font-weight: bolder;
    font-size: 1.1rem;
  }
  .radioInputType {
    position: absolute;
    margin-top: .4688rem;
    color: #7E7E7E;
    /* margin-left: 0.7rem; */
    font-size: 0.625rem;
    font-weight: 400;
  
  }
  .radioinput-styles {
    display: flex;
    gap: 25px;
    margin-bottom: 1rem;
    font-size: 1rem !important;
  }
  .radioinput-styles .ant-radio-inner{
    height: 1rem;
    width: 1rem;
  }
  .radioinput-styles .ant-radio-checked .ant-radio-inner::after {
    width: 16px;
    height: 16px;
  }

  .radioinput-styles .ant-radio-inner::after {
    margin-top: -8px;
    margin-left: -8px;
  }
}
