.conversation {
  margin-bottom: 5%;
  /* justify-content: center; */
}

/* .conversation .conversationListCont {
  width: 100%;
  max-height: 73vh;
  overflow: auto;
  background-color: #fff;
} */

.infinite-scroll-component__outerdiv {
  width: inherit;
}

.conversations-container {
  padding: 2.6% 3% 0% 3%;
}

.conversation-div1 {
  /* height: 82vh;
  overflow: auto; */
  border: solid 1px #e4e4e4;

}

.conversationListCont::-webkit-scrollbar {
  width: 2px;
  height: 5px !important;
}

.conversationListCont::-webkit-scrollbar-track {
  background: #fffefe;
}

/* Handle */
.conversationListCont::-webkit-scrollbar-thumb {
  background: rgb(201, 201, 201);
}

/* Handle on hover */
.conversationListCont::-webkit-scrollbar-thumb:hover {
  background: rgb(201, 201, 201);
}

.conversation .chat-History-Cont {
  display: flex;
  justify-content: space-between;
}

.conversation-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 3.8rem;
  /* position: absolute; */
  z-index: 1;
  background-color: #fff;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e4e4e4;
}

.conversation-header-title {
  display: flex;
  width: 52%;
  height: 19px;
  flex-grow: 0;
  margin-left: 4%;
  flex-direction: row;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;

}

.conversation-header-title-text1 {
  color: #1e1e1e;
  margin-left: -3.5%;
  font-size: 100%;
}

.conversation-header-title-text2 {
  color: #7e7e7e;
  margin-left: 1%;
}

.conversation-header-filter {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  width: 15%;
  height: 1.93rem;
  flex-grow: 0;
  margin: 0px 3% 0px 11.5%;
  padding: 0px 2% 5px 2%;
  border-radius: 4px;
  border: solid 1px #e4e4e4;
  background-color: #fff;
}

.conversation-header-filter-image {
  width: 16px;
  height: 16px;
  flex-grow: 0;
  margin: 2px 8px 1px 0;
  object-fit: contain;
}

.conversation-header-filter-count {
  width: 8px;
  height: 19px;
  flex-grow: 0;
  margin: 4px 8px 1px 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #118936;
}

.conversation-header-filter-search {
  background-color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.conversation-header-filter-search .ant-input-affix-wrapper {
  padding: 2px 7px;
}

.chat-history-column {
  border: 1px solid #e4e4e4;
  height: 82vh;
  background-color: #fff;
}

.conversation-header-input {
  height: 29px;
  border-radius: 4px;
}

.cross-icon-div {
  margin-top: 3px;
  margin-left: 5%;
}

.chat-filter-menu {
  border-radius: 4px;
  /* padding: 1rem 0 0 1.5rem; */
  padding: 0.8rem 0 0 1rem;
  z-index: 500;
  overflow: auto;
  border: solid 1px #d5d8d6;

}

.filterDropdown .ant-dropdown {
  z-index: 1000 !important;
}

.chat-history-container {
  height: 82vh;
}

@media only screen and (min-width: 768px) {
  .conversation-header-title-text1 {
    font-size: 15px;
  }
}

.conversation .iconChatCont {
  width: 100%;
  height: 809px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.iconChatCont .iconnChat {
  width: 132.4px;
  height: 105.9px;
}

.iconChatCont .iconnChat .iconConversation {
  width: 100%;
  height: 100%;
}

.iconChatCont .iconChatTitle {
  font-size: 18px;
  font-weight: 500;
  color: #3e3e3e;
  margin-top: 53px;
}

.conversation .chatContainer {
  border: solid 1px #e4e4e4;
  width: 65%;
  /* height: 82vh; */
  height: 37rem;
}

.jobDetailCont .mr {
  margin-bottom: 10px;
}

.jobDetailCont .detailsTitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 13px;
}

.conversation .jobrowflex {
  display: flex;
  justify-content: space-between;
}

.jobDetailCont .jobrowflex .text-Bold {
  font-weight: 500;
  font-size: 14px;
  white-space: pre-wrap;
}

.text-Bold-Container {
  background-color: #118936;
}
@media only screen and (max-width:1000px) {
  .conversation .chatContainer {
    width: 100%;
  }
}

.statusDropDownBtnss {

  display: flex;
  justify-content: space-between;
  margin-top: 4%;
  margin-bottom: 4%;
  margin-left: -1rem;
}

.clearBtnStatus {
  /* width: 7rem;
    height: 2.2rem; */
  width: 86px;
  height: 24px;
  margin-left: 4.5%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.clearBtnStatus:hover {

  border-color: #118936;
  color: #118936;
}

.applyBtnStatus {
  color: #fff !important;
  background-color: #118936 !important;
  margin-right: 3.2%;
  width: 86px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.applyBtnStatus:hover {

  border-color: #118936;
  color: #118936;
}

@media only screen and (max-width:769px) {
  .conversations-container {
    padding: 0%;
  }
}




.conversation .conversationListCont {
  width: 100%;
  max-height: 33rem;
  overflow: auto;
}

.conversations-container {
  padding: 2.6% 3% 0% 3%;
}

.infinite-scroll-component__outerdiv {
  width: inherit;
}

.conversation-div1 {
  /* height: 82vh;
  overflow: auto; */
  border: solid 1px #e4e4e4;

}

.shimmer-container {
  border-bottom: 1px solid lightgray;
  width: 100%;
  padding: 5px;
}

.conversationListCont::-webkit-scrollbar {
  width: 2px;
  height: 5px !important;
}

.conversationListCont::-webkit-scrollbar-track {
  background: #fffefe;
}

/* Handle */
.conversationListCont::-webkit-scrollbar-thumb {
  background: rgb(201, 201, 201);
}

/* Handle on hover */
.conversationListCont::-webkit-scrollbar-thumb:hover {
  background: rgb(201, 201, 201);
}

.conversation .chat-History-Cont {
  display: flex;
  justify-content: space-between;
}

.conversation-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 3.8rem;
  /* position: absolute; */
  z-index: 1;
  background-color: #fff;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e4e4e4;
}

.conversation-header-title {
  display: flex;
  width: 52%;
  height: 19px;
  flex-grow: 0;
  margin-left: 4%;
  flex-direction: row;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;

}

.conversation-header-title-text1 {
  color: #1e1e1e;
  margin-left: -3.5%;
  font-size: 100%;
}

.conversation-header-title-text2 {
  color: #7e7e7e;
  margin-left: 1%;
}

.conversation-header-filter {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  width: 15%;
  height: 1.93rem;
  flex-grow: 0;
  margin: 0px 3% 0px 11.5%;
  padding: 0px 2% 5px 2%;
  border-radius: 4px;
  border: solid 1px #e4e4e4;
  background-color: #fff;
}

.conversation-header-filter-image {
  width: 16px;
  height: 16px;
  flex-grow: 0;
  margin: 2px 8px 1px 0;
  object-fit: contain;
}

.conversation-header-filter-count {
  width: 8px;
  height: 19px;
  flex-grow: 0;
  margin: 4px 8px 1px 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #118936;
}

.conversation-header-filter-search {
  background-color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.conversation-header-filter-search .ant-input-affix-wrapper {
  padding: 2px 7px;
}

.chat-history-column {
  /* border: 1px solid #e4e4e4; */
  height: 82vh;
}

.conversation-header-input {
  height: 29px;
  border-radius: 4px;
}

.cross-icon-div {
  margin-top: 3px;
  margin-left: 5%;
}

.chat-filter-menu {
  border-radius: 4px;
  padding: 0.8rem 0 0 1rem;
  z-index: 500;
  overflow: auto;
  border: solid 1px #d5d8d6;
}

.filterDropdown .ant-dropdown {
  z-index: 1000 !important;
}

.chat-history-container {
  height: 82vh;
}

@media only screen and (min-width: 768px) {
  .conversation-header-title-text1 {
    font-size: 15px;
  }
}

.conversation .iconChatCont {
  width: 100%;
  height: 809px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.iconChatCont .iconnChat {
  width: 132.4px;
  height: 105.9px;
}

.iconChatCont .iconnChat .iconConversation {
  width: 100%;
  height: 100%;
}

.iconChatCont .iconChatTitle {
  font-size: 18px;
  font-weight: 500;
  color: #3e3e3e;
  margin-top: 53px;
}



.conversation .jobDetailCont {
  height: fit-content;
  width: 35%;
  padding: 1% 1%;
  border-radius: 4px;
  border: solid 1px #e4e4e4;
  margin-left: 1%;
  background-color: white;
}

.jobDetailCont .mr {
  margin-bottom: 10px;
}

.jobDetailCont .detailsTitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 13px;
}

.conversation .jobrowflex {
  display: flex;
  justify-content: space-between;
}

.jobDetailCont .jobrowflex .text-Bold {
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  line-height: normal;
}

@media only screen and (max-width: 1000px) {
  .conversation .chatContainer {
    width: 100%;
  }
}

.statusDropDownBtnss {

  display: flex;
  justify-content: space-between;
  margin-top: 4%;
  margin-bottom: 4%;
  margin-left: -1rem;
}

@media only screen and (max-width:769px) {
  .conversations-container {
    padding: 0%;
  }
  .send-message-container .message-bottom {
    height: auto;
  }
}

@media only screen and (min-width: 769px) {
  .ant-dropdown.ant-dropdown-placement-bottomLeft {
    z-index: 950 !important;
  }
}