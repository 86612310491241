.Effective-signUp-heading {
    font-size: 1rem;
    font-weight: bold;
    color: #1e1e1e;
    padding-bottom: .625rem;
}

.Effective-signUp-Text {
    font-size: .875rem;
    font-weight: normal;
    color: #4e4e4e;
}

.Effective-sign-main-div {
    border-radius: .3125rem;
    background-color: #f8f8f8;
    padding: .9375rem;
}

.eff-terms-candi {
    font-size: 1rem;
    font-weight: bold;
    color: #4054b2;
    text-decoration: underline;
    cursor: pointer;
}

.eff-alerts-email {
    font-weight: bold;
    color: #4054b2;
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Effective-signUp-heading {
        font-size: .875rem;
        padding-bottom: .25rem;
    }

    .Effective-sign-main-div {
        padding: .625rem;
    }

    .Effective-signUp-Text {
        font-size: .75rem;
    }

    .eff-terms-candi {
        font-size: .875rem;
    }
}