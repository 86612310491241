.DashBoard-Profile-Container {
    width: 100%;
    border-radius: .25rem;
    box-shadow: 0 .125rem .125rem 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.DashBoard-Profile-Container .DashBoard-Profile-info-section {
    display: flex;
    font-size: .5625rem;
    font-weight: normal;
    color: #666;
    background-color: #f6f6f6;
    align-items: center;
    padding: .25rem 0rem .1875rem .5625rem;
    display: flex;
}

.DashBoard-Profile-Container .DashBoard-Profile-info-icon img {
    height: .75rem;
}

.DashBoard-Profile-Container .DashBoard-Profile-info-Text {
    padding-left: .25rem;
}

.DashBoard-Profile-Container .DashBoard-Profile-User-Info {
    padding: .625rem;
}

.DashBoard-Profile-Container .DashBoard-Profile-User-online {
    font-size: .5625rem;
    font-weight: normal;
    font-style: normal;
    color: #118936;
    border-radius: .5rem;
    border: solid .0313rem #118936;
    background-color: #fff;
    max-width: 3.3125rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.DashBoard-Profile-Container .DashBoard-Profile-User-offline {
    color: #9e9e9e;
    border: solid .0313rem #9e9e9e;
}

.DashBoard-Profile-Container .DashBoard-Profile-User-online-dot {
    width: .25rem;
    height: .25rem;
    border-radius: 50%;
    background-color: #118936;
    display: inline-block;
}
.DashBoard-Profile-Container .DashBoard-Profile-User-offline-dot {
    background-color: #9e9e9e;
}

.DashBoard-Profile-Container .DashBoard-Profile-User-online-Text {
    padding-left: .25rem;
    padding-top: .0625rem;
}

.DashBoard-Profile-Container .DashBoard-Profile-Avatar {
    width: 4.375rem;
    height: 4.375rem;
    background-color: transparent;
    border-radius: 9.375rem;
    position: absolute;
    top: 0.7rem;
}

.DashBoard-Profile-Container
  .DashBoard-Profile-Avatar
  .avatarBgColor {
  background-color: #118936;
}

.DashBoard-Profile-Container .DashBoard-Profile-Avatar-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.DashBoard-Profile-Container .DashBoard-Profile-user-name {
    padding-top: .75rem;
    font-size: .875rem;
    font-weight: 500;
    font-style: normal;
    color: #3e3e3e;
}

.DashBoard-Profile-Container .DashBoard-Profile-user-Profession-Code {
    font-size: .5625rem;
    font-weight: bold;
    color: #777;
}

.DashBoard-Profile-Container .DashBoard-Profile-user-rating-section {
    border-radius: .1875rem;
    box-shadow: 0 .0625rem .125rem 0 #bbb;
    background-color: #fff;
    display: flex;
    align-items: baseline;
    margin-top: .5rem;
    padding: 0rem .1875rem .1875rem;
}

.DashBoard-Profile-Container .DashBoard-Profile-user-rating-number {
    font-size: .5625rem;
    font-weight: bold;
    font-style: normal;
    color: #777;
    padding-left: .1875rem;
}

.DashBoard-Profile-Container .DashBoard-Profile-user-rating-star .ant-rate {
    font-size: .75rem;
    color: #e39435;
}

.DashBoard-Profile-Container .DashBoard-Profile-user-Button {
    padding: .375rem 1.25rem;
    border-radius: .25rem;
    border: solid .0313rem #000;
    background-color: #fbdf50;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    cursor: pointer;
}

.DashBoard-Profile-Container .DashBoard-Profile-user-Button-Completed-3DResume {
    font-size: .75rem;
    font-weight: bold;
    font-style: normal;
    color: #000;
}

.DashBoard-Profile-Container .DashBoard-Profile-Avatar .avatar-img4 {
    width: 4.4375rem;
    height: 4.4375rem;
}

.DashBoard-Profile-Container .DashBoard-Profile-progress-bar {
    width: 5.875rem;
    height: 5.875rem;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.DashBoard-Profile-Container .DashBoard-Profile-progress-bar2 {
    width: 5.875rem;
    height: 5.875rem;
    border-radius: 50%;
    border: .1875rem solid #e5e0e0;
}

.DashBoard-Profile-progress-bar-inner {
    width: 5.875rem;
    height: 5.875rem;
    border-radius: 50%;
    border: .1875rem solid rebeccapurple;
    display: flex;
    align-items: center;
    justify-content: center;
}

.smallcircleDashborad {
    top: 8.875rem;
    left: 15.8125rem;
    position: absolute;
    transform: translate(-50%, -50%);
}

.DashBoard-Profile-user-Mobile {
    display: contents;
}

.DashBoard-Profile-Container .DashBoard-Profile-Avatar-section-Mobile {
    display: contents;
}

@media screen and (max-width: 1000px) {
    .DashBoard-Profile-Container .DashBoard-Profile-Avatar {
        width: 3.5rem;
        height: 3.5rem;
        background-color: transparent;
        border-radius: 9.375rem rem;
        position: absolute;
        top: 0.7rem;
        left: 0.7rem;
    }

    .DashBoard-Profile-Container .DashBoard-Profile-Avatar-section {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .DashBoard-Profile-user-Mobile {
        padding-left: 1.25rem;
        display: unset;
    }

    .DashBoard-Profile-Container .DashBoard-Profile-User-online-despktop {
        display: none;
    }

    .DashBoard-Profile-Container .DashBoard-Profile-user-rating-section {
        width: max-content;
    }

    .DashBoard-Profile-Container .DashBoard-Profile-User-online {
        margin-top: .8125rem;
        margin-left: .875rem;
    }

    .DashBoard-Profile-Container .DashBoard-Profile-Avatar-section-Mobile {
        display: flex;
        flex-direction: column;
    }

    .DashBoard-Profile-Container .DashBoard-Profile-Avatar .avatar-img4 {
        width: 3.5rem;
        height: 3.5rem;
    }

    .DashBoard-Profile-Container .DashBoard-Profile-user-Button {
        margin-top: .9375rem;
        margin-bottom: .9375rem;
    }

    .DashBoard-Profile-Container .DashBoard-Profile-user-name {
        padding-top: unset;
    }
}