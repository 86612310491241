.binary-button-wrapper {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.binary-button {
  width: 88px;
  height: 44px;
  top: 336px;
  left: 330px;
  border-radius: 20px;
  border: 1px solid #A4A4A4;
  background: #ffff;
}

.binary-button:focus,
.binary-button:active,
.binary-button:hover,
.binary-button-selected { 
  width: 88px;
  height: 44px;
  top: 336px;
  left: 330px;
  border-radius: 20px;    
  color: #118936;
  border: 1px solid #118936;
  background: #f0f0f0;
}
