.ReceivedBidsCard {
  width: 22rem;
  height: auto;
}

.Recruiters-message{
  font-weight: bold;
}
.Message{
  font-weight: bold;
}

.ReceivedBidsCard .cardCont {
  height: auto;
  /* padding: 15px 0; */
  background-color: #e7f3eb;
}

.ReceivedBidsCard .Retract-bid-cardCont {
  background-color: #f2f2f2;
  height: auto;
  color: #9e9e9e;
}

.tooltip-class{
  font-size: 12px;
  font-weight: 500;
}

.tooltip-class .ant-tooltip-inner{
  color: #3e3e3e !important; 
  /* max-width: 42rem !important; */
  padding: 5%;
  width: 18rem;
}

.tooltip-class .ant-tooltip-placement-bottom, .ant-tooltip-placement-bottomLeft, .ant-tooltip-placement-bottomRight{
  padding-top: 4px !important;
}

.ReceivedBidsCard .buttonsCont {
  width: 100%;
  padding-top: 12px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
}

/* .rate-class {

} */
.flexSpace .ant-rate-star-first, .ant-rate-star-second{
  color: #cacbca;
}


#Accept-btn {
  background-color: #FC1254;
  color: white;
  font-size: 14px;
  height: 45px;

}
.Accept-btn-int {
  font-size: 12px;
  font-weight: 20;
  margin: -8px;
}

.Accept-btn-acc {
  margin-top: 20px; 
  font-weight: bold;
  margin-top: 30px;
  font-size: 14px;
}

.whole-acc-btn{
  align-items: center;
  padding-top: 5px;
}
.Accept-btn-int, .Accept-btn-acc {
  margin: -5px; 
  padding: 0; 

}

#Decline-btn {
  background-color: white;
  color: gray;
  font-size: 14px;
  height: 45px;
}

@media (max-width: 768px) {
  #Accept-btn,
  #Decline-btn {
    font-size: 14px; 
  }
}
/* #Accept-btn{
  background-color: #FC1254;
  color: white;
  font-size: 0.87rem;
}

#Decline-btn{
  background-color: white;
  color: gray;
} */

.buttonsCont .btncard {
  /* padding: 20px 50px; */
  width: 48%;
  background-color: #FFFFFF;
  height: 35px;
  flex-grow: 0;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7e7e7e;
  border: solid 1px #7e7e7e;
  border-radius: 5px;
}

.buttonsCont .Retracted-btn {
  width: 48%;
  background-color: #f2f2f2;
  height: 40px;
  flex-grow: 0;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9e9e9e;
  border: solid 1px #e4e4e4;
  border-radius: 5px;
}
.buttonsCont .btn-tooltip span{
  width: 48%;
  border: none !important;
}

.buttonsCont .btn-tooltip button{
    width: 100%;
    padding: inherit;
    height: inherit;
    flex-grow: 0;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9e9e9e;
    border: solid 1px #e4e4e4;
    border-radius: 5px;
}

.ReceivedBidsCard .card-Header-RB {
  border-bottom: solid 1px #e4e4e4;
  display: flex;
  padding: 12px 10px;
  /* align-items: center; */
}

.card-Header-RB .candidate-card-image {
  width: 45px;
  height: 45px;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  object-fit: contain;
  border-radius: 10%;
  margin-right: 3%;
  /* margin: 0px 8px 0px 12px; */
}

.ReceivedBidsCard .flexSpace {
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 12px;
}

.ReceivedBidsCard .exp {
  /* margin-left: 6%; */
  font-size: 14px;
  font-weight: 500;
}

.card-Header-RB .textBold {
  font-size: 15px;
  font-weight: 500;
  color: #1e1e1e;
  width: 92%;
}

.card-Header-RB .Retratced-textBold {
  font-size: 15px;
  font-weight: 500;
  color: #9e9e9e;
}

.card-Header-RB .star-Icon {
  color: #ffba08;
  font-size: 19px;
  cursor: pointer;
}
.comp-rate-cp {
  font-size: 10px !important;
  /* object-fit: contain; */
}

.card-Header-RB .ratingText {
  font-size: 12px;
  color: #ffba08;
  margin-left: 3%;
  /* margin-right: 1%; */
  /* background-color:  red; */
  /* margin-top: -1.5px; */
}

.card-Header-RB .rating-COUNT {
  font-size: 12px;
  color: #7e7e7e;
  margin-left: 2%;
  /* background-color:  red; */
}

.header-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.ReceivedBidsCard .card-Body-RB {
  padding: 15px 15px 0px 15px;
}

.bidRateBold {
  font-size: 16px;
  font-weight: bold;
  color: #1e1e1e;
}

.textRegular {
  font-size: 12px;
  font-weight: 500;
  color: #3e3e3e;
}

.textR {
  font-weight: normal;
  word-break: break-word;
}

.textR ul,ol,dl{
  margin-left: 2rem;
}
.textR ul li, ol li{
  margin-left: 0;
}

.time-chat {
  font-size: 11px;
  color: #7E7E7E;
  width: 100%;
  display: flex;
  justify-content: right;
  margin-top: 5%;
}

.double-check-icon {
  color: #007cf0;
  margin: 1.2% 0 0 2%;
}

.declined-bid-modal {
  padding: 4%;
}

.modal-ok-btn{
  width: 145px;
  height: 42px !important;
  margin: 0px 15px 0 10px;
  padding: 0 1px 0 0 !important;
  border-radius: 4px !important;
  background-color: #118936 !important;
  font-family: SFProText !important;
  font-size: 16px !important;
  font-weight: 600;
  color: #fff !important;
}
.modal-no-btn{
  width: 108px;
  height: 42px !important;
  margin: 0px 10px 0 15px !important;
  /* padding: 6px 59px 11px 58px !important; */
  border-radius: 4px !important;
  background-color: #fff;
  color: #9e9e9e !important;
}
.modal-paragraph {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.bid-summary-text{
  color: #4054B2;
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  cursor: pointer;
}
@media screen and (max-width:767px) and (min-width:500px) {
  .ReceivedBidsCard {
    width: 25rem;
    margin-top: 15px;
  }
}

@media screen and (max-width:499px) {
  .ReceivedBidsCard {
    width: 88%;
    margin-top: 15px;
  }
}

@media screen and (max-width: 768px) {
  .buttonsCont .Retracted-btn {
    font-size: .8125rem;
  }
}