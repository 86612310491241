.Resume-upload-v2 {
    width: 50%;
    margin: auto;
    padding-top: 2.75rem;
}


.Resume-upload-v2-grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.Resume-upload-v2-grid-item {
    background-color: lightgray;
    padding: 1.25rem;
    text-align: center;
}

/* .upload-doc-container-v2 {
    padding-bottom: 10.8125rem;
} */

.Upload-doc-v2-button {
    display: flex;
    justify-content: center;
}

.Upload-doc-v2-button .gap-back-next-signup-buttons {
    /* margin-bottom: 10.0625rem; */
}

.Doc-upload-file-V2-two {
    min-width: 24rem;
}

@media screen and (max-width: 768px) {
    .Resume-upload-v2 {
        width: unset;
        padding: 2.5313rem 1.5625rem 0;
    }

    .Resume-upload-v2-grid-container {
        grid-template-columns: 1fr;
    }

    .Resume-upload-v2-grid-container{
        grid-gap: 4.625rem;
    }

    .upload-doc-container-v2 {
        /* padding-bottom: 4.3125rem; */
        /* padding-top: 1.5625rem; */
    }

    .Upload-doc-v2-button .gap-back-next-signup-buttons {
        margin-bottom: 3.8125rem;
    }

    .Doc-upload-file-V2-two {
        min-width: 20rem;
    }
}