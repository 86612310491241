.notificationItem {
    padding: 12px 0px;
    border-bottom: 1px solid #e4e4e4;
    cursor: pointer;
  }
  .notificationItem .imageCont {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .image-notifi-container {
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .image-notifi-text {
    color: white;
    font-size: 22px;
    padding-top: 8px;
  }
  .notificationItem .imageCont div {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    overflow: hidden;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    background-color: #118936;
    display: flex;
    justify-content: center;
  }
  .AdminMessage-Cantainer-nod {
    background-color: #fff !important;
    align-items: center;
  }
  .AdminMessage-Cantainer-nod img {
    width: 2.375rem !important;
    height: 2.0625rem !important;
  }
  .notificationItem .imageCont div img {
    width: 100%;
    height: 100%;
  }
  .notificationItem .notificationTitle {
    font-size: 14px;
    font-weight: 600;
    color: #1e1e1e;
  }
  .noti-img {
    align-self: center;
  }
  .notificationItem .notificationTime {
    font-size: 12px;
    color: #7e7e7e;
    margin: 5px 0px 4px 0px;
  }
  .notificationItem .interviewerName {
    font-size: 12px;
    font-weight: 600;
    color: #4e4e4e;
    margin: 10px 0px;
  }
  .notificationItem .interviewTime {
    font-size: 12px;
    color: #4e4e4e;
    margin: 4px 0px 10px 0px;
  }
  .notificationItem .attendInterview {
    font-size: 12px;
    font-weight: 600;
    color: #118936;
  }