.choose-skill-listing-parent {
    display: flex;
    justify-content: center;
    padding-top: 200px;
    padding-bottom: 200px;
    background-color: white;
}



.Create-listing-heading {
    color: #7e7e7e;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px;
    padding-bottom: 24px;
}

.progres-bar-create {
    padding-top: 1px;
}

.back-btn-bt {
    color: #7e7e7e;
    font-size: 14px;
    /* font-family: Noto Sans; */
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    padding-top: 23px;
    cursor: pointer;
}

.create-skill-input .ant-select-selection-search-input {
    padding-bottom: .25rem;
}

@media screen and (max-width: 768px) {
    .Create-listing-heading {
        color: #7e7e7e;
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: 40px;
        padding-bottom: 24px;
    }
}