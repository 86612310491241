.gap-back-next-signup-buttons {
	display: flex;
	gap: 1.56rem;
	/* margin-bottom: 10rem; */
	width: 70%;
}

.bck-button-2 .gap-back-next-signup-buttons {
	justify-content: center;
	margin-top: 137px;
}

.bck-button-3 .gap-back-next-signup-buttons {
	justify-content: center;
	margin-top: 137px;
}
.gap-back-next-signup-buttons .gap-button-back {
	width: 10.625rem;
	height: 3.125rem;
	border-radius: 0.25rem;
	border: 1px solid #e4e4e4;
	/* border: 1px solid #1e1e1e; */

	display: flex;
	justify-content: center;
	align-items: center;

	/* font */
	color: #1e1e1e;
	font-size: 1rem;
	font-style: normal;
	font-weight: 600;
	/* cursor: pointer; */
	--antd-wave-shadow-color: transparent;
}

.gap-back-next-signup-buttons .gap-button-skip {
	width: 10.625rem;
	height: 3.125rem;
	border-radius: 0.25rem;
	border: none;

	display: flex;
	justify-content: center;
	align-items: center;

	/* font */
	color: #1e1e1e;
	font-size: 1rem;
	font-style: normal;
	font-weight: 600;
	/* cursor: pointer; */
}

.gap-back-next-signup-buttons .gap-button-back:hover {
	border: 1px solid #6b6b6b;
	color: #6b6b6b;
}

.gap-back-next-signup-buttons .gap-button-skip:hover {
	color: #6b6b6b;
	border: none;
}

.gap-back-next-signup-buttons .gap-button-next {
	width: 10.625rem;
	/* height: 3.125rem; */
	border-radius: 0.25rem;
	border: 0.5px solid #1e1e1e;
	background-color: #fbdf50;

	display: flex;
	justify-content: center;
	align-items: center;

	/* font */
	color: #1e1e1e;
	font-size: 1rem;
	font-style: normal;
	font-weight: 600;
	--antd-wave-shadow-color: transparent;
}

.gap-back-next-signup-buttons .gap-button-next:hover {
	background-color: #ffea81;
}

@media screen and (max-width: 1000px) {
	.gap-back-next-signup-buttons {
		gap: 1rem;
		/* flex-direction: row-reverse; */
		width: 85%;
	}

	.gap-back-next-signup-buttons .gap-button-back {
		width: 100%;
		height: 2.5rem;
		font-size: 0.75rem;
		order: 0;
	}

	.gap-back-next-signup-buttons .gap-button-skip {
		width: 100%;
		height: 2.5rem;
		font-size: 0.75rem;
		border: none;
		order: 1;
	}

	.gap-back-next-signup-buttons .gap-button-next {
		width: 100%;
		height: 2.5rem;
		font-size: 0.75rem;
		order: 2;
	}

	.bck-button-3 .gap-back-next-signup-buttons {
		margin-top: 61px;
	}
	.bck-button-2 .gap-back-next-signup-buttons {
		margin-top: 61px;
	}
}

.BottomButtonsParentContainer {
	width: 100%;
	/* display: flex;
	justify-content: center; */
	padding-bottom: 2rem;
	bottom: 2rem;
}
.buttonsRowContainer {
	width: 50%;
}
.Btns-row2 {
	margin-top: 5%;
	text-align: center;
	width: 85%;
	height: 6vh;
	display: flex;
	justify-content: space-between;
}
.temp-gap-div {
	width: 1.563rem;
}

.Back-btn {
	width: 100%;
	background-color: white;
	padding: 10px;
	color: #000000;
	text-align: center;
	transition: all 200ms;
	border: 1px solid #3e3e3e;
	vertical-align: middle;
	line-height: 1.3;
	height: 3.125rem;
	border-radius: 5px;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.25rem;
	letter-spacing: 0em;
}
.Back-btn:focus {
	width: 100%;
	background-color: white;
	padding: 10px;
	color: #000000;
	text-align: center;
	/* font-size: 12px;
  font-weight: 600; */
	transition: all 200ms;
	border: 1px solid #3e3e3e;
	vertical-align: middle;
	line-height: 1.3;
	height: 3.125rem;
	border-radius: 4px;
}
.Back-btn-div {
	margin: auto;
}
.Back-btn:hover {
	background-color: white;
	color: #6b6b6b;
	border: 1px solid #6b6b6b;
}
.Skip-btn:hover {
	background-color: white;
	color: #6b6b6b;
	
	border:none ;
}
.Next-btn {
	width: 100%;
	background-color: #fbdf50;
	padding: 10px;
	color: #000000;
	text-align: center;
	font-size: 12px;
	font-weight: 600;
	border: 0.5px solid #000;
	vertical-align: middle;
	line-height: 1.3;
	border-radius: 4px;
	height: 3.125rem;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.25rem;
	letter-spacing: 0em;
	transition: none;
	--antd-wave-shadow-color: transparent;
}
.Next-btn:focus {
	width: 100%;
	background-color: #fbdf50;
	padding: 10px;
	color: #000000;
	text-align: center;
	height: 3.125rem;
	vertical-align: middle;
	line-height: 1.3;
	border-radius: 5px;
	border-color: #000000;
}
.Next-btn-div {
	margin: auto;
}
.Next-btn:hover {
	background-color: #ffea81;
	color: black;
	border: 0.5px solid #000;
}
.Next-btn .ant-btn::before {
	transition: none;
}
@media screen and (max-width: 768px) {
	/* .buttonsRowContainer{
      width: 100%;
  } */
	.Btns-row {
		margin-top: 10%;
		text-align: center;
		width: 90%;
		/* height: 6rem !important; */
		margin-left: 5%;
		border: none;
	}
	.Back-btn-div {
		margin: auto;
		margin-top: 1rem;
		height: 2.5rem !important;
	}
	.Next-btn {
		height: 2.5rem;
		min-height: 2.5rem;
		font-size: 0.75rem;
		font-weight: 600;
		line-height: 0.895rem;
	}
	.Back-btn {
		height: 2.5rem;
		min-height: 2.5rem;
		font-size: 0.75rem;
		font-weight: 600;
		line-height: 0.895rem;
	}
}
.main-div-mobile {
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: #fff;
	box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.25);
	padding: 0.625rem 0;
	right: 1px;
}
.button-mob-view {
	display: flex;
	justify-content: space-around;
	width: 100%;
	overflow: hidden;
}
.back-btn-mobile {
	width: 20%;
	height: 55px;
	border-radius: 4px;
	border: 1px solid #d8d8d8;
	background-color: white;
}
.next-btn-mobile {
	display: flex;
	width: 70%;
	height: 55px;
	padding: 12px 64px 12px 65px;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	border-radius: 4px;
	background: black;
	color: white;
	cursor: pointer;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px;
	border: 1px solid black;
}
.btn-read-rules {
	color: black;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px;
}

.main-div-mobile1 {
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: #fff;
	box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.25);
	padding: 0.625rem 0;
	right: 1px;
}
.button-mob-view1 {
	display: flex;
	justify-content: space-around;
	width: 100%;
	overflow: hidden;
	padding: 2px;
}
.back-btn-mobile1 {
	width: 17%;
	height: 55px;
	border-radius: 4px;
	border: 1px solid #d8d8d8;
	background-color: white;
}
.Skip-btn{
	width: 100%;
	height: 55px;
	border-radius: 4px;
	background-color: white;
  color: black;
  font-style: normal;
	font-weight: 500;
	border: none;

}
.skip-btn-mobile1 {
	width: 36%;
	height: 55px;
	border-radius: 4px;
	border: 1px solid #d8d8d8;
	background-color: white;
    color: black;
  font-style: normal;
	font-weight: 500;
}
.next-btn-mobile1 {
	display: flex;
	width: 36%;
	height: 55px;
	padding: 12px 64px 12px 65px;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	border-radius: 4px;
	background: black;
	color: white;
	cursor: pointer;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px;
	border: 1px solid #c7c7c7;
}
.btn-read-rules1 {
	color: white;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px;
}
