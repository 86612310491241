.footer-container {
  background-color: #fff;
  padding: 1.4375rem 6.25rem 1.5rem;
  min-height: 14.06rem;
}

.footer-section {
  display: flex;
  justify-content: space-between;
}

.footer-About-section {
  display: flex;
  flex-direction: column;
}

.footer-heading {
  font-weight: 500;
  color: #4e4e4e;
  font-size: .75rem;
  padding-bottom: .625rem;
}

.footer-About-section > span {
  font-size: .75rem;
  font-weight: normal;
  color: #7e7e7e;
  padding-bottom: .25rem;
  cursor: pointer;
}


.footer-Support-section > span {
  font-size: .75rem;
  font-weight: normal;
  color: #7e7e7e;
  padding-bottom: 0.25rem;
  cursor: pointer;
}

.footer-Community-section {
  display: flex;
  flex-direction: column;
  /* padding-right: 3rem; */
}

.footer-Community-section > span {
  font-size: .75rem;
  font-weight: normal;
  color: #7e7e7e;
  padding-bottom: 0.25rem;
  cursor: pointer;
}

.footer-logo-section {
  display: flex;
  justify-content: space-between;
}

.footer-container .ant-divider-horizontal {
  margin: .875rem 0 1.0625rem 0 !important;
  background-color: #e4e4e4 !important;
}

.footer-social-link {
  display: flex;
  justify-content: flex-end;
  align-items: end;
  /* min-width: 21.25rem; */
  /* gap: 1.5rem; */
}

.footer-social-link .anticon {
  font-size: 1.1563rem;
  color: #7e7e7e;

}

.footer-social-link>a {
  padding-left: 1.3313rem;
}

.footer-icon-modifiction {
  /* font-size: 1.875rem; */
  color: #7e7e7e;
  height:15px;
  width: 15px;
} 

.footer-divider-top {
  margin: 0;
  background-color: #f8f8f8;
  border-top: 0.0625rem solid #e4e4e4 !important;
}

.Footer-logo-Skillgig > img {
  height: 1.4375rem;
}

.Footer-logo-Skillgig > span {
  padding-left: .5938rem;
  color: #7e7e7e;
  font-size: 0.875rem;
}
.skillogo{
  font-size:.75rem !important;
}

.footer-Support-section {
  display: flex;
  flex-direction: column;
}

.footer-22{
  max-height: 15px !important;
  max-width: 15px !important;
}

@media screen and (max-width: 768px) {
  .footer-container {
    padding: 0.313rem 1.25rem 1.25rem;
    min-height: 19.813rem;
  }

  .footer-section {
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
  }

  .Footer-logo-Skillgig {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

    .footer-social-link>a {
      padding-left: 1.1563rem;
    }

  .footer-logo-section {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .Footer-logo-Skillgig > span {
    padding: 15px 0 15px 0;
    color: #9e9e9e;
    font-weight: normal;
    font-size: .875rem;
  }

  .footer-container .ant-divider-horizontal {
    margin-bottom: 1.25rem !important;
  }

  .skillogo{
    font-size: .875rem !important;
  }
  .footer-heading {
    padding-top: 1.25rem;
    font-size: 16px;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    color: #4e4e4e;
    font-weight: 500;
  }

  .footer-container .ant-divider-horizontal {
    margin-top: 1.188rem !important;
  }

  .footer-social-link {
    padding-top: 0.938rem;
    display: flex;
    justify-content: center;
    align-items: normal;
  }

  .footer-social-link .anticon {
    font-size: 1.25rem;
  }

  .footer-icon-modifiction {
    height:20px;
    width: 20px;
  }

  .Footer-logo-Skillgig > img {
    height: 2rem;
  }

  .footer-social-link .ant-row {
    justify-content: space-between;
  }

  .footer-first-span {
    padding-top: 1.25rem !important;
  }

  .footer-heading .anticon {
    color: #4e4e4e;
    height: 20px;
    width: 20px;
  }

  /* .footer-bottom-span {
    padding-bottom: 0 !important;
  } */

  .footer-Community-section{
    padding-right: unset;
  }

  /* .footer-social-link {
   
    gap: 1rem;
  } */
}
