.CircularBar {
  position: absolute;
}

.CircularBadge {
  width: var(--cbWidth);
  height: var(--cbHight);
  position: absolute;
  display: grid;
  place-items: center;
  color: #ffffff;
  background-color: #118936;
  bottom: 0;
  right: 0;
  font-size: 0.5625rem;
  border-radius: 50%;
  cursor: pointer;
}

.CircularBadgeTooltip .ant-tooltip-inner {
  font-size: 0.75rem;
}
