.private-gigs-tag-section{
    display: flex;
    align-items: center;
    gap: .5rem;
}

.Filter-section-pvt-mkt {
    padding: 0 0 1.25rem 1.25rem;
}

.Filter-section-pvt-mkt .tag-filter {
    margin-top: 1.25rem;
    display: flex;
    width: fit-content;
    border: solid .0625rem #e4e4e4;
    background-color: #e4e4e4;
    font-size: .875rem;
    font-weight: 500;
    color: #5e5e5e;
}
.Filter-section-pvt-mkt .PrivateGigsTag-Cantainer {
    margin-left: .8125rem;
    padding: .0625rem .25rem 0 .25rem;
}

.Filter-section-pvt-mkt .skill-list-type-dropdown {
    width: 6.125rem;
}

.Filter-section-pvt-mkt-selected .skill-list-type-dropdown {
    border-color:  #118936;
    color: #118936;
    background-color: #f6fff9;
}

.private-gigs-tag-section .PrivateGigsTag-Cantainer {
    margin-top: .1563rem;
}

.skill-type-menu2 {
    width: 17.125rem !important;
}
.filter-wrapper{
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding-left: 21px;
}
.dropdown1 {
    width: fit-content;
    height: 28px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 5px 10px;
    border-radius: 4px;
    border: solid 1px #d4d4d4;
    color: #1e1e1e;
    background-color: #fff;
    /* border: solid 1px #118936;
    color: #118936;
    background-color: #f6fff9; */
  }
.dropdown-job1 {
    width: fit-content;
    height: 28px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 5px 10px;
    border-radius: 4px;
    /* border: solid 1px #d4d4d4;
    background-color: #fff; */
    border: solid 1px #118936;
    color: #118936;
    background-color: #f6fff9;
  }
.job-type-menu1{
    width: 240px;
    /* height: 144px; */
    padding: 5px 20px 20px 20px;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    background-color: #fff;
}
.div2-searchResults{
    display: flex;
    justify-content: flex-end;
    padding: 0px 33px 18px 0px;
}
.sort-by {
    width: 3.438rem;
    height: 1.188rem;
    flex-grow: 0;
    margin: 0 0.938rem 0 0;
    /* font-family: SFProText; */
    font-size: 1rem;
    /* line-height: normal; */
    color: #5e5e5e;
    cursor: default;
  }
  .tag2{
    border-radius: 20px;
    height: 29px;
    grid-gap: 10px;
    gap: 10px;
    /* margin: 20px 10px 0px 0px; */
    padding: 4px 12px;
    border: solid 1px #e4e4e4;
    background-color: #e4e4e4;
    /* font-family: SFProText; */
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    /* line-height: normal; */
    letter-spacing: normal;
    text-align: left;
    color: #5e5e5e;
    z-index: inherit;
  }

  .applied-filter .tag2  {
    display: inline-block;
    height: 14px;
  }
.most-recent{
    width: 7rem;
    height: -0.812rem;
    font-size: 1rem;
    font-weight: 600;
    color: #1e1e1e;
    padding-top: 4px;
}
.clearAll-div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0.5rem 5% 1.125rem 5%;
}
.cancelfilter-btn{
    width: 5.375rem;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    align-self: center;
    cursor: default;
    letter-spacing: normal;
    text-align: center;
    color: #7e7e7e;
    cursor: pointer;
}
.applyfilter-btn{
    width: 4.688rem;
    height: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: default;
    grid-gap: 0.625rem;
    gap: 0.625rem;
    color: #fff;
    font-weight: 600;
    padding: 0.313rem 1.25rem;
    border-radius: 0.125rem;
    background-color: #118936;
    cursor: pointer;
    border: #118936;
}
.s-result {
    position: relative;
    margin: 0;
    padding: 4px 0;
    text-align: left;
    list-style-type: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
      0 9px 28px 8px rgb(0 0 0 / 5%);
  }
  
  .skill-search {
    margin: 1rem 1.25rem;
    width: 20rem;
    height: 2.125rem;
    padding: 0.438rem 9.813rem 0.438rem 0.625rem;
    border-radius: 0.25rem;
    box-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.25);
    background-color: #fff;
    /* font-family: SFProText; */
    font-size: 14px;
  }