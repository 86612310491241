.Mobile-filter-container {
  height: 480px;
  overflow-y: auto;
  margin: 0;
  padding: 4px;
}
.Mobile-filter-container .ant-menu-vertical .ant-menu-item {
  line-height: normal !important;
  height: auto !important;
  margin: 0; 
}
.Mobile-filter-container .job-type {
  color: #1e1e1e;
  padding-top: 1.25rem;
  font-weight: 500;
  padding-bottom: .625rem;
}

.Mobile-filter-container .Search-jobs {
  margin: 0 14vw 0 0;
  color: #3e3e3e;
}

.Mobile-filter-container .ant-input-prefix {
  /* text-align: left; */
  color: #7e7e7e !important;
  font-size: 16px !important;
}
.Mobile-filter-container .mobile-filter {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}
.Mobile-filter-container .input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Mobile-filter-container .input-container .dash {
  align-self: center;
  color: #7e7e7e;
}
.Mobile-filter-container .input-container .input-left {
  margin: 0 11px 0 0;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #d4d4d4;
  background-color: #fff;
}
.Mobile-filter-container .input-container .input-left-invalid {
  margin: 0 11px 0 0;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #d4d4d4;
  background-color: #fff;
  border: 1px solid #db2828;
}
.Mobile-filter-container .input-container .input-right .ant-input {
  margin: 0 0 0 4px;
}
.Mobile-filter-container .input-container .input-left .ant-input {
  margin: 0 0 0 4px;
}
.Mobile-filter-container .input-container .input-right {
  margin: 0 0 0 11px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #d4d4d4;
  background-color: #fff;
}
.Mobile-filter-container .input-container .input-right-invalid {
  margin: 0 0 0 11px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #d4d4d4;
  background-color: #fff;
  border: 1px solid #db2828;
}
.Mobile-filter-container
  .input-container
  .input-right
  input[type="text"]::placeholder {
  color: #7e7e7e;
}
.Mobile-filter-container
  .input-container
  .input-left
  input[type="text"]::placeholder {
  color: #7e7e7e;
}
.Mobile-filter-container .ant-input {
  font-size: 1rem;
}
.Mobile-filter-container .skill-search-mobile-invalid {
  border: 1px solid #db2828;
}

.Mobile-filter-container .mobile-filter .left {
  display: flex;
  width: -webkit-fill-available;
  height: 42px;
  border-radius: 4px;
  border: solid 1px #d4d4d4;
  background-color: #fff;
  justify-content: center;
  align-items: center;
}
.Mobile-filter-container .mobile-filter .left-active {
  display: flex;
  width: -webkit-fill-available;
  height: 42px;
  border-radius: 4px;
  border: solid 1px #d4d4d4;
  background-color: #e4ffec;
  justify-content: center;
  align-items: center;
}
.Mobile-filter-container .mobile-filter .left-invalid {
  display: flex;
  width: -webkit-fill-available;
  height: 42px;
  border-radius: 4px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  border: 1px solid #db2828;
}
.Mobile-filter-container .mobile-filter .left-invalid .text {
  font-size: 1rem;
  color: #3e3e3e;
}
.Mobile-filter-container .mobile-filter .left .text {
  font-size: 1rem;
  color: #3e3e3e;
}
.Mobile-filter-container .mobile-filter .left-active .text {
  font-size: 1rem;
  color: #3e3e3e;
}

.Mobile-filter-container .mobile-filter .right .text {
  width: 25px;
  height: 14px;
  flex-grow: 0;
  margin: 1px 0 1px 20px;
  /* font-family: SFProText; */
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}
.s-result-mobile .skill-search-mobile {
  margin: 1rem 0.938rem 0.25rem 0.938rem;
  width: auto;
  height: 2.125rem;
  padding: 0.438rem 0.625rem 0.438rem 0.625rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  /* font-family: SFProText; */
  font-size: 16px;
}
.s-result-mobile .skill-search-mobile .ant-input {
  font-size: 16px;
}
.s-result-mobile .skill-search-mobile .ant-input-prefix {
  color: #7e7e7e !important;
  height: 15.6px !important;
  width: 15.6px !important;
  margin-right: 10px !important;
  font-size: 16px !important;
}
.SearchfilterDrawer-mobile .ant-menu {
  padding-top: 0.2rem !important;
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}

.Mobile-filter-container .apply-button-mobile-filter {
  /* width: 320px; */
  height: 42px;
  padding: 12px 114px 12px 115px;
  border-radius: 5px;
  background-color: #118936;
}
.Mobile-filter-container .apply-button-mobile-filter .apply-button-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.13;
  text-align: center;
  color: #fff;
}

.SearchfilterDrawer-mobile .ant-drawer-title {
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}
.SearchfilterDrawer-mobile .ant-drawer-header {
  border-bottom: 2px solid #f0f0f0;
}
.search-results-filter-mobile-validation-text {
  font-size: 12px;
  color: #db2828;
}
.radioSortReceivedBidsGroupFilter {
  width: 100%;
  display: flex;
  justify-content: center;
}
.radioSortReceivedBidsGroupFilter .radioSortReceivedBidsFilter {
  display: flex;
  width: 100%;
  /* min-width: 22.63rem; */
  height: 2.75rem;
  /* margin: 1.25rem 0.94rem 0.63rem; */
  padding: 0.6rem 0 0.75rem 0.75rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.15);
  border: solid 0.06rem #d4d4d4;
  background-color: #fff;
}
.checkBoxFilter{
  display: flex;
  width: 100%;
  height: 2.75rem;
  padding: 0.6rem 0 2rem 0.75rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.15);
  border: solid 0.06rem #d4d4d4;
  background-color: #fff;
}

.radioSortReceivedBidsGroupFilter .ant-radio-checked .ant-radio-inner {
  border-color: var(--primaryColor);
}

.radioSortReceivedBidsGroupFilter .ant-radio-inner::after {
  background-color: var(--primaryColor);
}
.radioSortReceivedBidsGroupFilter .ant-space-vertical {
  width: 100%;
}
.SearchRadioButtonColor {
  color: #3e3e3e;
}
.MobileFilter-Search-input-left-invalid {
  border: solid 0.06rem #db2828 !important;
}
.apply_button_main{ 
    position:absolute;
    bottom: 0;
    width: 100%;
    border-top: 0.063rem solid #e8e8e8;
    padding: .9375rem 1.25rem .9375rem;
    text-align: right;
    left: .0625rem;
    background: #fff;
    border-radius: 0 0 0.25rem 0.25rem; 
}
.SearchfilterDrawer ::-webkit-scrollbar {
  display: none;
}
.Search-filter-MobileView .ant-drawer-body {
  padding: 0rem;
}

.Search-filter-MobileView .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: unset
}

.Search-filter-MobileView .ant-drawer-close {
  right: .125rem;
}

.Skill-Selecter-filter .ant-drawer-body {
  padding: 0rem;
}

.Skill-Selecter-filter .ant-drawer-close {
  position: absolute;
  right: .625rem;
}

.location-lists-container.show-scrollbar {
  max-height: calc(62px * 8);
  overflow-y: overlay;
}

.location-lists-container.show-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.location-lists-container.show-scrollbar::-webkit-scrollbar-track {
  background: #fff;
}

.location-lists-container.show-scrollbar::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 12px !important;
  cursor: pointer !important;
}

.location-lists-container .ant-menu {
  border-right: none;
}
.location-box {
  width: 100%;
  min-height: 2.5rem;
  height: auto;
  /* padding: 4px; */
  border-radius: 0.25rem;
  /* box-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.25); */
  border-radius: 4px;
  border: .0625rem solid #D4D4D4;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  /* font-family: SFProText; */
  font-size: 16px;
}
.tag1 {
  border-radius: 20px;
  height: 29px;
  gap: 10px;
  margin: 4px 2px 4px 2px;
  padding: 4px 12px;
  border: solid 1px #e4e4e4;
  background-color: #e4e4e4;
  /* font-family: SFProText; */
  font-size: 14px;
  z-index: 1000;
  cursor: pointer;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  /* line-height: normal; */
  letter-spacing: normal;
  text-align: left;
  color: #5e5e5e;
}
.input-placeholder {
  color: #7E7E7E;
  padding-left: .75rem;
}

.Mobile-filter-container .clearAll {
  display: none;
}

.Mobile-filter-container ::-webkit-scrollbar {
  display: none;
  /* make scrollbar transparent */
}

.job-type-gig-type-mobile {
  padding-top: 5px !important;
}
.private-div{
  background-color: #0F1E2B;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: white;
  border-radius: 2px;
  padding: 2px 4px 2px 4px;
}
