/* .document-upload-cantainer-v2 {
    width: 45%;
} */

.document-upload-Header-v2 {
    padding-bottom: 2.125rem;
 
}

.document-upload-title-v2 {
    color: #212121;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    align-items: center;
}

.bls-doc-Text {
    /* width: 70%; */
    display: block;
    align-items: end !important;
}

.acls-doc-Text {
    display: block;
    /* width: 81%; */
    align-items: end !important;
}

.document-upload-info-v2 {
    color: #7E7E7E;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding-top: 20px;
}

.document-upload-cantainer-v2 .ant-upload.ant-upload-drag .ant-upload {
    padding: 2rem 0 1.9375rem;
}

.document-upload-text-v2 {
    padding-top: 1.625rem;
    color: #3E3E3E;
    font-size: .875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
    padding-bottom: .875rem;
}

.document-upload-cantainer-v2 .ant-upload-list-item-thumbnail {
    pointer-events: none;
}

.document-upload-Choose-v2 {
    color: #118936;
    font-size: .875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
}

.document-upload-hint-v2 {
    color: #7E7E7E;
    text-align: center;
    font-size: .875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.ant-upload-list-item-card-actions-btn:focus, .ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
    margin-bottom: 2px
}

.ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item{
    margin-right: 10px;
    width: 560px;
}
.disableImg{
    display: none;
}
.document-upload-cantainer-v2 .ant-upload.ant-upload-drag {
    border: .125rem dashed #118936;
    background: #FFFFFF;
    width: 600px;
}

.document-upload-cantainer-v2-error .ant-upload.ant-upload-drag{
    border: .125rem dashed #DB2828 !important;
}

.document-upload-cantainer-v2 .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: #118936;
}
.uploadDocContainer{
    display: flex;
}
.Disable-upload-section {
    display: none;
}

.document-upload-cantainer-v2 .ant-upload-list-picture .ant-upload-list-item-error {
    border-color: #DB2828;
}

.document-upload-cantainer-v2 .ant-upload-list-item-error .ant-upload-list-item-name {
    color: #000000;
}

.document-upload-cantainer-v2 .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
    color: #000000;;
}

.upload-doc-Mandatory {
    color: #7E7E7E;
    font-size: .625rem;
    font-style: normal;
    font-weight: 400;
    padding-left: 1.0625rem;
}

.Resume-doc-Mandatory {
    margin-top: .2313rem;
}

.Next-btn .ant-click-animating-node {
    display: none;
}

@media screen and (max-width: 768px) {
    .ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item{
        width: 300px;
        margin-right: 5px;
    }
    .document-upload-cantainer-v2 .ant-upload.ant-upload-drag {
        border: .125rem dashed #118936;
        background: #FFFFFF;
        width: 330px;
    }

    .document-upload-Header-v2 {
        padding-bottom: 2.3125rem
    }

    .document-upload-title-v2 {
        font-size: 16px;
    }

    .document-upload-info-v2 {
        line-height: 20px;
        padding-top: 0rem;
    }

    .document-upload-cantainer-v2 .ant-upload.ant-upload-drag .ant-upload {
        padding: 41px 0 42px;
    }

    .document-upload-text-v2 {
        padding: 25px 0 15px;
    }

    .document-upload-desktop-text-v2 {
        display: none;
    }

    .upload-doc-Mandatory {
        padding-left: .625rem;
    }
}