.gig-detail-container {
  height: auto;
  width: 100%;
  padding: 2.0vw 6.25vw;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.div1 .ant-rate-star-second {
  font-size: 26px;
  color: #7e7e7e;
}

.div1-mobile .ant-rate-star-second {
  font-size: 14px;
  color: #7e7e7e;
}

.header-container {
  display: flex;
  flex-direction: column;
  /* border: 0.063rem solid black; */
}

.header-container .job-title {
  margin: 0.625rem 0 0.625rem 0;
  /* height: 1.313rem; */
  /* font-family: SFProText; */
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  cursor: default;
}

.header-container .job-title-it {
  margin: 0.625rem 0 0rem 0;
}

.g-details-header {
  display: flex;
  /* justify-content: space-between; */
  height: 100%;
  width: 100%;
  cursor: default;
}

.g-details-header > div {
  line-height: normal;
}

.g-details-arrow .arrow {
  height: 1rem;
  width: 1rem;
  margin: 0.15rem 0.475rem 0 0.358rem;
  /* line-height: normal; */
  align-self: center;
  color: #4054b2;
}

.header-text {
  /* width: 4.063rem; */
  height: 1.188rem;
  flex-grow: 0;
  /* font-family: SFProText; */
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  /* line-height: normal; */
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
  /* cursor: pointer; */
}

.header-text23 {
  /* width: 4.063rem; */
  height: 1.188rem;
  flex-grow: 0;
  /* font-family: SFProText; */
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  /* line-height: normal; */
  letter-spacing: normal;
  text-align: left;
  color: #4054b2;
  cursor: pointer;
}

.g-details-facility-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: default;
}

.g-details-facility-left {
  display: flex;
  align-items: center;
  height: 1.063rem;
}

.g-details-facility-right {
  display: flex;
  margin: -0.375rem 0 0px 0;
  display: flex;
  /* position: fixed; */
  right: 24px;
  cursor: pointer;
}

.g-details-facility-right-it {
  margin: -1.375rem 0 0px 0;
}

.g-details-facility-right .div1 svg {
  margin: -4px 0 0px 0;
  /* position: fixed; */
}

.g-details-facility-right .div1 .heart {
  margin: 0px 0 0px -1px;
  height: 28px;
  width: 28px;
  flex-grow: 0;
  object-fit: contain;
  /* position: fixed; */
}

.IsFavoriteCheck {
  color: #ff4343;
}

.g-details-facility-right .div2 .share {
  height: 28px;
  width: 28px;
  margin: -1px 0 0 -1px;
  flex-grow: 0;
  object-fit: contain;
}

.g-details-facility-right .div1 {
  margin: 0 1.53rem 0 0;
  width: 40px;
  height: 40px;
  flex-grow: 0;
  margin: 0 15px 0 0;
  padding: 6px;
  border-radius: 22px;
  border: solid 1px #d4d4d4;
  background-color: #fff;
}

.g-details-facility-right .div2 {
  margin: 0 1.53rem 0 0;
  width: 40px;
  height: 40px;
  flex-grow: 0;
  margin: 0 0 0 0;
  padding: 6px;
  border-radius: 22px;
  border: solid 1px #d4d4d4;
  background-color: #fff;
}

.g-details-facility-container .g-details-facility-name {
  display: flex;
  margin: 0 0.625rem 0 0;
  align-self: center;
}

.company-profile-name-serach {
  cursor: pointer;
}

.g-details-facility-container .g-details-facility-name > div {
  margin: -1px 0.25rem 0 0;
  height: 1.063rem;
  /* font-family: SFProText; */
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.g-details-facility-container .right {
  display: flex;
  height: 0.875rem;
  margin: -0.375rem 0 0 0;
}

.g-details-facility-container .right .ant-rate {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 0.7rem;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: "tnum", "tnum";
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #ffba08;
  /* font-size: 20px; */
  line-height: unset;
  list-style: none;
  outline: none;
}

.g-details-facility-container .right .ant-rate .ant-rate-star:not(:last-child) {
  margin-right: 5px;
  /* height: 18px; */
}

.g-star-rating {
  line-height: normal;
  height: 14px;
}

.g-star-rating .star {
  line-height: normal;
  height: 1px;
  width: fit-content;
  font-size: 2px;
}

.g-details-facility-container .right > div {
  margin: 0 3px 0px 0;
}

.content-container {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  gap: 3.75rem;
  margin: 1.25rem 0 0 0rem;
}

.content-container .ractangle-left {
  /* width: 38.75rem; */
  /* width: 48.43vw; */
  /* max-width: 38.75rem; */
  flex: 1.558;
  height: 26.125rem;
  position: relative;
  overflow: hidden;
  z-index: 1;
  cursor: default;
  border-radius: 4px 4px 0 0;
}

.g-hospital-icon {
  font-size: 1rem;
}

.city-image {
  /* width: 389px; */
  /* width: 442px; */
  width: 100%;
  height: 100%;
  /* margin: 0 0 15px; */
  /* margin: 0rem 0rem 0rem -0.8rem; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  z-index: -1;
}

.content-container .ractangle-right {
  /* max-width: 25rem; */
  min-width: 26.063rem;
  /* width: 31.25vw; */
  flex: 1;
  /* height: 417px; */
  /* margin: 1.188rem 0 1.313rem 2.5rem; */
  /* padding: 1.25rem 1.25rem 0.938rem; */
  /* border-radius: 0.25rem; */
  /* border: solid 0.063rem #e4e4e4; */
  background-color: #fff;
  cursor: default;
}

.content-container .ractangle-right-border {
  border: solid 0.063rem #e4e4e4;
  border-radius: 0.25rem;
  padding: 1.25rem 1.25rem 0.938rem;
}

.GigJobDetailsITUser-Cantainer {
  width: 100%;
  word-wrap: break-word;
}

.Skill-PieChart-Component {
  border-radius: 0.25rem;
  border: solid 0.0625rem #e4e4e4;
  background-color: #fff;
  margin-top: 1.25rem;
  padding: 1.25rem;
  /* max-width: 25rem; */
  height: fit-content;
}

.Gigdetail-Skill-Detail-listing {
  display: flex;
  justify-content: space-between;
  padding-top: 0.625rem;
  font-size: 0.875rem;
  font-weight: normal;
  color: #3e3e3e;
  flex-direction: column;
  font-size: 0.875rem;
}

.Gigdetail-Skill-Requried-HC-Cantainer {
  padding: 1.25rem 0 1.8125rem;
}

.Gigdetail-SkillRequried {
  font-size: 1rem;
  font-weight: normal;
  color: #7e7e7e;
}
.Gigdetail-SkillRequired-Not-Found {
  display: flex;
  justify-content: space-between;
  padding-top: 0.75rem;
}

.Gigdetail-Skill-percent-skill {
  display: flex;
  justify-content: space-between;
  padding-top: 0.75rem;
}

.content-containerIT {
  flex-direction: column !important;
  width: 58.3%;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}

.GigDetail-containerIT {
  display: flex;
  /* justify-content: space-between; */
  grid-gap: 3.75rem;
  gap: 3.75rem;
}

.Gigdetails-IT-User {
  display: flex;
  flex-direction: column;
  width: 42%;
}

.bid-details-container {
  /* margin: 19rem 4.125rem 0.625rem 1.188rem; */
  margin: 23rem 4.125rem 0.625rem 1.188rem;
}

.location-details {
  /* width: 11.563rem; */
  width: max-content;
  height: 1.938rem;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.625rem;
  /* margin: 0.625rem 0 0 1.188rem; */
  margin: 0.625rem 0 0 0rem;
  padding: 0.313rem 0.625rem;
  border-radius: 0.25rem;
  background-color: rgba(30, 30, 30, 0.9);
  /* font */
}

.location-details > div {
  flex-grow: 0;
  /* font-family: SFProText; */
  /* height: 1.313rem; */
  font-size: 1.125rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* position: absolute;
  bottom: 1.938px; */
  text-align: left;
  color: #fff;
}

.bid-details {
  /* width: 7.438rem; */
  width: max-content;
  /* height: 3.313rem; */
  height: auto;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.313rem;
  padding: 0.313rem 0.625rem;
  border-radius: 0.25rem;
  background-color: #118936;
  display: none;
}

.bid-details > div {
  width: max-contentg;
  height: 1.5rem;
  flex-grow: 0;
  font-size: 1.25rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.bid-details > div:nth-child(2) {
  /* width: 4.5rem; */
  /* height: 0.875rem; */
  width: auto;
  height: auto;
  flex-grow: 0;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.content-container .ractangle-right .about-job {
  /* width: 4.875rem; */
  height: 1.1rem;
  flex-grow: 0;
  margin: 0 17.625rem 0.938 0;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.content-container .ractangle-right .about-job-text {
  flex-grow: 0;
  margin: 0.938rem 0 0.938rem 0;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.content-container .ractangle-right .details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.content-container .ractangle-right .details > div {
  height: 1.063rem;
  flex-grow: 0;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
  margin: 0 0 0.938rem 0;
}

.content-container .ractangle-right .ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 0.25rem 0;
}

.bid-details-apply {
  width: 10rem;
  height: 2.625rem;
  margin: 0 0 0.063rem 0;
  padding: 0.75rem 2rem;
  border-radius: 0.25rem;
  background-color: #118936;
  border: 1px solid #118936;
  text-align: center;
  cursor: pointer;
}
.is-expired-gigs {
  background-color: #7e7e7e;
  cursor: not-allowed;
}

.bid-details-apply > div {
  height: 1.125rem;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.bottom-details {
  display: flex;
  margin: 0.938rem 0 0 0;
  flex-direction: row;
  /* justify-content: space-between; */
  justify-content: flex-end;
}

.job-details-bid {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.job-details-bid .salary {
  height: 22px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.job-details-bid .salary-text {
  height: 0.875rem;
  font-size: 12px;
  color: #3e3e3e;
  margin: 0.438rem 0 0 0;
}

/* MOBILE */
.gig-detail-container .search {
  margin: 0.625rem 0.938rem;
}

.gig-detail-container .divider-header {
  margin: -1.563rem 0 -0.375rem 0;
  color: #e4e4e4;
}

.header-container-mobile {
  display: flex;
  flex-direction: column;
  /* border: 0.063rem solid black; */
}

.header-container-mobile .g-details-header {
  display: flex;
  /* justify-content: space-between; */
  height: 100%;
  width: 100%;
}

.header-container-mobile
  .g-details-header
  .g-details-arrow-mobile
  .arrow-mobile {
  width: 1rem;
  height: 1rem;
  padding: 0.188rem 0.313rem 0.188rem 0.375rem;
}

.header-container-mobile .g-details-header .header-text1 {
  line-height: normal;
  /* width: 4.063rem; */
  height: 1.188rem;
  flex-grow: 0;
  /* font-family: SFProText; */
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  /* line-height: normal; */
  letter-spacing: normal;
  text-align: left;
  color: #4054b2;
  cursor: pointer;
  /* margin-left: 0.938rem; */
  margin: 0 0.125rem 0 0.938rem;
}

.header-container-mobile .g-details-header .header-text3 {
  line-height: normal;
  width: 4.063rem;
  height: 1.188rem;
  flex-grow: 0;
  /* font-family: SFProText; */
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  /* line-height: normal; */
  letter-spacing: normal;
  text-align: left;
  color: #4054b2;
  cursor: pointer;
  /* margin-left: 0.938rem; */
  margin: 0 0.925rem 0 0.938rem;
}

.header-container-mobile .g-details-header .header-text22 {
  line-height: normal;
  /* width: 4.063rem; */
  height: 1.188rem;
  flex-grow: 0;
  /* font-family: SFProText; */
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  /* line-height: normal; */
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
  /* cursor: pointer; */
  /* margin-left: 0.938rem; */
  margin: 0 0 0 0.938rem;
  /* margin-left: 0.938rem; */
  /* margin: 0 0 0 0.938rem; */
}

.header-container-mobile .g-details-header .header-text2 {
  line-height: normal;
  /* width: 4.063rem; */
  height: 1.188rem;
  flex-grow: 0;
  /* font-family: SFProText; */
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  /* line-height: normal; */
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
  cursor: pointer;
  /* margin-left: 0.938rem; */
  margin: 0 0 0 0.938rem;
  /* margin-left: 0.938rem; */
  /* margin: 0 0 0 0.938rem; */
}

.header-container-mobile
  .g-details-header
  .g-details-arrow-mobile
  .arrow-mobile
  svg {
  /* margin: 0 0.25rem 0 0; */
  align-self: center;
}

/* .header-container-mobile .header-text { */
/* width: 4.063rem;
  height: 1.188rem;
  flex-grow: 0;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4054b2;
  cursor: pointer; */
/* } */

/* Content Container Mobile */
.content-container-mobile {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 1.25rem 0 0 0rem;
}

.content-container-mobile .ractangle-left {
  width: 100%;
  height: 10.625rem;
  flex-grow: 0;
  margin: 0rem 0rem 1.25rem 0rem;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.content-container-mobile .ractangle-left .g-details-facility-right {
  display: flex;
  margin: 8px 10px 0px 0;
  flex-direction: row-reverse;
  position: initial;
}

.content-container-mobile .ractangle-left .g-details-facility-right > div {
  width: 20px;
  height: 20px;
  flex-grow: 0;
  margin: 0 0 0px 5px;
  padding: 0px;
  border-radius: 22px;
  border: solid 1px #d4d4d4;
  background-color: #fff;
}

.content-container-mobile
  .ractangle-left
  .g-details-facility-right
  .div2
  .share-mobile {
  margin: -5px 0px 0px 1.5px;
  height: 14px;
  width: 14px;
}

.content-container-mobile
  .ractangle-left
  .g-details-facility-right
  .div1
  .heart-mobile {
  margin: -3.6px 0px 0px 2px;
  height: 14px;
  width: 14px;
}

/* .content-container-mobile .ractangle-left .g-hospital-icon {
} */

.content-container-mobile .ractangle-left .city-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  z-index: -1;
}

.content-container-mobile .ractangle-left .location-details {
  /* width: 11.563rem; */
  width: max-content;
  height: 1.938rem;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.625rem;
  margin: 0rem 0 0 0.938rem;
  padding: 0.313rem 0.625rem;
  border-radius: 0.25rem;
  background-color: rgba(30, 30, 30, 0.9);
  /* font */
  flex-grow: 0;
  font-size: 1.125rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.content-container-mobile .ractangle-left .bid-details-mobile-container {
  /* margin: 2.25rem 0rem 0.625rem 0.938rem; */
  margin: 6.25rem 0rem 0.625rem 0.938rem;
  display: none;
}

.content-container-mobile
  .ractangle-left
  .bid-details-mobile-container
  .bid-details {
  width: max-content;
  height: 3.313rem;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.313rem;
  padding: 0.313rem 0.625rem;
  border-radius: 0.25rem;
  background-color: #118936;
}

.content-container-mobile
  .ractangle-left
  .bid-details-mobile-container
  .bid-details
  > div {
  width: max-contentg;
  height: 1.5rem;
  font-size: 1.25rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.content-container-mobile .ractangle-left .bid-details > div:nth-child(2) {
  /* width: 4.5rem; */
  height: 0.875rem;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

/* Facility Details Mobile */

.content-container-mobile .g-details-facility-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.content-container-mobile .g-details-facility-container .job-details {
  /* height: 1.313rem; */
  margin: 0px 0px 0.625rem 0.938rem;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.content-container-mobile
  .g-details-facility-container
  .g-details-facility-name {
  display: flex;
  margin: 0 1.563rem 0 0.938rem;
  align-self: auto;
}

.content-container-mobile
  .g-details-facility-container
  .g-details-facility-name
  > div {
  margin: -1px 0.438rem 0 0;
  /* height: 1.063rem; */
  height: auto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.content-container-mobile .g-details-facility-container .rating-review {
  display: flex;
  flex-direction: row;
  margin: 11px 1.53rem 1.75rem 0.938rem;
}

.content-container-mobile .g-details-facility-container .rating-review > div {
  height: 14px;
  flex-grow: 0;
  margin: 0 0px 0 0;
  padding: 0px;
  background-color: #fff;
}

.content-container-mobile
  .g-details-facility-container
  .rating-review
  .ant-rate {
  height: 12px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 0.7rem;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: "tnum", "tnum";
  display: inline-block;
  margin: 0px 0px 0 0px;
  padding: 0;
  color: #fadb14;
  /* font-size: 20px; */
  line-height: unset;
  list-style: none;
  outline: none;
}

.content-container-mobile
  .g-details-facility-container
  .rating-review
  .ant-rate
  .ant-rate-star:not(:last-child) {
  margin-right: 5px;
}

.g-star-rating {
  line-height: normal;
}

.content-container-mobile
  .ractangle-left
  .g-details-facility-container
  .right
  > div {
  margin: 0 3px 0px 0;
}

/* Ractangle Right Mobile */
.content-container-mobile .ractangle-right {
  width: 100%;
  flex-grow: 0;
  padding: 10px 15px;
  border-radius: 0.25rem;
  background-color: #fff;
}

.content-container-mobile .ractangle-right .about-job {
  height: 1.063rem;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.content-container-mobile .ractangle-right .about-job-text {
  flex-grow: 0;
  margin: 0.938rem 0 0.938rem 0;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.content-container-mobile .ractangle-right .details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 1.063rem;
  margin-bottom: 0.625rem;
}

.content-container-mobile .ractangle-right .details > div {
  /* width: 56px; */
  height: 1.063rem;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
  margin: 0 0 0.938rem 0;
  /* width: 100px; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-container-mobile .ractangle-right .ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 0.25rem 0;
}

.content-container-mobile .ractangle-right .bid-details-apply {
  width: 8.5rem;
  height: 2.625rem;
  flex-grow: 0;
  margin: 0 0 0 0;
  padding: 0.75rem 1.25rem;
  border-radius: 0.25rem;
  background-color: #118936;
  text-align: center;
}

.content-container-mobile .ractangle-right .bid-details-apply > div {
  width: max-content;
  /* font-family: SFProText; */
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.content-container-mobile .divider {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  background-color: #e4e4e4;
  margin: -5px 0 10px 0;
}

.content-container-mobile .divider-mobile {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  background-color: #e4e4e4;
  margin: 0 0 1px 0;
}

.content-container-mobile .bottom-details {
  display: flex;
  margin: 0rem 0 1.813rem 0;
  padding: 0 15px;
  /* justify-content: space-between; */
  justify-content: flex-end;
}

.content-container-mobile .bottom-details .job-details-bid {
  display: flex;
  flex-direction: column;
}

.content-container-mobile .floating-bottom-button-details {
  position: fixed;
  bottom: -2.0rem;
  background: #fff;
  padding: 20px;
  border-top: 0.125rem solid #d4d4d4;
  border-bottom: 0.125rem solid #d4d4d4;
  width: 100%;
}

.content-container-mobile .bottom-details .job-details-bid .package-rate {
  height: 22px;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.content-container-mobile .bottom-details .job-details-bid .package-text {
  height: 14px;
  flex-grow: 0;
  margin: 7px 0px 0 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.PopOver-on-shareBid {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.375rem 0rem;
}

.PopOver-on-shareBid-job {
  font-size: 1.5rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
  padding-bottom: 0.6875rem;
}

.PopOver-on-shareBid-content {
  font-size: 1.125rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
  padding-bottom: 2.5rem;
}

.PopOver-on-shareBid-main {
  display: flex;
  gap: 3.125rem;
}

.shareButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
}

.PopOver-on-shareBid-inside-image {
  width: 3.75rem;
  height: 3.75rem;
}

.PopOver-on-shareBid-inside-content {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2e2e2e;
  cursor: pointer;
}

.PopOver-on-shareBid-inside-content2 {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #118936;
  cursor: pointer;
}

.copyLink {
  margin-left: -0.9063rem;
  transition: 0s all;
}

.share-bid-model23 .ant-modal-close-x {
  font-size: 0.9375rem !important;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .content-container-mobile .ractangle-left .bid-details-mobile-container {
    margin: 2.25rem 0rem 0.625rem 0.938rem;
  }
  .gig-detail-container {
    height: auto;
    width: 100%;
    padding: 0;
    /* padding: 0rem 3.813rem 0rem 3.813rem; */
    display: flex;
    flex-direction: column;
    /* margin: auto 9.55%; */
    /* justify-content: space-evenly; */
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */
    background-color: #fefefe;
  }

  .content-container {
    display: flex;
    /* order: 1; */
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0rem 0 0 0rem;
  }

  .content-container .ractangle-left {
    width: 38.75rem;
    /* max-width: 38.75rem; */
    height: 10.625rem;
    border: 0.063rem solid black;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }

  /* RACTANGLE RIGHT */
  .content-container .ractangle-right {
    /* max-width: 25rem; */
    max-width: 38.75rem;
    padding: 0;
    border-radius: 0.25rem;
    border: solid 0.063rem #e4e4e4;
    background-color: #fff;
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */
  }

  .content-container .ractangle-right .about-job {
    width: 4.875rem;
    height: 1.1rem88;
    flex-grow: 0;
    margin: 0 17.625rem 0.938 0;
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #7e7e7e;
  }

  .content-container .ractangle-right .about-job-text {
    width: 21.125rem;
    flex-grow: 0;
    margin: 0.938rem 1.375rem 0.938rem 0;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
    text-align: left;
    color: #1e1e1e;
  }

  .content-container .ractangle-right .details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .content-container .ractangle-right .details > div {
    height: 1.063rem;
    flex-grow: 0;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #3e3e3e;
    margin: 0 0 0.938rem 0;
  }

  .content-container .ractangle-right .ant-divider-horizontal {
    display: flex;
    clear: both;
    width: 100%;
    min-width: 100%;
    margin: 0.25rem 0;
  }

  /* IMAGE */
  .city-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    object-fit: fill;
    z-index: -1;
  }

  .content-container-mobile .ractangle-right .details > div.pay-package {
    color: #118936;
  }

  .Gigdetail-Skill-Requried-HC-Cantainer {
    padding: 15px 0 1.8125rem;
  }

  .Gigdetail-SkillRequried {
    font-size: 0.75rem;
    font-weight: normal;
    color: #7e7e7e;
  }

  .Gigdetail-Skill-Detail-listing {
    padding-top: 0rem;
  }

  .Skill-PieChart-Component {
    padding: 0.9375rem;
    border: 0.0625rem solid #d4d4d4;
    border-left: none;
    border-right: none;
    border-radius: 0rem;
    border-bottom: none;
    padding-bottom: unset;
    margin-top: 0.9375rem;
  }

  .gig-detail-container .Gigdetails-IT-Users-Mobile {
    /* width: 100%; */
    padding: 0.9375rem 0px 0.9375rem;
  }
}

.bid-place-div{
  display: flex;
  width: auto;
  background-color: #FFF0A3;
  padding: 20px 20px 20px 20px;
  border-radius: 4px;
  flex-wrap: wrap;
  margin-top: 5px;
}
.bid-place-text{
  font-weight: 500;
  font-size: 14px;
  color: #3E3E3E;
  word-break: break-word; 
  overflow-wrap: break-word; 
  flex: 1;
  min-width: 0; 
}
.alert-icon{
  margin: 0px 6px 5px 0px;
}
.place-Bid-btn-disabled {
  height: 42px !important;
  border-radius: 5px !important;
  border: solid 1px #c4c4c4;
  background-color: #fff;
  cursor: not-allowed;
  line-height: 1.13;
}
.text-color-disable{
  font-weight: 600;
  color: #D4D4D4;
  font-size: 16px;
}
.text-color-enable{
  font-weight: 600;
  font-size: 16px;
  color:white
}