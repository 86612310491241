.TextInputParentContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.TextInputTitleContainer {
  display: flex;
  align-items: center;
}
.TextInputTitle-field {
  color: #212121;
  font-weight: 800;
  font-size: 1.5rem;
  margin-right: 1.25rem;

}
.TextInputType-field {
  color: #7E7E7E;
  /* margin-top: 5px; */

  font-weight: 400;
  font-size: 0.625rem;
  line-height: 1.5rem;
  padding-top: 4px;
}
.textInputContainer {
  width: 50%;
}
.TextInputStylefield {
  border: none;
  border-bottom: 1px solid;
  border-color: #ccc;
  border-radius: 0;
  margin-top: 2rem;
  padding-left: 0;
  font-size: 30px;
  height: 40px;
}
.TextInputStylefield::placeholder {
  color: #777777;
}
.TextInputStylefield:focus {
  box-shadow: none; /* Remove focus box shadow */
  border-color: #ccc; /* Set the border color on focus */
}
.TextInputStylefield:hover {
  box-shadow: none !important; /* Remove focus box shadow */
  border-color: #ccc !important; /* Set the border color on focus */
}
.TextInputStylefieldError {
  border: none;
  border-bottom: 1px solid;
  border-color: #dc2d2d;
  border-radius: 0;
  margin-top: 2rem;
  padding-left: 0;
  font-size: 30px;
  height: 40px;
}
.TextInputStylefieldError::placeholder {
  color: #777777;
}
.TextInputStylefield .ant-input{
  font-size: 30px;
}
.TextInputStylefieldError:focus {
  box-shadow: none !important; /* Remove focus box shadow */
  border-color: #dc2d2d !important; /* Set the border color on focus */
}
.errorIcon{
  color:#dc2d2d;
  margin-right: 5px;
}
.errorMsg{
  color:#dc2d2d;
  margin-top: 0.8rem;
  font-size: 0.875rem;
  font-weight: 400;
}

.errorMsg-content{
  color:#dc2d2d;
  /* margin-top: 0.8rem; */
  margin-left: 0.25rem;
  font-size: 0.875rem;
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  .textInputContainer {
    width: 90%;
  }
  .TextInputTitle-field {
    color: #212121;
    font-weight: 800;
    font-size: 1rem;
  }
  .TextInputStylefield {
    border: none;
    border-bottom: 1px solid;
    border-color: #ccc;
    border-radius: 0;
    margin-top: 1rem;
    font-size: 16px;
  }
  .TextInputStylefieldError {
    border: none;
    border-bottom: 1px solid;
    border-color: #dc2d2d;
    border-color: #ccc;
    border-radius: 0;
    margin-top: 1rem;
    font-size: 16px;
  }
  .TextInputType-field{
    line-height:1rem ;
  }
}
.email-verification-div{
  margin-top: 14px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #4054B2;
  background: #ECEEF770;
  display: flex;
  justify-content: space-between;
  }