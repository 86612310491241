.datepickers-div {
    margin-top: 55px;
}

.datepickers-div .ant-picker {
    width: 100%;
    margin-bottom: 10px;
}
.ant-select-selection--single {
    pointer-events: none;
}
.modal-send-request-btn {
    background-color: #fff;
    border-radius: 5px;
    border: solid 1px #118936;
    background-color: #fff;
    width: 100%;
    height: 42px;
    cursor: pointer;

    font-size: 16px;
    font-weight: 600;
    color: #118936;
    text-align: center;
}

.time-dropdown {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 40px;
    width: 70px;
    text-align: left;
    z-index: 1;

}

.time-dropdown-text {
    z-index: 1;
    font-size: 14px;
    font-weight: normal;
    color: #1e1e1e;

}

.time-dropdown-menu {
    z-index: 100000;
    overflow-y: scroll;
    height: 250px;
    overflow-x: hidden;
    border: 1px solid #e4e4e4;
}

.time-dropdown-phase-menu {
    z-index: 1;
    height: auto;
}


.time-dropdown-menu::-webkit-scrollbar {
    width: 7px;
}

.time-dropdown-menu::-webkit-scrollbar-track {
    background: #fff;
}

.time-dropdown-menu::-webkit-scrollbar-thumb {
    background: #e4e4e4;
    border-radius: 12px !important;
    cursor: pointer !important;
}

.interview-drawer-menu-item {
    padding: 3px 20px !important;
}

.dropdown-div .ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {

    margin-top: -10px;
    margin-bottom: 0px;
}

.selectDateBtn {
    color: #fff;
    height: 30px;
    background-color: #118936;
    margin-left: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.selectDateBtn:hover {

    border-color: #118936;
    color: #118936;
}

.cancelDateBtn {
    height: 30px;
    margin-left: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 50%;


}

.cancelDateBtn:hover {

    border-color: #118936;
    color: #118936;
}

.DatePickerChat .ant-picker-panel-container {
    margin-top: 15rem;
    margin-left: 2rem;

}

.Custom-date-picker .ant-modal-body {
    padding: 1.5rem !important;
}

@media only screen and (max-width: 800px) {
    .datepickers-div {
        margin-top: 30px;

    }
}

@media screen and (max-width: 769px) {
    .DatePickerChat .ant-picker-panel-container {
        margin-top: 15rem;
        margin-left: 2rem;
    }
}

.interviewError {
    color: red;
    padding: 0.5rem;
    text-align: center
}