.BottomButtonsParentContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
    bottom: 2rem;
}
.buttonsRowContainer{
    width: 50%;
}
.Btns-row1{
    margin-top: 10%;
    text-align: center;
    width: 50%;
}
.temp-gap-div{
    width: 1.563rem;
}

.Back-btn{
    width: 100%;
    background-color: white;
    padding: 10px;
    color:#000000;
    text-align: center;
    transition: all 200ms;
    border:1px solid #3e3e3e;
    vertical-align: middle;
    line-height: 1.30;
    height: 3.125rem;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    letter-spacing: 0em;
}
.Back-btn:focus{
    width: 100%;
    background-color: white;
    padding: 10px;
    color:#000000;
    text-align: center;
    /* font-size: 12px;
    font-weight: 600; */
    transition: all 200ms;
    border:1px solid #3e3e3e;
    vertical-align: middle;
    line-height: 1.30;
    height: 3.125rem;
    border-radius: 4px;
}
.Back-btn-div{
    margin: auto;
}
.Back-btn:hover{
    background-color: white;
    color: #6B6B6B;
    border:1px solid #6B6B6B;
}
.Next-btn{
    width: 100%;
    background-color: black;
    padding: 10px;
    color:white;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    border:0.5px solid #000;
    vertical-align: middle;
    line-height: 1.30;
    border-radius: 4px;
    height: 3.125rem;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    letter-spacing: 0em;
    transition: none;
    --antd-wave-shadow-color: transparent;
}
.Next-btn:focus{
    width: 100%;
    background-color: #333333;
    padding: 10px;
    color:white;
    text-align: center;
    height: 3.125rem;
    vertical-align: middle;
    line-height: 1.30;
    border-radius: 5px;
    border-color: #000000;
}
.Next-btn-div{
    margin: auto;
}
.Next-btn:hover{
    background-color: #333333;
    color: white;
    border:0.5px solid #000;
}
.Next-btn .ant-btn::before{
    transition: none;
}
@media screen and (max-width: 768px) {
    /* .buttonsRowContainer{
        width: 100%;
    } */
    .Btns-row{
        margin-top: 10%;
        text-align: center;
        width: 90%;
        /* height: 6rem !important; */
        margin-left: 5%;
        border: none;
    }
    .Back-btn-div{
        margin: auto;
        margin-top: 1rem;
        height: 2.5rem !important;
    }
    .Next-btn{
        height: 2.5rem;
        min-height: 2.5rem;
        font-size: 0.75rem;
        font-weight: 600;
        line-height: 0.895rem;
        background-color: black;
        color: white;
    }
    .Back-btn{
        height: 2.5rem;
        min-height: 2.5rem;
        font-size: 0.75rem;
        font-weight: 600;
        line-height: 0.895rem;
    }


}
.cancel-btn-mobile{
    font-size: 16px;
    font-weight: 700;
    line-height: 19.09px;
    width: 47%;
    height: 57px;
    color: #1E1E1E;
    border-radius: 4px;
    border: 1px solid #1E1E1E;
    background-color: white;
  }
.save-btn-mobile{
    width: 47%;
    height: 57px;
    border-radius: 4px;
    background: black;
    color: white;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.09px;
    border: 1px solid black;
}
.main-div-mobile{
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.25);
    padding: .625rem 0;
    right: 1px;
}
.button-mob-view{
  display: flex;
  justify-content: space-around;
  width: 100%;
  overflow: hidden;
}
.back-btn-mobile{
  width: 20%;
  height: 55px;
  border-radius: 4px;
  border: 1px solid #D8D8D8;
  background-color: white;
}
.next-btn-mobile{
    display: flex;
    width: 70%;
    height: 55px;
    padding: 12px 64px 12px 65px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    background: black;
    color: white;
    cursor: pointer;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    border: 1px solid black;
  }
  .next-btn-mobile:focus{
    /* width: 100%; */
    background-color: black;
    /* padding: 10px; */
    color:white;
    text-align: center;
    /* height: 3.125rem; */
    /* vertical-align: middle; */
    /* line-height: 1.30; */
    border-radius: 5px;
    border-color: #000000;
}
.btn-read-rules{
    color: black;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }
.cancel-btn-mobile{
    font-size: 16px;
    font-weight: 700;
    line-height: 19.09px;
    width: 47%;
    height: 57px;
    color: #1E1E1E;
    border-radius: 4px;
    border: 1px solid #1E1E1E;
    background-color: white;
  }
.save-btn-mobile{
    width: 47%;
    height: 57px;
    border-radius: 4px;
    background: black;
    color: white;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.09px;
    border: 1px solid black;
}