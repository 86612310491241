.TextInputParentContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.TextInputTitleContainer {
  display: flex;
  align-items: center;
}
.TextInputTitle-dropdown {
  color: #3e3e3e;
  font-weight: bolder;
  font-size: 1.5rem;
  margin-right: 1.25rem;
}
.TextInputType-dropdown {
  color: #7E7E7E;
  /* margin-left: 0.7rem; */
  margin-top: 3px;
  font-size: 0.625rem;
  font-weight: 400;
}
.textInputContainer {
  width: 50%;
}
.ant-input-affix-wrapper{
  box-shadow: none !important;
}
.TextInputStyleDropDown .ant-input{
  font-size: 30px;
  padding-left: 0;
}
.TextInputStyleDropDown .ant-input::placeholder  {
  font-size: 1rem;
  font-weight: 400;

  /* color: red; */
}
.TextInputStyleDropDown {
  border: none;
  border-bottom: 1px solid;
  border-color: #ccc;
  border-radius: 0;
  margin-top: 2rem;
  /* padding-bottom: 0.5rem; */
  padding-left: 0;
  height: 40px;
  font-size: 30px;
}
.TextInputStyleDropDownAutoComplete .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
  border-color: #ccc !important;
}
.TextInputStyleDropDown:focus {
  box-shadow: none; /* Remove focus box shadow */
  border-color: #ccc; /* Set the border color on focus */
}
.TextInputStyleDropDown:hover {
  box-shadow: none; /* Remove focus box shadow */
  border-color: #ccc; /* Set the border color on focus */
}
.TextSubTitle{
  color: #3E3E3E;
  margin-top: 0.8rem;
}
@media screen and (max-width: 768px) {
  .textInputContainer {
    width: 90%;
  }
  .TextInputTitle-dropdown {
    color: #3e3e3e;
    font-weight: bolder;
    font-size: 1.1rem;
  }
  .TextInputStyleDropDown {
    border: none;
    border-bottom: 1px solid;
    border-color: #ccc;
    border-radius: 0;
    margin-top: 1rem;
  }
  .TextInputType-dropdown{
    line-height:1rem ;
  }
  .TextInputStyleDropDown .ant-input{
    font-size: 16px !important;
    padding-left: 0;
  }
}
