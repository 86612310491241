.assessment-link-container {
    width: 100%;
    height: 52px;
    text-decoration: none;
    flex-grow: 0;
    border-radius: 4px;
    padding: 11px 12px 11px 10px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    background-color: #f6eed1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  
  }
  .assessment-link-context{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #3e3e3e;
  }
  
  .assessment-link-icon{
    width: 30px;
    height: 30px;
    flex-grow: 0;
    border-radius: 4px;
    border: solid 0.5px #777;
    /* background-color: #f6eed1; */
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .assessment-link-container:hover{
    background-color: var(--assessmentHoverEffect) !important;
  }
  
  
  