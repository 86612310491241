.add-edit-box {
  /* height: 11.38rem; */
  flex-grow: 0;
  /* margin: 0.94rem 0 0; */
  padding: 0.94rem 0.63rem 0.88rem;
  background-color: #f2f2f2;
  margin-bottom: 20px;
}
.add-edit-box .cancel-add-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 0.06rem solid #e4e4e4;
  padding-top: 15px;
}
.cancel-add-div .cancel {
  width: 8.44rem;
  height: 2.25rem;
  /* margin: 0.56rem 1.25rem 0 0; */
  padding: 0.63rem 2.75rem 0.56rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
  cursor: pointer;

  /* font */
  font-size: 0.88rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #4e4e4e;
  padding: 0.63rem 0;
}

.cancel-add-div .add {
  width: 8.44rem;
  height: 2.25rem;
  /* margin: 0.56rem 0.19rem 0 1.25rem; */
  padding: 0.63rem 0;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
  background-color: var(--primaryColor);
  cursor: pointer;

  /* font */
  font-size: 0.88rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .add-edit-box {
    width: 100%;
    margin-bottom: 0px;
  }

  .cancel-add-div .add {
    width: 8.94rem;
  }

  .cancel-add-div .cancel {
    width: 8.94rem;
  }
}



.cancel-summary-div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  border-top: 0.06rem solid #e4e4e4;
  padding-top: 15px;
}

.cancel-summary-btn{
  width: 8.44rem;
  height: 2.25rem;
  padding: 0.63rem 2.75rem 0.56rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
  border: solid 0.06rem #1E1E1E;
  background-color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #1E1E1E;
  padding: 0.63rem 0;
  margin-right: 20px;
}

.add-summary-btn {
  width: 8.44rem;
  height: 2.25rem;
  padding: 7px;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
  background-color: #000000;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.add-summary-disable{
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25) !important;
  background-color: #535353 !important;
  cursor: not-allowed;
}

@media screen and (max-width: 768px) {
  .cancel-summary-div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; 
  }
  .add-summary-btn{
    width: 7rem;
  }
  .cancel-summary-btn{
    width: 7rem;
  }
}

@media screen and (max-width: 480px) {
  .add-summary-btn{
    width: 7rem;
  }
  .cancel-summary-btn{
    width: 7rem;
  }
}