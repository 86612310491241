.dashboardContainer {
  padding: 1.25rem 6.25rem 3.125rem;
  display: flex;
  gap: 1.875rem;
  background-color: #f8f8f8;
}

.rightSectionDashboard,
.leftSectionDashboard {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.rightSectionDashboard {
  width: 32.7%;
}

.leftSectionDashboard {
  width: 68.3%;
}

/********** For Mobile View  Media Query Start **********/
@media screen and (max-width: 1000px) {
  .dashboardContainer {
    padding: 0.625rem 0.625rem 1.25rem;
    flex-direction: column;
    gap: 1.25rem;
    background-color: #ffffff;
  }

  .rightSectionDashboard,
  .leftSectionDashboard {
    width: 100%;
  }
}
/********** For Mobile View  Media Query End **********/
