.screen-button-container {
    display: flex;
    gap: 25px;
    justify-content: center;
    padding:131px 0px 327px 0px;
  }
  
  .screen-custom-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid #1E1E1E;
    padding: 18px 70px;
    background-color: #f1f1f1;
    cursor: pointer;
     
    font-size: 12px;
    
    font-weight: 600;
    
    color: #1E1E1E;
     
  }

  .screen-custom-button-2{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid #1E1E1E;
     padding: 18px 70px;
    background-color: #FBDF50;
    cursor: pointer;
    
    font-size: 12px;
     
    font-weight: 600;
    
    color: #1E1E1E;
     
  }

  @media screen and (max-width: 1000px) {
    .screen-button-container {
        display: flex;
        flex-direction: column;
        gap: 25px;
        justify-content: center;
        padding:71.07px 25px 57px 25px;
        
      }
      
      .screen-custom-button {
         
         
        padding: 13px 140px;
         
         
      }
    
      .screen-custom-button-2{
        
        
        
         padding: 13px 140px;
       
        
        
       
         
      }

     }




  
   
  
  
  