.mainSec {
  padding: 0 30px;  
}
 
.bannerSec{
    width: 100%;
    display: flex;
    justify-content: flex-end;    
}
 
.bannerImg{
    width: 100%;
    height: auto;
    margin-top: -3px;
}
 
.bannerImg-mobile{
  display: none;
  width: 100%;
}
.fw-600{
  font-weight: 600;
}
.fw-400{
  font-weight: 400;
}
.gigs-content-landing{
  display: flex;
  object-fit: cover;
}
 
.Slider-Container{
  border: 1px solid #E4E4E4;
  margin-top: 15px;
  min-height: 100px;
}
.Heading-OurCUlture{
  font-size: 16px;
  color: #1E1E1E;
  padding: 15px 0 0 15px;
  font-weight: 600;
}

@media (max-width: 786px) {
  .Heading-OurCUlture {
    font-size: 14px;
  }
}

.job-headings-emp{
  font-weight: 600;
  font-size: 16px;
  margin-top: 10px;
  margin-left: 20px;
  color: #1E1E1E;
  display: flex;
  flex-direction: row;
}
.gigs-content{
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
}
 
.emp-gig-page{
  max-width: 99% !important;
  width: 99% !important;
}

.Landing-page-gig-container > div:nth-child(3)  > .emp-gig-page { float: right !important;  }
.Landing-page-gig-container > div:nth-child(2)  > .emp-gig-page { margin: auto !important;  }
 
.job-length-emp{
  width: 30px;
  object-fit: contain;
  background-color: #4054b233;
  margin: 0 0 0 5px;
  color: #4054b2;
  font-size: 14px;
  font-weight: 500;
  border-radius: 12px;
  text-align: center;
  padding-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}
 
@media (max-width: 768px) {
  .bannerImg {
    display: none;
  }
  .bannerImg-mobile {
    display: block;
    height: auto;
    width: 100%;
  }
  .mainSec {
    padding: 10px;    
  }
  .heading{
    border: none !important ;
    padding: 4px;
  }
  .why-do-text{
    margin-bottom: -5px;
    color: #3E3E3E;
   
 }
 .followUS{
  height: 107px !important;
 }

}

@media (max-width: 768px) {
  .job-headings-emp{
    margin-left: 2px;
    font-size: 14px;
  }
  .Heading-OurCUlture > p{
    margin-left: -14px;
  }
  .OurCultureBorder{
    border: 1px solid #E4E4E4;
  }
  .Slider-Container{
    border: none;
  }
}
.followUsText{
  position: absolute;
  top: 13px;
  font-size: 14px;
  font-weight: 400;
}
 
/* Content wala div */
.pvt-container {
    width: 100%;
    margin: 20px auto;
    border-radius: 5px;
    overflow: hidden;
  }
 
  .heading {
    border-bottom: 1px solid #E4E4E4;
    color: #fff;
    align-self: flex-start;
    padding: 15px;
    /* width: 106%; */
    /* padding-left: 20px; */
    /* padding-bottom: 10px; */
    /* margin-left: -14px; */
  }
 
 .why-do-text{
    /* margin-left:5px; */
    font-family: SFProText;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
 }

 @media (max-width: 786px) {
  .why-do-text {
    font-size: 14px;
  }
}
  .content {
    padding: 5px;
    padding-left: 15px;
    color: #3E3E3E;
      font-weight: 400;
      font-size: 16px;
      line-height: 19.09px;
      margin-top: 10px;
  }
 
  .followUS {
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #E4E4E4
    ;
    border-radius: 5px;
    overflow: hidden;
    height: 100px;
  }
 
  .social-icons {
    display: flex;
    justify-content: space-evenly;
    justify-content: center;
    width: 400px;
  }
 
  .social-icons img {
   
    margin: 0 10px 10px ;
  }
  .profileDivider3{
    border: 1px solid black;
  }
 
  .comp-main{
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 10px;
  }
  .Carausel-btn-left {
    position: absolute;
    left: 0;
    z-index: 1; /* Ensure it's above the Carousel */
    color: #ffffff;
    height: 60px;
    width: 60px;
    top: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
  }
  .carausel-btn-right {
    position: absolute;
    right: 0;
    z-index: 1; /* Ensure it's above the Carousel */
    color: #ffffff;
    height: 60px;
    width: 60px;
    top: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
  }
  .Carausel-btn-left:hover,
  .Carausel-btn-left:focus, /* For accessibility */
  .Carausel-btn-left:active {
  /* Override default styles */
  color: #ffffff; /* Set the desired color */
  background-color: rgba(255, 255, 255, 0.5); /* Set the desired background color */
}
 
.carausel-btn-right:hover,
.carausel-btn-right:focus, /* For accessibility */
.carausel-btn-right:active {
  color: #ffffff; /* Set the desired color */
  background-color: rgba(255, 255, 255, 0.5); /* Set the desired background color */
}
 
  @media (max-width: 768px) {
    .carausel-btn-right {
      height: 30px;
      width: 30px;
      top: 60%;
      background-color: rgba(255, 255, 255, 0.5);
      border: none;
    }
    .Carausel-btn-left{
      height: 30px;
      width: 30px;
      top: 60%;    
      background-color: rgba(255, 255, 255, 0.5);
      border: none;
    }
 
  }
 
.HomePage-JobsMayLike-Card {
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;
    list-style: none;
    grid-gap: 0.938 rem !important;
    width: 100%;
    flex: 0 0 33.333333%;
    font-size: 1.5em;
    margin: 0 rem 0 0 0;
    gap: 0.938 rem;
  }
 
  .slick-track > div:nth-child(3) > div > div > img { float: right !important;  }
  .slick-track > div:nth-child(2) > div > div > img { margin: auto !important;  }

  .HomePage-MostPopularJobs-Cantainer {
    display: flex;
    padding-top: 1.875rem;
    padding-bottom: 1.3rem;
    justify-content: space-between;
    align-items: baseline;
  }
 
  .HomePage-MostPopularJobs-text {
    color: #3e3e3e;
    font-size: 1.5rem;
    font-weight: 600;
    display: flex;
  }
 
  .HomePage-MostPopularJobs-text-Mobile {
    font-size: 1rem;
    font-weight: 600;
    color: #3e3e3e;
    display: flex;
    align-items: center;
  }
 
  .HomePage-MostPopularJobs-text-Mobile span {
    color: #4054b2;
  }
 
  .HomePage-SeeAllJobs {
    font-size: 1rem;
    font-weight: 500;
    color: #4054b2;
    cursor: pointer;
  }