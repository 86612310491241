.messageInboxContainer {
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
}

.messageTab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.3125rem;
  padding: 0.3125rem;
  border-radius: 0.25rem;
  border: solid 0.0625rem #e4e4e4;
  background-color: #f3f3f3;
}

.messageBtn {
  min-width: 7.8125rem;
  padding: 0.8125rem 0rem;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  color: #777;
  cursor: pointer;
}

.messageBtnActive {
  border-radius: 0.25rem;
  box-shadow: 0.0625rem 0.0625rem 0.0625rem 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: #434343;
}

.messageInbox {
  display: flex;
  flex-direction: column;
  gap: 0.0625rem;
  /* max-height: 20.3125rem; */
  overflow-y: auto;
  box-shadow: 0 0.0625rem 0 0 #eee;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.AdminSubject-bold {
  color: #1E1E1E;
  font-size: .75rem;
  font-style: normal;
  font-weight: 400;
}

.messageInbox::-webkit-scrollbar {
  width: 0.25rem;
}

.messageInbox::-webkit-scrollbar-track {
  box-shadow: 0 0 0 0 #dedede;
  background-color: #eee;
}

.messageInbox::-webkit-scrollbar-thumb {
  border-radius: 1.25rem;
  background-color: #999;
}

.messageItem {
  padding: 0.75rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  box-shadow: 0 0.0625rem 0 0 #eee;
  background-color: #fff;
  cursor: pointer;
}

.messageItem .userStatus .ant-badge-dot {
  width: 0.5rem;
  height: 0.5rem;
}

.messageItem:hover {
  background: #fafafa;
}

.messageInboxEmpty {
  height: 18.75rem;
  gap: 1.375rem;
  padding: 1.5625rem;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.messageInboxEmpty > span:first-child {
  font-size: 1rem;
  font-weight: 600;
  line-height: normal;
  color: #999;
}
.messageInboxEmpty > span:nth-child(2) {
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  color: #1e1e1e;
}
.messageInboxEmpty > span:last-child {
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.93;
  color: #1e1e1e;
}

.messageItem.messageInboxEmpty:hover {
  background-color: #fff;
  cursor: default;
}

.leftMessageItem {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.1125rem;
}

.leftMessageItemRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftMessageItemRow .leftMessageInfoFirst {
  display: flex;
  gap: 0.3125rem;
  flex: 1;
}

.leftMessageItemRow > .leftMessageInfoFirst span {
  font-size: 0.75rem;
  font-weight: 300;
  line-height: normal;
  color: #999;
}

.leftMessageItemRow > .leftMessageInfoFirst span:first-child {
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: normal;
  color: #1e1e1e;
}

.leftMessageItemRow > .leftMessageInfoFirst span:first-child,
.leftMessageItemRow > .leftMessageInfoFirst span:nth-child(2) {
  max-width: 20vw;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.leftMessageItemRow .messageBidRAte {
  font-weight: normal;
  color: #666;
  width: 100%;
}

.messageBidRAte .messageText {
  font-size: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 1rem;
  max-width: 40vw;
}

.messageTimeFav {
  display: flex;
  gap: 0.4375rem;
  align-items: center;
}

.messageTimeFav > span {
  font-size: 0.625rem;
  line-height: normal;
  color: #777;
}

.messageTimeFav .ant-rate {
  line-height: normal;
  height: 1.25rem;
}

.messageCountBage .ant-badge-count {
  font-size: 0.625rem;
  min-width: 1.0625rem;
  height: 0.875rem;
  line-height: 0.875rem;
  background-color: #118936;
}
.messageCountBage .ant-badge-dot {
  width: 1.0625rem;
  height: 0.875rem;
}

.Admin-dashboard-inbox-msg {
  font-size: 8px !important;
  font-style: normal;
  font-weight: 500 !important;
  border-radius: 2px;
  background: #999;
  color: #fff !important;
  padding: 0px 4px 2px;
  display: flex;
  align-items: center;
}

/********** For Mobile View  Media Query Start **********/
@media screen and (max-width: 1000px) {
  .messageInboxContainer {
    gap: 0rem;
  }

  .messageTab {
    justify-content: space-between;
    gap: 0rem;
    padding: 0rem;
    border-radius: unset;
    border: none;
    border-bottom: solid 0.0625rem #e4e4e4;
    background-color: initial;
  }

  .messageBtn {
    flex: 1;
    padding: 0.8125rem 0rem 0.6875rem;
    font-weight: 500;
  }

  .messageBtnActive {
    border-radius: unset;
    box-shadow: unset;
    background-color: unset;
    color: #118936;
    border-bottom: solid 0.125rem #118936;
  }

  .messageInbox {
    gap: 0.125rem;
    max-height: 20.625rem;
  }

  .messageInbox::-webkit-scrollbar {
    width: 0.375rem;
  }

  .messageInbox::-webkit-scrollbar-track {
    box-shadow: 0 0 0 0.0313rem #dedede;
  }

  .messageInboxEmpty {
    height: unset;
    gap: 0.9375rem;
    padding: 1.125rem 0.9375rem 1.25rem;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  .messageInboxEmpty > span:first-child {
    font-size: 0.875rem;
  }
  .messageInboxEmpty > span:nth-child(2) {
    font-size: 0.75rem;
  }
  .messageInboxEmpty > span:last-child {
    font-size: 0.75rem;
    line-height: 1.92;
  }
  .leftMessageItemRow > .leftMessageInfoFirst span:first-child,
  .leftMessageItemRow > .leftMessageInfoFirst span:nth-child(2) {
    max-width: 25vw;
  }
  .messageBidRAte .messageText {
    max-width: 60vw;
  }
}
/********** For Mobile View  Media Query End **********/
