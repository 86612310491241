.bhsContainer {
  padding: 1.25rem;
  border-radius: 0.25rem;
  border: solid 0.0625rem #e4e4e4;
  background-color: #fff;
}

/* For BHS Filter & Sort */
.bhsFltSortContainer {
  gap: 2.78%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* For BHS Card */
.bhsCardContainer {
  margin-top: 0.9375rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

/********** For Mobile View  Media Query Start **********/
@media screen and (max-width: 768px) {
  .bhsContainer {
    padding: 0rem;
    border-radius: none;
    border: none;
    background-color: unset;
  }

  .bhsFltSortContainer {
    gap: 8px;
    flex-direction: column;
  }

  .bhsCardContainer {
    margin-top: 10px;
    gap: 8px;
  }
}
/********** For Mobile View  Media Query End **********/
