.ViewResume-container {
    background-color: #fff;
    padding: 2.5rem 6.625rem 3.0625rem 5.875rem;
}

.ViewResume-Candidate-Name {
    font-size: 1.5rem;
    font-weight: 600;
    font-style: normal;
    text-align: center;
    color: #1e1e1e;
    padding-bottom: .625rem
}

.ViewResume-link {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    color: #1e1e1e;
}

.ViewResume-heading-section {
    font-size: 1.125rem;
    font-weight: 500;
    font-style: normal;
    text-align: left;
    color: #1e1e1e;
}

.ViewResume-sub-heading-section {
    font-size: 1rem;
    font-weight: 500;
    font-style: normal;
    color: #1e1e1e;
}

.ProfessionalExperience-Responsibilities-listing {
    font-size: .875rem;
    font-weight: 300;
    font-style: normal;
    text-align: left;
    color: #1e1e1e;
    padding-top: .625rem;
    /* width: 40rem; */
}

@media screen and (max-width: 480px) {
    .ViewResume-Candidate-Name {
        font-size: 1.125rem;
        padding-bottom: 1.125rem;
    }

    .ViewResume-link {
        word-wrap: break-word;
        font-size: 1.125rem;
    }

    .ViewResume-container {
        padding: 1.25rem .9375rem 3.0625rem;
    }

    .ViewResume-heading-section {
        font-size: 1rem;
    }

    .ViewResume-sub-heading-section {
        font-size: .875rem;
    }
    
    .ProfessionalExperience-Responsibilities-listing {
        font-size: .75rem;
        padding-top: .4375rem;
    }

    .ViewResumeDownloadPDF-cantainer {
        padding-top: 2.5rem !important;
    }

    .ViewResume-sub-heading-section-Left {
        text-align: right;
        padding-left: 1.875rem;
    }
}