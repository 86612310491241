.empty-container-favorite{
  margin: auto;
  /* width: 90%; */
  margin-top: 40px;
  /* background-color: yellow; */
  text-align: center;
}
.empty-container-favorite div {
  font-size: 26px;
  color:#1e1e1e;
  font-weight: 400;
}
 
.favorite-empty-container{
  margin-bottom:4%;

}
.empty-container-favorite span {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #7e7e7e;
}

.myfavorites-container {
    padding: 2.5rem 2.5rem 1.25rem 2.5rem; 
} 

.myfavorites-section .sgigs-information .information-right .sgigs-rating {
  margin: -1px 0 0 8px;
}
.myfavorites-section .sgigs-information .information-right .review {
  margin: -1px 0 0 5px;
}

.myfavorites-section .sgigs-review .bid-posted-on {
  margin-top: 5px;
}

.myfavorites-section .anticon svg {
  width:24px !important;
  height:24px !important;
}
.myfavorites-section .sgigs-div1{
  margin:3px 0px 0px 0px !important;
}

.myfavorites-heading{ 
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  color: #3e3e3e;
  display: flex;
  align-items: center;
}

.myfavorites-subheading{
  font-size: 14px;
  letter-spacing: normal;
  color: #7e7e7e;;
}

.myfavorites-section{
  display: grid;
  gap: .625rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  flex-wrap: wrap;
  padding-bottom: 3.063rem;
}

.Favorites-card-width {
  grid-template-columns: 1fr 1fr 1fr;
}

.favorite-pagination{
  display: flex;
  justify-content: center;
  margin-top: 10vh;
  padding-bottom: .625rem;
}

.myfavorites-main-container{
  background-color: #fff;
  overflow: hidden;
}

.empty-container-main-favorite{
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .myfavorites-container {
    padding: 15px 0px 15px 15px; 
} 
.Empty-my-fav{
  max-height:100px ;
  max-width:90px;
}

.myfavorites-section .sgigs-information .information-right .sgigs-rating {
  margin: 0 0 0 5px;
}

.myfavorites-section .anticon svg {
  width:20px !important;
  height:20px !important;
}

.myfavorites-section .sgigs-div1{
  margin:3px 0px 0px 0px !important;
}

.empty-container-favorite span {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #7e7e7e;
}

.empty-container-favorite div {
  font-size: 16px;
  color:#1e1e1e;
  font-weight: 400;
}

.myfavorites-heading{ 
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  color: #3e3e3e;
}

.myfavorites-subheading{
  font-size: 0.75rem;
  letter-spacing: normal;
  color: #7e7e7e;;
}
.myfavorites-section .sgigs-thecard {
   

   flex-basis: none ; 
}
.myfavorites-section .sgigs-review .bid-posted-on{
  font-size: 10px !important;
}
.myfavorites-section .sgigs-thecard {
  padding-bottom:10px !important;
}
.myfavorites-section{
  display: flex;
  gap: 5px;
  padding: 0px 15px 15px;
}

.empty-container-main-favorite{
  margin-bottom: 15px;
}

}
