.sgigs-receiveBidCard {
  display: flex;
  /* flex: 0 0 33.333333%; */
  flex-direction: row;
  justify-content: space-evenly;
}

.sgigs-information-pvt-tag .badge {
  margin-left: 0rem;
}

.sgigs-thecard {
  /* width: 28.7vw;
  height: 14.5rem;
  border: 1px solid #eee;
  margin: 20px 20px 20px 0;
  padding: 20px 0 0;
  border-radius: 3px;
  box-shadow: 1px 1px 1px 1px #eee;
  background-color: #fff; */
  /* width: 390px; */
  /* width: 30.46875%; */
  /* width: 24.375rem; */
  /* height: 284px; */
  /* margin: 1.26rem 1.26rem 1.26rem 1.512rem; */
  /* margin: 1.25rem 0 0 0; */
  max-width: 30.375rem;
  padding: 0rem;
  border-radius: 0.126rem;
  /* border: solid 0.063rem #e4e4e4; */
  background-color: #fff;
  /* position: relative; */
  flex: 1;
  flex-basis: 24.38rem;
  cursor: pointer;
  border-radius: 0.125rem;
  border: solid 0.063rem #e4e4e4;
  border-top: none;
}

.budget-amount{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 5px;
  padding-left: 5px;
  display: none;
}

.sgigs-thecard .dividerGigCard {
  /* width: 100%; */
  /* height: 1px; */
  border-top: 1px solid #eee;
  min-height: fit-content;
  /* background-color: #eee; */
  /* margin: 1rem -0.625rem; */
}

.sgigs-thecard .sgigs-upAbove {
  padding: 0.9375rem 0.625rem 0.756rem;
  min-height: 10rem;
  /* cursor: pointer; */
}

.sgigs-theStatus {
  padding: 0.313rem 0.625rem;
  background-color: var(--statusColor-cream);
  width: fit-content;
  border-radius: 0.188rem;
  color: var(--statusColor-brown);
  font-size: 0.8rem;
  margin-right: 0.313rem;
}

.GigCard-CompanyName-FacilityName {
  justify-content: unset !important;
}

.GigCard-Rating-section-job {
  padding-left: .9375rem;
 
}

.GigCard-CompanyNameIT-FacilityNameHC {
  font-size: 14px;
  font-weight: normal;
  color: #4e4e4e;
}

.GigCard-CompanyName-FacilityName-wrap {
  word-wrap: normal;
}

.cand-sgigs-image {
  /* width: 389px; */
  /* width: 442px; */
  width: 100%;
  height: 15rem;
  border-radius: 0.125rem 0.125rem 0rem 0rem;
  /* margin: 0 0 15px; */
  /* margin: 0rem 0rem 0rem -0.8rem; */
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  /* right: 0; */
  object-fit: cover;
}

.sgigs-theTitle {
  width: 100%;
  /* height: 1.188rem; */
  /* margin: 0.938rem 8.75rem 0rem 0rem; */
  /* font-family: SFProText; */
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}
.sgigs-theFacility {
  /* font-size: 0.8rem;
  font-weight: 0;
  color: grey; */
  /* width: 18.75rem; */
  width: 100%;
  /* height: 1.063rem; */
  margin: 0.625rem 13.625rem 0rem 0rem;
  /* font-family: SFProText; */
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4e4e4e;
  /* margin: 0.5rem 0; */
}

.sgigs-information {
  display: flex;
  justify-content: space-between;
  margin: 0.75rem 0rem 0rem 0rem;
  align-items: flex-end;
  /* height: 1.1rem; */
}

.information-left-outer {
  display: flex;
  justify-content: space-between;
}

.sgigs-information .information-left {
  display: flex;
}

.sgigs-information .information-right {
  display: flex;
  flex-direction: row;
  /* width: 1.313rem; */
  margin-left: -2px;
  height: 1.063rem;
  margin-top: 1px;
  padding-bottom: 4px;
  /* line-height: normal; */
  font-size: 0.875rem;
  align-items: center;
}

.sgigs-information .information-right .sgigs-rating {
  color: #ffba08;
  margin: 0 0 0 0.5rem;
  line-height: normal;
}
.sgigs-information .information-right .review {
  color: #7e7e7e;
  margin: 0 0 0 5px;
  line-height: normal;
}
.sgigs-star {
  /* width: 0.875rem;
  height: 0.75rem; */
  width: 1rem;
  height: 1rem;
  flex-grow: 0;
  /* margin: 0.75rem 0.5rem 0 0; */
  margin-left:-5px;
  object-fit: contain;
  border-radius: 0.031rem;
  color: #ffba08;
}

.sgigs-thecard .ant-rate-star-second {
  color: #9e9e9e;
}

.sgigs-review {
  display: flex;
  /* width: 20rem; */
  font-size: 0.75rem;
  /* font-family: SFProText; */
  margin: -0.188rem 0rem 0rem 0rem;
  color: #7e7e7e;
  justify-content: space-between;
}
.sgigs-review .posted-on {
  font-size: 0.75rem;
  color: #7e7e7e;
}

.sgigs-Budgets {
  display: flex;
  /* justify-content: baseline; */
  width: 6.5rem;
  height: 1.5rem;
  flex-grow: 0;
  margin: 0 0 0rem;
  /* font-family: SFProText; */
  font-size: 1.25rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #118936;
}

.sgigs-gigcard-interestedBtn {
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding-right: 0.625rem;
  /* margin: -0.4rem 0 0rem; */
  /* height: 1.5rem; */
  /* width: 17.5rem; */
}
.sgigs-div1 {
  /* width: 4.25rem; */
  height: 0.875rem;
  flex-grow: 0;
  margin: 0rem 2.25rem 0 0;
  /* font-family: SFProText; */
  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
  padding-top: 0.188rem;
}
.sgigs-gigcard-interestedBtn .sgigs-div2 {
  width: 6rem;
  background-color: var(--primaryColor);
  font-size: 0.9rem;
  color: white;
  border-radius: 0.188rem;
  padding-top: 0.125rem;
}
.sgigs-div2 {
  cursor: pointer;
}

.sgigs-receiveTime {
  font-size: 0.65rem;
  font-style: italic;
  color: #777;
  padding: 0.5rem 1rem;
  width: max-content;
}
.span-icon {
  font-size: 0.95rem;
  /* padding-top: .06rem; */
}
.span-item {
  font-size: 0.9rem;
  width: 24rem;
  margin-right: 0.7rem;
}

.messageIcon {
  display: flex;
  align-items: center;
  margin-top: 0.7rem;
  /* justify-content: center; */
}

.sgigs-IT-Experience{
  font-size: 14px;
  color: #1e1e1e;
  font-weight: normal;
  display: flex;
  align-items: flex-end;
}
@media (max-width: 786px) {
  .sgigs-IT-Experience {
    font-size: 12px;
  }
}
@media screen and (max-width: 700px) {
  .black-header {
    background-color: black;
    width: fit-content;
  }
  .sgigs-thecard {
    width: -webkit-fill-available;
  }
  .filters-wrapper .location-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: center;
    width: max-content;
  }
  .filters-wrapper .location-button .ant-switch-checked {
    background-color: #118936;
    width: fit-content;
  }
  .location-text {
    /* height: 19px; */
    /* margin: 2px 0 2px 12px; */
    /* font-family: SFProText; */
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    /* line-height: normal; */
    letter-spacing: normal;
    text-align: left;
    color: #3e3e3e;
  }
  .sgigs-theTitle {
    width: 100%;
    /* height: 1.188rem; */
    /* margin: 0.938rem 8.75rem 0rem 0rem; */
    /* font-family: SFProText; */
    font-size: 0.875rem !important;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1e1e1e;
  }
  .sgigs-theFacility {
    /* width: 18.75rem; */
    width: 100%;
    height: 1.063rem;
    margin: 0.625rem 13.625rem 0rem 0rem;
    /* font-family: SFProText; */
    font-size: 0.75rem !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4e4e4e;
    /* margin: 0.5rem 0; */
  }
  .sgigs-div1 {
    width: 4.25rem;
    height: 0.875rem;
    flex-grow: 0;
    margin: 0rem 2.25rem 0 0;
    /* font-family: SFProText; */
    font-size: 0.625rem !important;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #7e7e7e;
  }
  .sgigs-Budgets {
    display: flex;
    width: 6.5rem;
    height: 1.5rem;
    flex-grow: 0;
    margin: 0 0 0rem;
    /* font-family: SFProText; */
    font-size: 1.125rem !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #118936;
  }
  .sgigs-information .information-right > div {
    margin: 0 0 0 5px;
  }
  .sgigs-upAbove .sgigs-information .information-right .review {
    color: #7e7e7e !important;
  }
  .sgigs-review {
    display: flex;
    /* width: 20rem; */
    /* font-size: 0.625rem !important; */
    /* font-family: SFProText; */
    margin: -0.188rem 0rem 0rem 0rem;
    color: #7e7e7e;
    justify-content: space-between;

  }
  .sgigs-star {
    width: 0.75rem !important;
    /* height: 0.75rem !important; */
    flex-grow: 0;
    /* margin: 0.75rem 0.5rem 0 0; */
    object-fit: contain;
    border-radius: 0.031rem;
    color: #ffba08;
    
  }
  .sgigs-information .information-right .sgigs-rating {
    color: #ffba08;
    margin: 2px 0 0 5px;
    font-size: 0.75rem;
  }
  .sgigs-information .information-right .review {
    color: #7e7e7e;
    margin: 0 0 0 5px;
    font-size: 0.75rem;
  }
  .sgigs-information .information-right {
    display: flex;
    flex-direction: row;
    width: 1.313rem;
    height: 1.063rem;
    /* line-height: normal; */
    font-size: 0.875rem;
    line-height: normal;
  }
  .sgigs-review .posted-on {
    font-size: 0.625rem;
  }
  .GigCard-CompanyNameIT-FacilityNameHC {
    width: 14.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;

  }
  .GigCard-Rating-section-job {
    padding-left: 0rem !important;
  }
}

@media screen and (max-width: 375px) {
  .GigCard-Rating-section-job {
    padding-left: 0rem;
  }
  .sgigs-information .information-right .review {
    font-size: 0.65rem;
  }
} 
