.card-parent-main {
  width: 58%;
}

.chat-card-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3.5%;
}
.main-class {
  padding: 4%;
}
.chat-card-btns .btns {
  background-color: #e7f3eb;
  color: #118936;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  width: 48%;
  border: none;
  border-radius: 4px;
  /* cursor: pointer; */
}
.btns-disable {
  width: 100%;
  height: 39px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  background-color: #f2f2f2;
  color: #9e9e9e;
  border: solid 1px #e4e4e4;
  border-radius: 5px;
}

.left .Admin-Talent-Chat-section {
  border-radius: .3125rem;
  border: .0625rem solid #E4E4E4;
  background: #FFF !important;
}

.right .Admin-Talent-Chat-section {
  border-radius: .3125rem;
}

.chat-card-btns .edit-btn {
  background-color: #e7f3eb;
  color: #118936;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  width: 100%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.chat-three-btns .btns {
  width: 100%;
  height: 39px;
  background-color: #118936;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
}

.onBilling-card-btns .edit-ratings {
  background-color: #118936;
  height: 40px;
  border-radius: 4px;
  color: #fff;
  align-items: center;
  border: none;
  width: 100%;
  margin-top: 2%;
  cursor: pointer;
}

.onBilling-card-btns .delete-ratings {
  background-color: #e7f3eb;
  color: #118936;
  width: 100%;
  margin-top: 2%;
  cursor: pointer;
  align-items: center;
  border: none;
  height: 40px;
}

.reject-modal-div {
  display: flex;
  width: 100%;
  border: solid 1px #d4d4d4;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-top: 3%;
  cursor: pointer;
}

.reject-modal-div:hover {
  background-color: #e4ffec;
}

.reject-modal-div:active {
  background-color: #e4ffec;
}

.rejectModalBtn {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  border-radius: 5px;
  width: 100%;
  border: none;
  background-color: #118936;
  margin-top: 3%;
  cursor: pointer;
}
.rejectModalBtn_disabled {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  border-radius: 5px;
  width: 100%;
  border: none;
  background-color: #118936;
  margin-top: 3%;
  cursor: no-drop;
}

.rejectModalBtndisabled {
  color: #9e9e9e;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  border-radius: 5px;
  width: 100%;
  border: none;
  background-color: #f2f2f2;
  margin-top: 3%;
}

.subject-font-chat {
  color: #1e1e1e;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media screen and (max-width: 769px) and (min-width: 500px) {
  .card-parent-main {
    width: 70vw;
  }
}

@media screen and (max-width: 499px) {
  .card-parent-main {
    width: 88%;
  }
}

/* <div style={{ width: "50%" }}>
              <Row className="flexSpace">
                <Rate
                  className="comp-rate-cp"
                  disabled
                  allowHalf
                  defaultValue={
                    data?.SourceTypeId == 1
                      ? data?.SenderUser?.Profiles?.Rating
                      : data?.RecipientUser?.Profiles?.Rating ?? 3.5
                  }
                ></Rate>
                <div
                  className="ratingText"
                  style={{ color: status == "Retracted Bid" && "#9e9e9e" }}
                >
                  {data?.SourceTypeId == 1
                    ? data?.SenderUser?.Profiles?.Rating
                    : data?.RecipientUser?.Profiles?.Rating ?? "3.5"}
                </div>
                <div
                  className="rating-COUNT"
                  style={{ color: status == "Retracted Bid" && "#9e9e9e" }}
                >
                  {data?.SourceTypeId == 1
                    ? data?.SenderUser?.Profiles?.Review !== 0
                      ? data?.SenderUser?.Profiles?.Review
                      : ""
                    : data?.RecipientUser?.Profiles?.Review !== 0
                    ? data?.RecipientUser?.Profiles?.Review
                    : ""}
                </div>
              </Row>
            </div> */
