.skillListingDetailPage {
  background-color: #f8f8f8;
  padding: 2.5rem;
  display: flex;
  gap: 1.25rem;
}

.skillListingDetailPage .first-section-sl-detail-page {
  max-width: 21.25rem;
  gap: 1.25rem;
  display: flex;
  flex-direction: column;
}

.skillListingDetailPage .left-col-card {
  padding: 0.94rem;
}

.skillListingDetailPage .second-section-sl-detail-page {
  width: 47.5rem;
}

.skillListingDetailPage .title-edit {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.career-div{
  display: flex;
  justify-content: space-between;
}
/* .edit-career-btn{
  font-size: 12px;
  font-weight: 500;
  color: #4054B2;
} */

.skillListingDetailPage .midSection-title {
  font-size: 1.25rem;
  font-weight: 500;
  text-align: left;
  color: #1e1e1e;
  margin-bottom: 1.25rem;
  max-width: 100%;
  text-wrap: wrap;
  word-break: break-word;
  line-height: 24px !important;
}

.skillListingDetailPage .midSection-edit {
  font-weight: 500;
  color: var(--primaryColor-blue);
}
.midSection-edit{
  cursor: pointer;
}

/* Work History Skill Listing */
.workHistorySkillListing {
  padding: 1.25rem 1.25rem 1.25rem;
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
}

.workHistorySkillListing .workHistory .titleWorkHistory {
  align-items: center;
}

.workHistorySkillListing .dividerSkillListing {
  width: 100%;
  height: 0.06rem;
  background-color: #e4e4e4;
  margin: 14px 0 2.188rem;
}

.workHistorySkillListing .titleWorkHistory .title {
  margin-bottom: unset;
}

.workHistorySkillListing .workHistory .textArea-workHistory {
  height: 9.38rem !important;
}

.workHistorySkillListing .add-edit-box {
  width: 100% !important;
  background-color: #fff;
  padding: 0;
  margin-top: 1.25rem;
}

.workHistorySkillListing .workHistory .inputWorkHistory {
  width: 100%;
}

.workHistorySkillListing .workHistory .twoItemInARow .ant-form-item {
  width: 100%;
}

.workHistorySkillListing
  .workHistory
  .twoItemInARow
  .ant-form-item-control-input-content {
  gap: 1.25rem;
}

.workHistorySkillListing .workHistory .twoItemInARow .ant-form-item {
  margin-bottom: 0.938rem;
}

.workHistorySkillListing .workHistory .checkbox-WorkHistory {
  margin-bottom: 0.938rem;
}

.workHistorySkillListing .workHistory .roleWorkHistory {
  margin-bottom: 0.938rem;
}

.workHistorySkillListing .workHistory .selectWorkHistory .ant-select-selector {
  width: 100%;
}

.workHistorySkillListing .workHistory .datepickerWorkHistory {
  width: 100%;
}

.workHistorySkillListing .itemWorkHistory .itemWorkHistory-combo {
  width: 97%;
}

.workHistorySkillListing .itemWorkHistory-combo .ant-form-item {
  width: 100%;
}

.workHistorySkillListing
  .workHistory
  .skillSelectWorkHistory
  .ant-select-selector {
  width: 100%;
}

.workHistorySkillListing .itemWorkHistory .inputPercentWorkHistory {
  width: 100%;
}

.workHistorySkillListing .add-edit-box .cancel-add-div {
  justify-content: flex-end;
  gap: 1.25rem;
  padding-top: 6px;
  border-top: unset;
}

.workHistorySkillListing .titleWorkHistory .title {
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.workHistorySkillListing .add-title {
  color: var(--primaryColor-blue);
  font-size: 0.88rem;
  cursor: pointer;
}

.skillListingDetailPage .UserDocuments {
  width: 100% !important;
}

/* Education History */

.educationHistorySkillListing {
  padding: 15px 10px 15px 10px;;
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
}

.educationHistorySkillListing .ant-form-item {
  margin-bottom: 0.938rem;
  max-width: 21.88rem;
}

.educationHistorySkillListing .add-edit-box {
  background-color: #fff;
  padding: 0;
  width: 100%;
}

.educationHistorySkillListing .add-edit-box .cancel-add-div {
  border-top: unset;
  justify-content: flex-end;
  gap: 1.25rem;
  padding-top: 30px;
}

.educationHistorySkillListing .add-edit-box .ant-form {
  display: flex;
  flex-wrap:nowrap; 
  gap: 1.25rem;
  justify-content: space-between;
  max-height: 95px;
}

.educationHistorySkillListing
  .education-history
  .selectEducationHistory
  .ant-select-selector {
  width: 21.8rem;
  /* width: 100%; */
}

.educationHistorySkillListing .title-education-history .title {
  font-size: 1rem;
  font-weight: 600;
  color: #1e1e1e;
}

.educationHistorySkillListing .education-history .title {
  margin-bottom: 1.25rem;
}

.educationHistorySkillListing .add-title {
  color: var(--primaryColor-blue);
  font-size: 0.88rem;
  cursor: pointer;
}

.educationHistorySkillListing .divider-skill {
  height: 0.06rem;
  flex-grow: 0;
  background-color: #e4e4e4;
  margin-top: 10px;
}

.educationHistorySkillListing .educationHistory-data .courseType-Name {
  font-size: 0.88rem;
  font-weight: 600;
  color: #1e1e1e;
  /* width: 27rem; */
}

/* Talent Profile */

.skillListingDetailPage .icon-skill {
  margin-left: 3px;
}
/* 
.skillListingDetailPage .bt-3d-resume {
  width: 100%;
} */

.skillListingDetailPage .updateProfile {
  font-size: 0.88rem;
  font-weight: 600;
  width: 100%;
  text-align: right;
  color: var(--primaryColor-blue);
}

.skillListingDetailPage .facebook-linkedin {
  height: 1.38rem;
  width: 3.5rem;
  display: flex;
  /* justify-content: space-between; */
  gap: .9688rem;
  
}

/* 3D resume box */

.skillListingDetailPage .resumebox-skill {
  width: 100%;
  padding: 0.94rem;
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
}

.skillListingDetailPage .resumebox-skill .title {
  font-size: 1rem;
  color: #1e1e1e;
}

.skillListingDetailPage .updateButton3D {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 2.63rem;
  border-radius: 0.31rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
  background-color: var(--dark-sea-green);

  /* font */
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: var(--primaryColor);
}

.skillListingDetailPage .progressbar3d {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1.875rem 0;
}

.skillListingDetailPage .ant-progress-circle .ant-progress-text {
  border-radius: 4rem;

  width: 3.38rem;
  height: 3.38rem;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.8rem;

  color: #fff;
  background-color: #118936;
}

.skillListingDetailPage .dropdowntab-skill {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 2.63rem;
  padding: 0.56rem 0.63rem 0.56rem;
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;

  font-size: 1rem;
  font-weight: 500;
  color: #1e1e1e;
}

.section-dropdown-skill {
  display: flex;
  flex-direction: column;
  padding: 0.938rem;
  gap: 0.938rem;
}

.skillListingDetailPage .reliasTestingSkill {
  width: 100%;
  height: 2.63rem;
  flex-grow: 0;
  /* padding: 0.75rem 7.19rem 0.69rem 7.19rem; */
  border-radius: 0.31rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
  background-color: #000000;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1rem;
  font-weight: 600;
  color: #fff;
}

.skillListingDetailPage .third-section-sl-detail-page {
  width: 21.25rem;
}

.skillListingDetailPage-pdf {
  max-width: 43.75rem;
  max-height: 37.25rem;
}

.skillListingDetailPage-pdf .pdfViewerCredentialing {
  background-color: #e4e4e4;
  /* overflow: hidden; */
}

.skillListingDetailPage-pdf .docNameSizeClose .name {
  color: #1e1e1e;
}

/* certifications and Awards */
.skillListingDetailPage .certsAndAwardsSkillListing {
  width: 100%;
  /* height: 18.69rem; */
  padding: 1.25rem;
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
}

.certsAndAwardsSkillListing .add-edit-box {
  background-color: #fff;
  padding: 0;
  width: 100%;
}

.certsAndAwardsSkillListing .certificationsAndAwards .textAreaCertificates {
  height: 9.38rem;
}

.certificationsAndAwards .add-edit-box .cancel-add-div {
  justify-content: flex-end;
  gap: 1.25rem;
  padding-top: 6px;
  border-top: unset;
}

.certificationsAndAwards .add-title {
  color: var(--primaryColor-blue);
  font-size: 0.88rem;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .skillListingDetailPage {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 0;
  }

  .skillListingDetailPage .second-section-sl-detail-page {
    width: 100%;
  }

  .skillListingDetailPage .first-section-sl-detail-page {
    width: 100%;
    max-width: unset;
  }

  .skillListingDetailPage .second-section-sl-detail-page {
    padding: 0 0.938rem;
  }

  .noViewHistorySkill {
    height: 18.06rem !important;
    gap: 2.5rem;
  }

  .noViewHistorySkill .emptyBox-img {
    margin-bottom: 0 !important;
  }

  .skillListingDetailPage .third-section-sl-detail-page {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.938rem;
  }

  .workHistorySkillListing .workHistory .inputWorkHistory {
    width: 8.375rem;
  }

  .workHistorySkillListing .workHistory .twoItemInARow .ant-form-item {
    width: unset;
  }
  
  .workHistorySkillListing
    .workHistory
    .selectWorkHistory
    .ant-select-selector {
    width: 8.375rem;
  }

  .workHistorySkillListing .workHistory .datepickerWorkHistory {
    width: 8.375rem;
  }

  .workHistorySkillListing
    .workHistory
    .skillSelectWorkHistory
    .ant-select-selector {
    width: 9rem;
  }

  .workHistorySkillListing .itemWorkHistory .inputPercentWorkHistory {
    width: 4.8125rem;
  }

  .workHistorySkillListing .workHistory .ant-picker-input > input {
    font-size: 0.875rem;
  }

  .workHistorySkillListing .workHistory .itemWorkHistory .ant-form-item-control-input-content {
    justify-content: flex-end;
  }

  .workHistorySkillListing .add-edit-box .cancel-add-div {
    justify-content: space-between;
  }

  .educationHistorySkillListing .educationHistory-data .courseType-Name {
    /* width: none;  */
  }

  .educationHistorySkillListing .add-edit-box .ant-form {
    
    flex-wrap: wrap; 
    max-height: -webkit-fill-available;
  }

  .educationHistorySkillListing
  .education-history
  .selectEducationHistory
  .ant-select-selector {
  max-width: 20rem;
  /* width: 100%; */
}
  .DropDown-select-Bid-history {
    background: #e4e4e4;
  }

  .summary-main-div{
    border: solid 0.06rem #e4e4e4;
    margin-bottom: 10px;
    background-color: white;
    padding: 10px;
  }
}
