.error-container {
  width: 100%;
  display: grid;
  /* place-items: center; */
  padding: 1% 1rem;
}

.bidStatus-container {
  display: grid;
  /* place-items: center; */
}

.bidStatus-container .signButton {
  outline: none;
  border: none;
  padding: 0.5rem 0.625rem;
  border-radius: 0.3125rem;
  cursor: pointer;
  background-color: #bec1c4;
  color: #ffffff;
}

.errorModel {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 0rem;
}

.errorModel .errorModelButton {
  cursor: pointer;
  border-radius: 0.25rem;
  border: solid 0.063rem #118936;
  font-size: 0.875rem;
  color: #118936;
  padding: 0.375rem 0.938rem 0.375rem 0.928rem;
  font-weight: 600;
}

.errorModel .errorModelGoBackBtn {
  cursor: pointer;
  position: absolute;
  left: 0.9375rem;
  top: 0.9375rem;
}

.errorModel .errorModelGoHomeBtn {
  cursor: pointer;
  position: absolute;
  right: 0.9375rem;
  top: 0.9375rem;
}
.confirmation-text-container{
  justify-content: center;
  text-align: center;
  margin-top: 1rem;
}

.errorModel .errorModelGoBackBtn:hover,
.errorModel .errorModelGoHomeBtn:hover {
  color: #118936;
}
