.TextInputParentContainerSp {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  .Multi-ant-form {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    border-bottom: 1px solid #BDBDBD;
    /* height: 110px; */
  }
  .Multi-ant-form-error {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    border-bottom: 1px solid #dc2d2d;
  }
  .TextInputTitleContainer {
    display: flex;
  }
  .TextInputTitle-mutli {
    color: #212121;
    font-weight: bolder;
    font-size: 1.5rem;
    margin-right: 1.25rem;
    font-weight: 800;
  }
  .TextInputType-multi {
    color: #7E7E7E;
    /* margin-left: 1.25rem; */
    margin-top: 5px;
    font-size: 0.625rem;
    font-weight: 400;
  }
  .textInputContainer {
    width: 50%;
  }
  .multiselect-selections{
    margin-bottom: 5px;
  }
  .multi-select-option{
    margin-bottom: 5px;
  }
  .placeholderSkillListingFormTest {
    background-color:#F9F9F9;
    border: 1px solid #D9D9D9;
    color: #7E7E7E;
    padding: 8px 20px;
    margin: 0 5px;
    border-radius: 40px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
  }
  .MultiInputStyle {
    /* border: none;
    border-bottom: 1px solid;
    border-color: #ccc;
    border-radius: 0; */
    margin-top: 2rem;
    padding-left: 0;
    margin-bottom: 12px !important;
  }
  .MultiInputStyleerror{
    border: none;
    border-radius: 0;
    margin-top: 2rem;
    padding-left: 0;
    margin-bottom: 12px !important;
  }
  .MultiInputStyleerror:focus {
    box-shadow: none !important; /* Remove focus box shadow */
    border-color: #ccc !important; /* Set the border color on focus */
  }
  .MultiInputStyleerror .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important; /* Remove focus box shadow */
    /* border-bottom: 1px solid #DB2828 !important; */
    border-bottom: none !important;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
  }
  .MultiInputStyleerror .ant-select-multiple .ant-select-selection-placeholder{
    left : 0 !important;
  }
  .MultiInputStyleerror .ant-select-multiple .ant-select-selection-search{
    -webkit-margin-start: 0 !important;
  margin-inline-start: 0 !important;
  }    

  .MultiInputStyleerror .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 !important;
  }
  .MultiInputStyle:focus {
    box-shadow: none !important; /* Remove focus box shadow */
    border-color: #ccc !important; /* Set the border color on focus */
  }
  .skillListingOne .dropCss {
    max-width: 43.75rem !important;
    min-height: 2.75rem;
    flex-grow: 0;
    margin: 0.5rem 0 0 !important;
    padding: 0.25rem 0.125rem;
    border-radius: 0.25rem;
    border: solid 0.06rem #d4d4d4;
    background-color: #fff;
    overflow: hidden;
  }
  .MultiInputStyle .ant-select-multiple .ant-select-selection-placeholder{
    left : 0 !important;
    font-size: larger;
    /* border-bottom: 1px solid #BDBDBD;
    padding-bottom: 15px; */
  }
  .MultiInputStyle .ant-select-multiple .ant-select-selection-overflow{
    position: relative;
    display: flex;
    flex: auto;
    flex-wrap: wrap;
    max-width: 100%;
    font-size: 17px;
    max-height: 110px !important;
    overflow: auto;
  }
  .MultiInputStyle .ant-select-multiple .ant-select-selection-search{
    -webkit-margin-start: 0 !important;
  margin-inline-start: 0 !important;
  }    

  .MultiInputStyle .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 !important;
}

  .MultiInputStyle .ant-select-selection-item {
    font-size: 1.25rem;
    margin-top: 2px;
    border-radius: 50px;
    /* margin-bottom: 0.7rem; */
    margin-right: 0.5rem;
    height: 2.3rem;
    display: flex;
    align-items: center;
    padding: 0 0.6rem;
    border: 1px solid #D9D9D9;
  }
  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector{
    /* border-bottom: 1px solid #DB2828 !important; */
    padding-left: 0;
    border: none !important;
  }
  .ant-select-selection-item-remove{
    font-size: 13px !important;
    margin-left: 0.4rem;
  }
  .errorIcon{
    color:#dc2d2d;
    margin-right: 5px;
  }
  .errorMsg{
    color:#DB2828;
    margin-top: 0.8rem;
  }
  @media screen and (max-width: 768px) {
    .textInputContainer {
      width: 90%;
    }
    .TextInputTitle-mutli {
      color: #3e3e3e;
      font-weight: bolder;
      font-size: 1.1rem;
    }
    .MultiInputStyle {
      border: none;
      /* border-bottom: 1px solid;
      border-color: #ccc; */
      border-radius: 0;
      margin-top: 2rem;
      margin-bottom: 7px !important;
    }
    .MultiInputStyleerror{
      border: none;
      border-radius: 0;
      margin-top: 2rem;
      padding-left: 0;
      margin-bottom: 7px !important;
    }
    .placeholderSkillListingFormTest {
      background-color:#F9F9F9;
      border: 1px solid #D9D9D9;
      color: #7E7E7E;
      padding: 2px 14px;
      margin: 0 5px;
      border-radius: 40px;
      font-size: 12px;
      line-height: 28px;
      font-weight: 400;
    }
    
    .MultiInputStyle .ant-select-selection-item {
      font-size: .75rem;
    }
  }
  