.skillgigsHome {
  padding: 2.5rem;
  background-color: #fff;
  padding-bottom: 0rem;
}

.skillgigsHome .completeCredentialingHome {
  width: 100%;
  height: 4.44rem;
  flex-grow: 0;
  /* margin: 2.5rem 2.5rem 1.88rem; */
  padding: 0.94rem 1.25rem;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
  background-color: #f4f4f4;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.completeCredentialingHome .iconAndCount {
  display: flex;
  gap: 1.25rem;
}

.completeCredentialingHome .countCred {
  display: flex;
  flex-direction: column;
}

.countCred .cred {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  display: flex;
  align-items: center;
}

.countCred .count {
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9e9e9e;
}

.completeCredentialingHome .completeCred {
  width: 5.31rem;
  height: 1.56rem;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.63rem;
  /* margin: 0.5rem 0 0.5rem 52.44rem; */
  padding: 0.19rem 0.5rem;
  border-radius: 0.13rem;
  background-color: var(--primaryColor);

  /* font */
  font-family: Roboto;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  cursor: pointer;
}

.HomePage-main-cantainer {
  padding: 2.5rem 2.5rem 1.875rem 2.5rem;
  background-color: #fff;
}

.HomePage-grid-section {
  display: flex;
}

.HomePage-Candidate-name {
  background-color: #f4f4f4;
  min-width: 17.813rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* padding: 2rem; */
  border-radius: 0.25rem;
}

.HomePage-Candidate-name .ant-carousel .slick-slider .slick-list {
  border-radius: 0.25rem;
}
.HomePage-Blank-Space {
  max-width: 1.875rem !important;
}

.HomePage-Caraousel {
  /* max-width: 75% !important; */
  width: 75% !important;
  margin-left: 1.875rem;
  border-radius: 0.25rem;
}

.HomePage-Candidate-span {
  font-weight: 500;
  padding-bottom: 0.625rem;
  font-size: 1rem;
}

.HomePage-Candidate-name span {
  font-size: 1rem;
  color: #000000;
}

.HomePage-add-skill-button {
  border: 0.063rem solid #118936;
  padding: 0.25rem 3rem;
  border-radius: 0.25rem;
  background-color: #f4f4f4;
  margin-top: 1.875rem;
}
.HomePage-add-skill-button:hover div {
  background-color: #118936;
  color: #fff;
}
.HomePage-add-skill-button:hover {
  background-color: #118936;
  color: #fff;
}
.HomePage-Create-a-Skill-Listing3 {
  color: #118936;
  cursor: pointer;
}

.HomePage-MostPopularJobs-Cantainer {
  display: flex;
  padding-top: 1.875rem;
  padding-bottom: 1.3rem;
  justify-content: space-between;
  align-items: baseline;
}

.HomePage-MostPopularJobs-text {
  color: #3e3e3e;
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
}

.HomePage-SeeAllJobs {
  font-size: 1rem;
  font-weight: 500;
  color: #4054b2;
  cursor: pointer;
}

.HomePage-MostPopularJobs-text span {
  color: #4054b2;
}

.HomePage-main-cantainer .ant-carousel .slick-dots li button {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  text-align: "flex-start";
}

.HomePage-main-cantainer .ant-carousel .slick-dots {
  margin-top: 1.15% !important;
  justify-content: flex-start !important;
  margin-right: 0rem !important;
  margin-left: 2.89% !important;
  right: 0 !important;
  left: unset !important;
}

.HomePage-carousel-inbox-main-second {
  padding: 0.9375rem 1.25rem 3.688rem !important;
  margin-bottom: 3.75rem;
  font-size: 1.125rem;
}

.HomePage-carousel-inbox-main-first {
  padding: 1.688rem 1.25rem 0px !important;
  font-size: 2rem;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  color: #fff;
}

.HomePage-Caraousel .ant-carousel .slick-dots li.slick-active {
  width: 0rem !important;
}

.HomePage-Caraousel .ant-carousel .slick-dots li {
  width: 0rem !important;
  height: 0rem !important;
  margin-right: 1.125rem !important;
  margin-left: 0rem !important;
}

.HomePage-JobsMayLike-Card {
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  list-style: none;
  grid-gap: 0.938 rem !important;
  width: 100%;
  flex: 0 0 33.333333%;
  font-size: 1.5em;
  margin: 0 rem 0 0 0;
  /* gap: 0.938 rem; */
}

@media screen and (min-width: 768px) and (max-width: 2000px) {
  .HomePage-Carousel-section-Mobile {
    display: none;
  }

  .skillgigsHome-Pvt-section .HomePage-MostPopularJobs-text-Mobile {
    display: none !important;
  }

  .HomePage-SeeAll-Mobile {
    display: none;
  }

  .HomePage-SeeAllJobs-Mobile {
    display: none;
  }

  .HomePage-Divider {
    display: none;
  }

  .HomePage-SeeAll-page {
    padding: 2.5rem;
  }

  .HomePage-Heading {
    font-size: 1.25rem;
    font-weight: 600;
    color: #3e3e3e;
    padding: 1.25rem 0 1.25rem 0;
  }

  .HomePage-Sub-Heading {
    font-family: SFProText;
    font-size: 1rem;
    color: #7e7e7e;
    padding-bottom: 1.25rem;
  }

  .HomePage-Breadcrumb-home {
    cursor: pointer;
  }

  .HomePage-SeeAll-page .ant-breadcrumb {
    font-size: 1rem;
    font-weight: 500;
    color: #4054b2;
  }

  .HomePage-SeeAll-page .ant-breadcrumb-separator {
    color: #3e3e3e;
  }

  .HomePage-SeeAll-page .ant-breadcrumb li:nth-child(2) {
    font-size: 1rem;
    font-weight: 500;
    color: #7e7e7e;
  }

  .HomePage-arrow-sign {
    font-size: 1rem;
    padding-left: 00.938rem;
  }

  .HomePage-arrow-sign .anticon {
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 720px) {
  .skillgigsHome {
    background-color: #fff;
    font-size: 0.875rem;
    color: #1e1e1e;
    font-weight: normal;
    padding: 0.9375rem 0.6875rem 0.9375rem 0.6875rem;
  }

  .skillgigsHome .completeCredentialingHome {
    padding: 0.625rem 0.625rem;
  }

  .HomePage-main-cantainer {
    background-color: #fff;
    padding: unset;
  }

  .HomePage-Candidate-name {
    display: none;
  }

  .HomePage-Caraousel {
    /* max-width: 100% !important; */
    width: 100% !important;
    margin-left: 0rem;
  }

  .skillgigsHome-Pvt-section .HomePage-MostPopularJobs-text {
    display: none !important;
  }

  .HomePage-SeeAllJobs {
    display: none;
  }

  .HomePage-SeeAllJobs-Mobile {
    font-size: 1rem;
    font-weight: 500;
    color: #4054b2;
    cursor: pointer;
  }

  .HomePage-MostPopularJobs-text-Mobile {
    font-size: 1rem;
    font-weight: 600;
    color: #3e3e3e;
    display: flex;
    align-items: center;
  }

  .Homepage-editer-mobile {
    color: #3e3e3e !important;
  }

  .HomePage-MostPopularJobs-Cantainer {
    padding-top: 0rem;
  }

  .HomePage-EditorPicks {
    padding: 0rem 0.938rem 0.938rem 0.938rem;
  }

  .HomePage-JobsMayLike {
    padding: 0rem 0.938rem 0rem 0.938rem;
  }

  .HomePage-main-cantainer .ant-divider-horizontal {
    margin: 1.3rem 0rem;
    background-color: #e4e4e4;
  }

  .HomePage-SeeAll-Mobile-text {
    color: #4054b2;
    font-weight: 600;
    font-size: 0.875rem;
    cursor: pointer;
  }

  .HomePage-SeeAll-Mobile {
    color: #4054b2;
    font-weight: 600;
    font-size: 0.875rem;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
  }

  .HomePage-Carousel-section {
    display: none;
  }

  .HomePage-MostPopularJobs-MainCantainer {
    padding: 1.3rem 0.938rem 0rem 0.938rem;
  }

  .HomePage-MostPopularJobs-text-Mobile span {
    color: #4054b2;
  }

  .HomePage-Carousel-section-Mobile {
    display: flex;
    /* justify-content: space-between; */
    margin: 0 auto;
    flex-wrap: wrap;
    list-style: none;
    width: 100%;
    flex: 0 0 33.333333%;
    font-size: 1.5em;
    margin: 0 rem 0 0 0;
    grid: 0.938 rem;
  }

  .HomePage-SeeAll-page {
    padding: 1.25rem 0.938rem 1.875rem 0.938rem;
    background-color: #fff;
  }

  .HomePage-Heading {
    font-size: 1rem;
    font-weight: 600;
    color: #1e1e1e;
    padding: 1.25rem 0 0.625rem 0;
  }

  .HomePage-Sub-Heading {
    font-family: SFProText;
    font-size: 1rem;
    color: #7e7e7e;
    padding-bottom: 1.25rem;
  }

  .HomePage-SeeAll-page .ant-breadcrumb {
    font-size: 1rem;
    font-weight: 500;
    color: #4054b2;
  }

  .HomePage-SeeAll-page .ant-breadcrumb li:nth-child(2) {
    font-size: 1rem;
    font-weight: 500;
    color: #7e7e7e;
  }

  .HomePage-main-cantainer .ant-carousel .slick-dots-bottom {
    top: 0.75rem;
  }

  .carousel-inbox-main-first {
    padding: 4.688rem 1.25rem 0px;
    font-size: 1.75rem;
    font-weight: 900;

    line-height: normal;

    text-align: left;
    color: #fff;
  }
}
.sign-up-emp-disclaimer {
  display: flex !important;
  min-width: 200px !important;
  width: 500px !important;
  padding: 6px !important;
  background-color: #fff0a3;
  border-radius: 4px;
}

.rejoin-alert {
  background-color: #fff0a3;
  max-width: 100% !important;
}
.ant-notification-top {
  width: 100% !important;
  max-width: 100% !important;
}
.ant-notification-notice-message {
  display: flex;
  align-items: center;
  align-content: center;
  /* justify-content: center; */
}

.sign-up-emp-disclaimer a {
  top: auto !important;
  color: #a49549 !important;
  padding-top: 3px;
  margin-left: 3px;
}
.sign-up-emp-disclaimer-mob {
  display: flex !important;
  min-width: 200px !important;
  width: 500px !important;
  padding: 6px !important;
  background-color: #fff0a3;
  border-radius: 4px;
}
.sign-up-emp-disclaimer-mob a {
  top: auto !important;
  color: #a49549 !important;
  padding: 15px 0px 0px 0px;
}
