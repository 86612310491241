.Drawer-filter-pvt-mkt {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.Drawer-filter-pvt-mkt> :nth-child(1) {
    font-size: .875rem;
    font-weight: normal;
    color: #9e9e9e;
}

.Drawer-filter-pvt-mkt> :nth-child(2) {
    font-size: 1rem;
    font-weight: 500;
    color: #3e3e3e;
}

.Radio-grop-pvt-mkt .ant-space-vertical {
    width: 100%;
    gap: .625rem;
}

.Radio-grop-pvt-mkt .ant-space-item {
    padding: 13px 12px;
    border-radius: 4px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #d4d4d4;
    background-color: #fff;
    font-size: 14px;
    color: #3e3e3e;
}

.Mobile-filter-title-pvt {
    font-size: 14px;
    font-weight: 500;
    color: #1e1e1e;
    padding-bottom: 10px;
}

.Radio-grop-pvt-mkt .ant-radio-wrapper {
    width: 100%;
}

.Radio-grop-pvt-mkt .ant-radio-group {
    width: 100%;
}

.Space-radio-pvt-mkt label > :nth-child(2) {
    display: flex;
    justify-content: space-between;
    color: #5e5e5e;
}

.radio-pvt-tag {
    display: flex;
    align-items: baseline;
}

.radio-pvt-tag .PrivateGigsTag-Cantainer {
    margin-left: .5rem;
}

.footer-primary-pvt {
    width: 100%;
    height: 42px;
    padding: 12px 114px 12px 115px;
    border-radius: 5px;
    background-color: #118936;
}
.apply-text{
    font-size: 16px;
    font-weight: 600;
    line-height: 1.13;
    text-align: center;
    color: #fff;
}
/* MOBILE FILTER */
.search-mobile-filter {
    display: flex;
    justify-content: space-between;
    grid-gap: 10px;
    gap: 10px;
    padding-bottom: 20px;
}
.search-mobile-filter .left {
    display: flex;
    width: -webkit-fill-available;
    height: 42px;
    border-radius: 5px;
    border: solid 1px #d4d4d4;
    background-color: #fff;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.search-mobile-filter .left .search-filter-container {
    display: flex;
    justify-content: space-between;
    grid-gap: 0.625rem;
    gap: 0.625rem;
}
.filter-button-icon{
    padding: 0px 2px 2px 0px;
}
.input{
    border: solid 1px #d4d4d4;
    border-radius: 4px;
    padding: 6px;
}