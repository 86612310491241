.CreateBidButtonIT-button .IT-bottom-details {
    justify-content: unset;
    margin: 1.875rem 0 0 0;
}

.CreateBidButtonIT-button .IT-bid-details-apply {
    width: 100%;
    display: flex;
    justify-content: center;
}
/* .bid-details-apply{
    border: 1px solid #118936;
    color: white
} */
.is-expired-gigs {
    background-color: #7e7e7e;
    cursor: not-allowed;
    border: 1px solid #7e7e7e
  }
  .place-Bid-btn-disabled {
    height: 42px !important;
    border-radius: 5px !important;
    border: solid 1px #c4c4c4 !important;
    background-color: #fff;
    cursor: not-allowed;
    line-height: 1.13;
  }
.text-color-disable{
    font-weight: 600;
    color: #D4D4D4;
    font-size: 16px;
}
.text-color-enable{
    font-weight: 600;
    font-size: 16px;
    color:white
}