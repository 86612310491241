.desired-Skill {
width: 100%;
padding-left: 0;
border-bottom: 1px solid #BDBDBD;
margin-top: 25px;
font-size: 1.2rem;
}
.desired-Skill .ant-select-selector {
height: auto; 
font-size: 1.5rem; 
width: 100%;
}

.desired-Skill .ant-select-selection-placeholder {
font-size: 1.2rem; 
color: #777777;
width: 100%;
}

.desired-Skill .ant-select-selection-item {
font-size: 1rem; 
height: 40px; 
width: 100%;
}

/* Remove border and outline for the Select component */
.ant-select-focused .ant-select-selector,
.ant-select-selector {
  border: none !important; /* Remove border */
  box-shadow: none !important; /* Remove any shadow effect */
}

/* Optionally, you can remove the hover effect as well */
.ant-select-selector:hover {
  border: none !important; /* Remove border on hover */
}
/* skill.css */

/* Other existing styles... */

/* Remove border and outline for the Select component */
.ant-select-focused .ant-select-selector,
.ant-select-selector {
  border: none !important; /* Remove border */
  box-shadow: none !important; /* Remove any shadow effect */
}

/* Optionally, you can remove the hover effect as well */
.ant-select-selector:hover {
  border: none !important; /* Remove border on hover */
}

.skills-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}
.desiredoc-skill {
  width: 100%;
  padding: 1%;
  padding-left: 0;
  border-bottom: 1px solid #BDBDBD;
  margin-top: 25px;
}
.skill-tag {
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 10px 15px;
  font-size: 14px;
  color: #666;
  border: 1px solid #ddd;
}



.desired-Skill {
  width: 100%;
  padding: 1%;
  padding-left: 0;
  border-bottom: 1px solid #BDBDBD;
  margin-top: 25px;
}

.desired-Skill.error-block {
  border-bottom: 1px solid #DB2828;
}

.desired-Skill .ant-select-selection-placeholder {
  font-size: 22px; 
  color: #777777;
  font-weight: 400;
  left: 0px !important;
  right: 0px !important;
}


.placeholder-new-signup-loc-skill {
  display: flex;
  flex-wrap: wrap;
}
.placeholder-new-signup-loc-skill .example {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -4px;
  font-size: 1.35rem;
}
.placeholder-new-signup-loc-skill .circle-box {
  margin-left: 10px;
  border-radius: 1.75rem;
  border: 1px solid #D9D9D9;
  background-color: #F9F9F9;;
  font-size: 14px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 12px 3px 12px;
}

.placeholder-new-signup-loc-skill .circle-box span{
  font-size: 1rem;
  color: #7E7E7E;
}
.skill-mobile{
  padding-left: 0px;
  padding-right: 0px;
}
.skill-block {
  border-bottom: 1px solid #DB2828 !important;
  width: 100%;
  padding: 1%;
  padding-left: 0;
  margin-top: 25px;
  font-size: 1.2rem;
}
@media screen and (max-width: 768px) {
  .ant-select-selection-overflow{
    height:80px 
  }
  .skill-mobile{
    padding-left: 15px;

   
  }
  .skill-block {
    border-bottom: 1px solid #DB2828 !important;
    width: 100%;
    padding: 1%;
    padding-left: 0;
    margin-top: 0px !important;
    font-size: 1.2rem;
  }
  .desiredoc-skill {
    width: 95% !important;
    padding: 7% !important;
    
  }
  .desired-Skill {
    width: 95%;
    padding: 4% 0px 6% 0px !important;
    border-bottom: 1px solid #BDBDBD;
    margin-top: 6px;
    font-size: 1.2rem;
  }
  
  .Mobile-class{
position: relative;
top: 9px;
left: 22px;
  }
  .input-screens {
    display: block;
    border: none;
    margin: 30px 0 0 0;
    font-size: 16px;
    padding-bottom: 12px;
    border-bottom: 1px solid #BDBDBD;
    max-width: 100%;
  }
  .input-screens-currency {
    border: none;
    margin: 50px 0 25px 0;
    font-size: 14px;
    width: 65px;
    padding-bottom: 18px;
    background-color: white;
    border-bottom: 2px solid #BDBDBD;
  }
  .currency-container .usd-container span {
    font-weight: 600;
    font-size: 1rem;
    color: #2E2E2E;
  }
  .currency-container .dollar-sign {
    font-size: 1rem;
  }
  .mobileview-currency-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .btns-container {
    display: flex;
    flex-direction: row;
    margin: 0px 0px 25px 0px;
  }
  .year-weekly-container {
    justify-content: space-between;
    margin-top: 20px;
  }
  .week-btn {
    display: flex;
    justify-content: center;
    padding: 8px 23px;
    border: 1px solid #9D9D9D;
    cursor: pointer;
    width: 45%;
  }
  .input-screenscurrency {
    border: none;
    font-size: 1rem;
    font-weight: 600;
    width: 80%;
    color: #777;
  }
  
  .down-icon {
    color: #7E7E7E;
  }
  .input-screens-currency {
    border: none;
    margin: 50px 0 25px 0;
    font-size: 14px;
    width: 65px;
    padding-bottom: 18px;
    background-color: white;
    border-bottom: 2px solid #BDBDBD;
  }
  .desired-loc-skill .ant-select-selection-placeholder {
    font-size: 16px; 
    color: #777777;
    font-weight: 400;
  }
  .location-subtitle {
    margin-top: 5px;
  }
  .date-container-datepicker {
    margin-top: 20px;
  }
  .date-container-datepicker .ant-picker-input > input {
    font-size: 18px;
    font-weight: 400;
  }
  .desired-loc-skill .ant-select-selection-item {
    border-radius: 1.75rem;
  border: 0.06rem solid #d4d4d4;
  background-color: #f2f2f2;
  font-size: 14px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 10px 3px 10px;
  }
  .desired-loc-skill .ant-select-selector {
    font-size: 1rem;
    margin-bottom: 2px;
  }
  .placeholder-new-signup-loc-skill {
    margin-bottom: 7px;
  }
  .placeholder-new-signup-loc-skill .circle-box {
    height: 30px;
  }
  .placeholder-new-signup-loc-skill .circle-box span{
    font-size: 0.85rem;
    font-weight: 400;
  }
  .placeholder-new-signup-loc-skill .example {
    margin-top: 3px;
    font-size: 1rem;
  }

}