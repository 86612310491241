.mobile-alert .ant-alert-close-icon {
	order: -1;
	margin-right: 10px;
	padding-top: 20px;
}
.mobile-alert .anticon-close {
	color: white;
	font-size: 16px;
}
.mobile-alert .ant-alert-message {
	display: flex;
	align-items: center;
}
.description {
	display: flex;
}

.icon-container {
	padding-top: 16px;
}

.text-container {
	padding: 7px 0 0 15px;
}

.button-container {
	padding: 8px 0 0 13px;
}

.custom-button {
	background-color: #118936;
	border: 1px solid #118936;
	color: white;
	cursor: pointer;
}
