
.resume-five-star-button {
    background-color: #FFCA05;
    width: 190px;
    height: 42px;
    color: #1E1E1E;
    font-size: 16px;
    border: 1px solid #000000;
    border-radius: 5px;
    font-weight: 600;
  }
  
  .Modal{
    position: relative;
  }
  .ant-modal-content {
  margin-top: 20px;
  }
  .ant-modal-footer {
    border-top: none;
    display: flex; 
    justify-content: center;
    height: 90px;
    padding-top: 20px;
  }
  
  .main-container-modal {
    width: 490px;
    height: 408px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 45px 0 0 40px;
  }
  
  .FiveStar {
    width: 228px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .instruction {
    margin:20px 0 26px 0;
    font-size: 18px;
    width: 484px;
    color: rgba(33, 33, 33, 1);
    font-weight: 400;
  }
  .Each-text{
    font-weight: 600;
    font-size: 28px;
    color:#1E1E1E;
  }

  
  .documents {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 125px;
  }
  
  .documents ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .documents li {
    margin-bottom: 15px;
    font-size: 14px;
    display: flex;
    color: #212121;
    font-weight: 500;
    align-items: center;
  }

  .check-image {
    width: 18px; 
    height: 18px;
    margin-right: 7px;
  }

  .div-contains-modal{
    position: relative;
  }

  .Circle{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    position: absolute;
    right: -200px;
    top: -75px;
    background-color: white;
  }

  .Circle-Star{
    display: flex;
    flex-direction: row;
    width: 70px;
    gap: 15px;
    height: 50px;
  }
  

  @media (max-width: 768px) {
    .Modal {
      width: 330px;  
      
    }
    .StarRatingModal{
      width: 24px;
      height: 24px;
    }
    .Number-rating{
      margin: 0;
      font-size: 18px;
    }
    .Each-text{
      font-size: 20px;
    }

    .main-container-modal {
       width: 330px;
       height: 370px;
       padding: 25px 0 0 25px;
       margin-top: 60px;
    }
    .instruction{
      width: 290px;
      height: 72px;
      font-size: 14px;
      color:rgba(126, 126, 126, 1);
      margin-top: 5px;
    }
    .ant-modal-footer{
      height: 52px;
      padding: 0;

    }

    .documents {
        gap: 20px; 
    }

    .Circle {
        right: 40%; 
        transform: translateX(170%);
        top: -90px; 
        width: 80px;
        height: 80px;
    }
    .Circle-Star{
      width: 43px;
      height: 24px;
      gap: 2px;
      justify-content: center;
      align-items: center;
    }
}