.chatcard {
  position: relative;
  /* height: inherit; */
  height:69%;
  padding-bottom: 1rem;
  overflow: auto;
  /* padding-bottom: 7rem; */
  /* padding-bottom: 1rem; */
  /* padding-top: 7rem; */
  scroll-behavior: smooth;
  /* background-color: #4054b2; */
}

.chatcard[aria-details='more-than-one-msg']{
  position: relative;
  /* height: inherit; */
  overflow: auto;
  /* padding-bottom: 7rem; */
  padding-bottom: 1rem;
  /* padding-top: 7rem; */
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column-reverse;
  height: 69%;
}

.chatcard::-webkit-scrollbar {
  /* width: 1.8px; */
  width: 0.5rem;
  height: 5px !important;
}

.chatcard::-webkit-scrollbar-track {
  background: #fffefe;
}

/* Handle */
.chatcard::-webkit-scrollbar-thumb {
  background: rgb(201, 201, 201);
}

/* Handle on hover */
.chatcard::-webkit-scrollbar-thumb:hover {
  background: rgb(201, 201, 201);
}

.chat-card-image .chat-card-image-avatar {
  width: 10px;
  height: 10px;
  flex-grow: 0;
  margin: 25px 0 0 -20%;
}

.chatHeaderCont .chatTitle-Cont {
  width: 60%;
  /* height: 99%;  */
  /* border: 1px solid grey; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatTitle-Cont .job-Title {
  font-size: 14px;
  font-weight: 500;
  color: #3e3e3e;
}

.chatTitle-Cont .nameTitle {
  font-size: 12px;
  font-weight: bold;
  color: #5e5e5e;
}

.chatTitle-Cont .bidTitle {
  font-size: 12px;
  font-weight: normal;
  color: #7e7e7e;
}

.chatTitle-Cont .chatRatingCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatRatingCont .starIcon {
  font-size: 12px;
  color: #ffba08;
}

.chatRatingCont .ratingText {
  font-size: 12px;
  color: #ffba08;
}

.chatRatingCont .rateCount {
  font-size: 12px;
  color: #7e7e7e;
}

.chatcard .titlejob-cont {
  padding: 1% 0px 1% 3%;
  background-color: #f2f2f2;
  color: #4054b2;
}

.chatcard .InnerChatCont {
  padding: 0px 20px;
}

.InnerChatCont .left {
  width: 100%;
  display: flex;
  justify-content: start;
}

.InnerChatCont .right {
  /* float: right; */
  width: 100%;
  display: flex;
  justify-content: end;
}

.InnerChatCont .spaceTop {
  margin-top: 20px;
}

.conv-Date {
  margin-left: 38%;
}


@media only screen and (max-width:768px) {
  .chatcard {
    margin: 6rem 0px 0px 0px;
    /* padding: 0px 0px 3rem 0px; */
    /* padding-top: 5rem; */
    margin-bottom: 70px;
    /* height: 90% !important; */
  }
  .chatcard[aria-details='more-than-one-msg']{
    /* padding-top: 0rem; */
    margin-top: 6rem 0px 0px 0px;
    /* padding: 0px 0px 6rem 0px; */
  }
}