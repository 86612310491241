.dashboard-annalytics-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.6875rem;
}

.annalytics-details {
  width: 20%;
  height: 6.25rem;
  flex-grow: 0;
  padding: 0.8125rem 0rem 0.9375rem 1.25rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
}
.annalyticsTooltip {
  position: absolute;
  right: -0.5625rem;
  top: -0.0625rem;
}
.annalyticsTooltipTopRight.ant-tooltip-placement-top,
.annalyticsTooltipTopRight.ant-tooltip-placement-topLeft,
.annalyticsTooltipTopRight.ant-tooltip-placement-topRight {
  padding-bottom: 0rem;
}
.annalyticsTooltipTopRight .ant-tooltip-inner {
  border-radius: 0.3125rem;
}
.annalytics-counts-details {
  display: flex;
  flex-direction: column;
}

.count {
  width: 1.625rem;
  height: 1.625rem;
  flex-grow: 0;
  font-family: SFProText;
  font-size: 1.375rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}
.details {
  width: 100%;
  height: 0.875rem;
  flex-grow: 0;
  margin: 0.1875rem 0 0.9375rem 0.0625rem;
  font-family: SFProText;
  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}
.annalytics-btn {
  display: flex;
  flex-direction: row;
}

.btn-text,
.annalytics-arrow-icon {
  font-family: SFProText;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #118936;
}

.btn-text {
  flex-grow: 0;
  margin: 0 0.3125rem 0 0;
}

.annalytics-details:hover {
  background-color: #f6f6f6;
}

.disableButtonDA {
  cursor: default;
}

@media screen and (max-width: 1000px) {
  .dashboard-annalytics-container {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .annalytics-details {
    width: 31.17% !important;
    height: 5.375rem;
    padding: 0.3125rem 0rem 0.5625rem 0.625rem !important;
  }
  .annalyticsTooltip {
    right: -0.8125rem;
    top: -0.5rem;
  }
}
