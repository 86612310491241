.mainDiv {
  padding: 25px 30px 20px 30px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  column-gap: 10px;
  max-width: 920px;
  margin: auto;
}
.subDiv-1 {
  width: 48%;
  border: 1px solid #00000040;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px #00000040;
}
.subDiv-2 {
  width: 48%;
}
.mainDiv-2 {
  text-align: center;
  padding: 30px;
}
.skillgigs-div {
  color: #212121;
  font-weight: 600;
  font-size: 24px;
}
.company-div {
  color: #777777;
  font-size: 14px;
  font-weight: 400;
}
.contact {
  color: #fc1254;
}
.access {
  font-size: 22px;
  font-weight: 500;
}
.signup {
  font-size: 16px;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
}
.headingDiv {
  font-size: 14px;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #4b4b4b;
}
.heading1 {
  font-size: 14px;
  font-weight: 400px;
  padding-top: 7px;
  padding-bottom: 7px;
}
.heading2 {
  padding-top: 40px;
  color: #4b4b4b;
}
.formDiv {
  padding: 30px;
}
.joinbtn {
  color: white;
  width: 100%;
  background: #fc1254;
  height: 43px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #fc1254;
}
.text1 {
  color: #777777;
  padding: 3px;
}
.text2 {
  text-align: center;
  padding-top: 25px;
  font-size: 14px;
  font-weight: 400;
}
.text3 {
  color: #fc1254;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}
.inputField {
  height: 40px;
  border-radius: 2px;
}
.passwordHelp-1 {
  margin-top: 0.4rem;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #5e5e5e;
}

@media (max-width: 768px) {
  .subDiv-1 {
    width: 96%;
  }
  .subDiv-2 {
    display: none;
  }
}
