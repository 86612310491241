.notification {
  background-color: #fff;
  /* position: absolute; */
  /* overflow: auto; */
  overflow-x: hidden;
  /* overflow-y: scroll; */
  /* height: 70vh; */
  overflow-x: hidden;
  width: 30rem;
  border: 1px solid #e4e4e4;
  right: 12.5rem;
  /* position: fixed; */
}

.notification-dropdown {
  position: fixed;
}

.notification-dropdown.ant-dropdown-placement-bottom > .ant-dropdown-arrow{
  left: 68%;
}

.notification-dropdown-message.ant-dropdown-placement-bottom > .ant-dropdown-arrow{
  left: 79%;
}

/* .ant-dropdown  .ant-dropdown-placement-bottom > .ant-dropdown-arrow {
  top: 3.6rem;
  right: 26.8rem;
  position: fixed;
  left: unset;
} */

.notificationListCont {
  margin-top: 3rem;
}

.notification-scroll-parent .infinite-scroll-component::-webkit-scrollbar {
  /* width: 7px; */
  width: 12px;
}

.inbox-loader {
  font-size: 35px;
  color:#118936;
}

.notification-scroll-parent .infinite-scroll-component::-webkit-scrollbar-track {
  background: #fff;
}

.notification-scroll-parent .infinite-scroll-component::-webkit-scrollbar-thumb {
  background: #e4e4e4;
  border-radius: 12px !important;
  cursor: pointer !important;
}

.msgs-infinite-scroller::-webkit-scrollbar {
  width: 12px;
}

.msgs-infinite-scroller::-webkit-scrollbar-track {
  background: #fff;
}

.msgs-infinite-scroller::-webkit-scrollbar-thumb {
  background: #e4e4e4;
  border-radius: 12px !important;
  cursor: pointer !important;
}

.msgs-infinite-scroller .infinite-scroll-component__outerdiv{
  margin: 0;
}

.notification-cards-container {
  width: 100%;
  /* height: 65vh;
  overflow: auto; */
  height: max-content;
  overflow: auto;
  /* height: max-content; */
  max-height: 70vh;
  /* overflow-y: auto; */
}

.notification-items-container {
  width: 100%;
  height: 65vh;
  overflow: auto;
}

.notificationHeader {
  width: 480px;
  height: 49.9px;
  border: solid 1px #e4e4e4;
  background-color: #fff;
  position: fixed;
  background: #fff;
  z-index: 500;
}

@media screen and (max-width: 768px) {
  .notificationHeader {
    width: 100%;
    top: 6.2rem;
    height: 49.9px;
    border: solid 1px #e4e4e4;
    background-color: #fff;
    position: fixed;
    background: #fff;
    z-index: 500;
  }

  .notificationHeader[aria-details='extra-top'] {
    width: 100%;
    /* top: 14.7rem; */
    height: 49.9px;
    border: solid 1px #e4e4e4;
    background-color: #fff;
    position: fixed;
    background: #fff;
    z-index: 500;
  }

  .notificationListCont {
    /* padding-top: 6vh; */
    position: relative;
    /* margin-top: 2rem !important; */
  }

  .notification-cards-container {
    /* margin-top: 5.3ch; */
    width: 100%;
    /* padding-top: 3%; */
  }

  .notification {
    width: 100%;
  }
}

.seeAll-div{
  width: 100%; 
  height: 20px; 
  align-self: flex-end;
}

.seeAll-text{
  cursor: pointer;
  color:#4054b2;
  float:right;
  padding-right: 15px;
  padding-top: 2px;
}

.notificationHeader .notificationCont {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-right: solid 1px #e4e4e4;
}

@media screen and (min-width: 992px) {
  .notificationHeader .notificationCont {
    padding: 0px 35px;
  }
}

.no_msgsCont .imageContainer {
  width: 100%;
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 35%;
}

.no_msgsCont .imageContainer div{
margin-top: 5%;
text-align: end;
font-weight: bold;
color: #1e1e1e;
}

.notificationHeader .titleText {
  font-size: 16px;
  color: #4e4e4e;
  cursor: pointer;
}

.notificationHeader .titleActive {
  color: #118936;
}

.notificationHeader .img_cont {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.notificationHeader .img_cont img {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.notificationHeader .messagesCont {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0px 35px;
}

@media screen and (min-width: 992px) {
  .notificationHeader .messagesCont {
    padding: 0px 65px;
  }
}

.messagesCont .titleMessage {
  font-size: 16px;
  color: #4e4e4e;
}

.no_notificationCont {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
}

@media screen and (max-width: 991px) {
}

.no_notificationCont .imageContainer {
  width: 107px;
  height: 137px;
}

.no_notificationCont img {
  width: 100%;
  height: 100%;
}

.notification .InboxFiltersCont {
  display: flex;
  padding: 0% 0.7rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 9.2rem;
  z-index: 20;
  background-color: #fff;
  border-bottom: 1px solid #e4e4e4;
}

@media screen and (min-width: 992px) {
  .notification .InboxFiltersCont {
    display: none;
  }
}

.notification .InboxFiltersCont .conversationTitleCont {
  display: flex;
  flex-direction: row;
  padding: 15px 0px 15px 0;
}

.InboxFiltersCont .conversationTitleCont > .titleConversation {
  font-size: 16px;
  margin-right: 5px;
  color: #1e1e1e;
}

.InboxFiltersCont .conversationTitleCont .titleCount {
  color: #7e7e7e;
}

.InboxFiltersCont .conversationfilterCont {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 1.8rem;
  flex-grow: 0;
  padding: 0px 2% 5px 2%;
  border-radius: 4px;
  border: solid 1px #e4e4e4;
  background-color: #fff;
  margin-right: 4%;
}

.InboxFiltersCont .filterSearchConversationCont {
  background-color: #fff;
  cursor: pointer;
  display: flex;
}

.InboxFiltersCont .conversation-header-inputt {
  height: 29px;
  border-radius: 4px;
  width: 92%;
}

.InboxFiltersCont .cross-icon-divv {
  margin-top: 3px;
  margin-left: 2%;
}

/* ----------------------------------- */

.blackIcon {
  filter: grayscale(100%);
}

.greenIcon {
  filter: grayscale(100%) brightness(80%) sepia(300%) hue-rotate(50deg)
    saturate(400%);
}

.cardsContainer {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 768px) and (min-width: 550px) {
  .notificationListCont {
    width: 32.2rem;
    margin: auto;
  }

  .notification-cards-container {
    border-left: 1px solid #e4e4e4;
    border-right: 1px solid #e4e4e4;
  }

  .notification .InboxFiltersCont {
    width: 32.21rem !important;
    border-left: 1px solid #e4e4e4;
    border-right: 1px solid #e4e4e4;
  }

  .notification {
    background-color: #f8f8f8;
  }
}
