.custom-progress-container .ant-progress {
    width:  620px;
    height: 5px;
    margin-top: 17px !important;
  }
  .Exp-License{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 2rem;
  }
  .Exp-license-number{
    width: 50%;
}
  .Rating-head{
    margin-top: 6px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 70px;
  }
  .ProfileText{
    color: #7e7e7e;
    font-weight: 800;
    font-size: 14px;
}
  .Rating{
    background: var(--Grey-white, #FFF);
  }
  .Rating-Number{
    color: #F4B400;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    padding-left: 10px !important;
  }
  .custom-progress-container{
    display: flex;
    flex-direction: row;
  }
  .custom-progress-container .ant-progress-outer{
    width: 102% !important;
  }

  @media screen and (max-width: 768px) {
    .custom-progress-container .ant-progress-outer{
      width: 109% !important;
    }
    .Exp-license-number{
      width: 90%;
  }
     .Rating{
        width: 22.5px;
height: 38.48px;
top: 0.88px;
left: 0.75px;
     }
.Rating-Number{
font-size: 18px;
font-weight: 600;
line-height: 21.48px;
text-align: left;

    }
  }