.NameScreenContainer{
    padding-top: 1rem;
}
.bck-button-2{
    width: 72% !important;
}

.Profile-Completion-Parent{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding-top: 2rem; */
}
.location-selection .ant-input-affix-wrapper > input.ant-input {
    color: red;
}
.alert-error{
    width: 50%;
    margin-left: 25%;
}
.Container-ExperienceLevelScreen .gap-back-next-signup-buttons {
    /* width: 88%; */
    /* justify-content: center; */
    /* margin-top: 50px !important; */
    padding-left: 1%;
    /* margin-top: 7rem; */
}
@media screen and (max-width: 768px) {
    .alert-error{
        width: 90%;
        margin-left: 5%;
    }
    .Profile-Completion-Parent{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 0px !important;
    }
    .Container-ExperienceLevelScreen .gap-back-next-signup-buttons {
    width: 88%;
    /* justify-content: center; */
    margin-top: 7rem;
    margin-left: 6%;
}
.bck-button-2{
    margin-left: 90px !important;
}
}