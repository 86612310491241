.messageInput {
    height: 40px;
    border-radius: 4px;
    /* margin-top: 10px; */
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}

.messageAttactmentCont {
    display: flex;
    justify-content: space-between;
    margin-top: 1%;
}

.messageAttactmentCont .clipIcon {
    width: 22px;
    height: 24px;
    object-fit: contain;
}

.messageAttactmentCont .sendText {
    font-size: 14px;
    font-weight: 500;
    color: #118936;
}

.upload-progress{
    width: 90%;
    margin: auto;
}


.send-message-container {
    padding: 0 10px 10px;
    bottom: 0;
    position: absolute;
    width: -webkit-fill-available;
    /* background: #fff; */
    z-index: 1;
}

.send-message-container .teaxArea-content {
    padding: 0.57rem 0.9375rem 0.6875rem 0.7375rem;
    height: 2.5rem;
    font-size: 0.875rem;
    width: 100%;
    border: none;
    box-shadow: none;
    line-height: 1.1625rem;
    overflow: auto;
    max-height: 100px;
    min-height: 40px;
    resize: none;
    transition: all 0.3s, height 0s;
}

.send-message-container .ant-input {
    /* padding: 0rem; */
    border-radius: 0.25rem;
    background-color: #fff;
    transition: all 0.3s, height 0s;
}

.text-with-attachment {
    padding: 0.3rem 0.1rem;
}

.send-message-container .message-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.55rem;
}

.conversation-icon svg {
    width: 18.1px;
    height: 18.3px;
    margin-left: 2px;
    margin-top: 4px;
    color: #7e7e7e;
}

a.message-send-btn {
    color: #118936;
    font-weight: 500;
    cursor: pointer;
}

.conversation-icon svg {
    width: 18.1px;
    height: 18.3px;
    margin-left: 2px;
    margin-top: 4px;
    color: #7e7e7e;
    cursor: pointer;
}

.attachment-box {
    margin: 0rem;
}

.attachment-container {
    width: 50%;
    margin: 0.75rem 0.3375rem 0.6875rem 0.6375rem;
    position: relative;
}

.attachment-container .attachment-container-close-btn {
    position: absolute;
    top: -0.4375rem;
    right: -0.5rem;
    cursor: pointer;
}

.attachment-content-info {
    width: 90%;
}

.attachment-content-info>h3 {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0rem;
    padding-bottom: 0.125rem;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.attachment-content-info>p {
    margin: 0rem;
    font-size: 0.625rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #9e9e9e;
}

.attachment-content {
    width: 100%;
    padding: 0.625rem 0.75rem 0.9375rem;
    border-radius: 0.3125rem;
    border: solid 0.0625rem #c4c4c4;
    display: flex;
    gap: 0.9375rem;
}

.attachment-content .fileIcon {
    max-width: 1.25rem;
    max-height: 1.625rem;
    fill: #cb0606;
}

@media only screen and (max-width:769px) {
    .send-message-container {
        position: fixed !important;
        padding-bottom: 15px !important;
    }
  }

@media only screen and (min-width: 769px) {
    .ant-select-dropdown {
        z-index: inherit;
    }
    .ant-scrolling-effect .ant-select-dropdown {
        z-index: 1000;
    }
}