.myActivityContainer {
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
  margin-top: 0.4375rem;
  width: 100%;
}

.myActivityHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.myActivityTitle {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  color: #1e1e1e;
}

.myActivityViewAllBtn {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: normal;
  text-align: right;
  color: #4054b2;
  /* padding-right: 0.625rem; */
  cursor: pointer;
}

/********** For Mobile View  Media Query Start **********/
@media screen and (max-width: 1000px) {
  .myActivityContainer {
    gap: 0rem;
    margin-top: 0rem;
  }

  .myActivityHeader {
    padding: 1.25rem 0.9375rem 1rem;
    border-bottom: 0.0625rem solid #e4e4e4;
  }

  .myActivityViewAllBtn {
    padding-right: 0rem;
  }
}
/********** For Mobile View  Media Query End **********/
