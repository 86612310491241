.input-screens {
  display: block;
  border: none;
  margin: 40px 0 0 0;
  font-size: 22px;
  width: 100%;
  padding-bottom: 18px;
  border-bottom: 1px solid #BDBDBD;
}

.input-screens.error-block {
  border-bottom: 1px solid #DB2828;
}

.input-screenscurrency {
  border: none;
  font-size: 1.5rem;
  font-weight: 600;
  width: 100%;
  padding: 0 0 0 10px;
  color: #777;
  /* border-bottom: 1px solid #BDBDBD; */
}

.input-screens-currency {
  border: none;
  margin: 50px 0 25px 0;
  font-size: 14px;
  width: 65px;
  padding-bottom: 18px;
  background-color: white;
  border-bottom: 2px solid #BDBDBD;
}
.down-icon {
  color: #7E7E7E;
}
.currency-parent-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 20px;
  padding: 10px 0 10px 0;
  border-bottom: 1px solid #BDBDBD;
}

.currency-parent-container.error-block {
  border-bottom: 1px solid #DB2828;
}
.currency-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.currency-container .usd-container {
  padding-right: 10px;
  border-right: 1px solid #000000;
}

.currency-container .usd-container span{
  font-weight: 600;
  font-size: 1.5rem;
  color: #2E2E2E;
}
.currency-container .dollar-sign {
  font-size: 1.5rem;
  font-weight: 600;
  margin-left: 0.7rem;
  color: #777;
}
.week-btn {
  padding: 8px 35px;
  border: 1px solid #9D9D9D;
}
.input-screens:focus {
  outline: none;
}
.input-screens-currency:focus {
  outline: none;
}
.input-screenscurrency:focus {
  outline: none;
}
.CreateSkillListingHeaderScreen2 {
  /* margin-bottom: 60px; */
}

.input-screenscurrency::-webkit-outer-spin-button,
.input-screenscurrency::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.year-weekly-container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.skill-btn {
  display: inline-block;
  color: #118936;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding: 13px 16px;
  border: 2px solid #118936;
  border-radius: 5px;
}

.CreateSkillListing-text h5 {
  font-size: 14px;
  font-weight: 600;
}

.CreateSkillListing-text .create-Benefits h5 {
  font-weight: 800;
}

.desired-loc-skill {
  width: 100%;
  padding: 1%;
  padding-left: 0;
  border-bottom: 1px solid #BDBDBD;
  margin-top: 25px;
}

.desired-loc-skill.error-block {
  border-bottom: 1px solid #DB2828;
}

.desired-loc-skill .ant-select-selection-placeholder {
  font-size: 22px; 
  color: #777777;
  font-weight: 400;
  left: 0px !important;
  right: 0px !important;
}

.desired-loc-skill .ant-select-selection-search {
  margin: 0px;
  padding: 0px;
}
.desired-loc-skill .ant-select-selector {
  font-size: 22px;
}

.desired-loc-skill .ant-select-selector {
  font-size: 1.5rem;
  font-weight: 400;
  padding-left: 0 !important;
}

.desired-loc-skill.title .ant-select-selection-search {
  right: 0px !important;
  left: 0px !important;
}

.desired-loc-skill .ant-select-selection-item {
  border-radius: 1.75rem;
  border: 0.06rem solid #d4d4d4;
  background-color: #f2f2f2;
  font-size: 14px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 10px 3px 10px;
}
.desired-loc-skill .ant-select-selection-item-content {
  padding-left: 2px;
  padding-right: 5px;
}
.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  font-weight: normal;
  background-color: #F4F4F4;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-selected .ant-select-item-option-state {
  color: #000000;
}

.location-subtitle {
  margin-top: 10px;
}
.location-limit-text {
  font-weight: 400;
  font-size: 12px;
  color: #7E7E7E;
  margin-top: 10px;
}

.date-container-datepicker {
  width: 100%;
  border: none;
  border-bottom: 1px solid #BDBDBD;
  margin-top: 30px;
}

.date-container-datepicker.error-block {
  border-bottom: 1px solid #DB2828;
}
.date-container-datepicker .ant-picker-input > input::placeholder {
  color:#777777;
}
.date-container-datepicker .ant-picker-input > input {
  font-size: 24px;
  font-weight: 400;
}
.ant-picker {
  padding: 5px 5px 5px 0px !important;
}

.custom-datepicker .ant-picker-focused, .ant-picker:hover{
  border-color: #BDBDBD;
}
.outerbox .ant-picker:hover, .ant-picker-focused {
  border-bottom: 1px solid #BDBDBD !important;
}
.ant-picker-focused {
  box-shadow: none;
}

.placeholder-new-signup-loc-skill {
  display: flex;
  flex-wrap: wrap;
}
.placeholder-new-signup-loc-skill .example {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -4px;
  font-size: 1.35rem;
}
.placeholder-new-signup-loc-skill .circle-box {
  margin-left: 10px;
  border-radius: 1.75rem;
  border: 1px solid #D9D9D9;
  background-color: #F9F9F9;;
  font-size: 14px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 12px 3px 12px;
}

.placeholder-new-signup-loc-skill .circle-box span{
  font-size: 1rem;
  color: #7E7E7E;
}

.placeholder-new-signup-title {
  margin-top: 2px;
}
.desired-loc-skill.title .ant-select-selection-search-input {
  height: 25px !important;
}
.skill-listing-title-suggestions-container {
  margin-top: 25px;
}
.skill-listing-title-suggestions-container > span {
  color: #7E7E7E;
  font-size: 0.75rem;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 20px;
}
.skill-listing-title-suggestion {
  cursor: pointer;
  margin-top: 10px;
  border-radius: 2.5rem;
  border: 1px solid #D9D9D9;
  background-color: #F9F9F9;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.25rem;
  width: fit-content;
}
.skill-listing-title-suggestion span{
  font-size: 0.875rem;
  color: #444;
  font-weight: 400;
}
.skill-listing-title-tip-container {
  display: flex;
}
.skill-listing-title-suggestion-cont {
  width: 70%;
  padding: 4% 2% 0% 0%;
}
.skill-listing-title-tip-container .CreateSkillListing-tip-cont {
  margin-top: -2rem;
}
.CreateSkillListing-tip-cont.title {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}
@media screen and (max-width: 768px) {
  .ant-select-selection-overflow{
    height:80px 
  }
  .Mobile-class{
position: relative;
top: 9px;
left: 22px;
  }
  .input-screens {
    display: block;
    border: none;
    margin: 30px 0 0 0;
    font-size: 16px;
    padding-bottom: 12px;
    border-bottom: 1px solid #BDBDBD;
    max-width: 100%;
  }
  .input-screens-currency {
    border: none;
    margin: 50px 0 25px 0;
    font-size: 14px;
    width: 65px;
    padding-bottom: 18px;
    background-color: white;
    border-bottom: 2px solid #BDBDBD;
  }
  .currency-container .usd-container span {
    font-weight: 600;
    font-size: 1rem;
    color: #2E2E2E;
  }
  .currency-container .dollar-sign {
    font-size: 1rem;
  }
  .mobileview-currency-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .btns-container {
    display: flex;
    flex-direction: row;
    margin: 0px 0px 25px 0px;
  }
  .year-weekly-container {
    justify-content: space-between;
    margin-top: 20px;
  }
  .week-btn {
    display: flex;
    justify-content: center;
    padding: 8px 23px;
    border: 1px solid #9D9D9D;
    cursor: pointer;
    width: 45%;
  }
  .input-screenscurrency {
    border: none;
    font-size: 1rem;
    font-weight: 600;
    width: 80%;
    color: #777;
  }
  
  .down-icon {
    color: #7E7E7E;
  }
  .input-screens-currency {
    border: none;
    margin: 50px 0 25px 0;
    font-size: 14px;
    width: 65px;
    padding-bottom: 18px;
    background-color: white;
    border-bottom: 2px solid #BDBDBD;
  }
  .desired-loc-skill .ant-select-selection-placeholder {
    font-size: 16px; 
    color: #777777;
    font-weight: 400;
  }
  .location-subtitle {
    margin-top: 5px;
  }
  .date-container-datepicker {
    margin-top: 20px;
  }
  .date-container-datepicker .ant-picker-input > input {
    font-size: 18px;
    font-weight: 400;
  }
  .desired-loc-skill .ant-select-selection-item {
    border-radius: 1.75rem;
    font-size: 14px;
    height: 30px;
    padding: 3px 10px 3px 10px;
  }
  .desired-loc-skill .ant-select-selector {
    font-size: 1rem;
    margin-bottom: 2px;
  }
  .placeholder-new-signup-loc-skill {
    margin-bottom: 7px;
  }
  .placeholder-new-signup-loc-skill .circle-box {
    height: 30px;
  }
  .placeholder-new-signup-loc-skill .circle-box span{
    font-size: 0.85rem;
    font-weight: 400;
  }
  .placeholder-new-signup-loc-skill .example {
    margin-top: 3px;
    font-size: 1rem;
  }
  .placeholder-new-signup-title {
    margin-top: 0px;
  }
  .desired-loc-skill.title .ant-select-selection-search-input {
    height: 30px !important;
  }
  .skill-listing-title-suggestion-cont {
    width: 100%;
    padding: 0;
  }
}