.roleExperiencePageNew {
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.roleExperiencePageNew .roleExperienceNew {
  width: 56.25rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  /* padding: 1.875rem 1.875rem 1.25rem 1.875rem; */
  margin: 2.5rem 11.875rem 3.06rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

.roleExperience-Heading {
  font-size: 1.125rem;
  font-weight: 600;
  color: #1e1e1e;
}

.countryCode-Modal-selecter .ant-space-item {
  overflow: auto;
  height: 25rem;
}

.countryCode-Modal-selecter .radio-group {
  display: unset !important;
}

.countryCode-Modal-selecter .radio-styles {
  width: -webkit-fill-available;
}

.countryCode-Modal-selecter::-webkit-scrollbar {
  display: none;
}

.roleExperience-Flag-Mobile-code {
  display: flex;
  /* border: 0.0625rem solid #d4d4d4; */
}

.roleExperience-Mobile-code {
  padding-left: 0.5rem;
}

.Flag-Mobile-code-borderColor {
  border: 0.0625rem solid #db2828 !important;
}

.roleExperience-Sub-Heading {
  font-size: 0.875rem;
  font-weight: normal;
  color: #7e7e7e;
  padding-top: 0.75rem;
  margin-bottom: 1.875rem;
}

.roleExperience-container {
  display: flex;
  flex-direction: column;
}

.roleExperienceNew .login-form {
  flex-direction: column;
  justify-content: space-between;
}

.roleExperienceNew .ant-form-item {
  display: flex;
  flex-direction: column;
}

.roleExperienceNew .ant-form-item-label {
  text-align: unset;
}

.roleExperienceNew .ant-form-item-label > label::after {
  content: "*";
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
  color: #db2828;
  font-size: 1rem;
}

.roleExperienceNew .ant-form-item-label > label {
  font-size: 1rem;
  font-weight: 600;
  color: #1e1e1e;
}

.roleExperienceNew
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none !important;
}

.roleExperienceNew .ant-form-horizontal .ant-form-item-control {
  /* width: 21.25rem; */
  width: 25.6rem;
  margin-top: 0.938rem;
}

.roleExperienceNew .ant-input:placeholder-shown {
  min-height: 2.75rem;
  border-radius: 0.25rem;
}
.role_Experience .ant-input{
  width: 48.5% !important;
  margin-top: 8px !important;
  min-height: 2.75rem;
  border-radius: 0.25rem;
}
.SSN{
width: 48.5%;
  border: solid 0.06rem #d4d4d4;
  margin-top: 10px;
  height: 2.75rem;
  padding-left: 10px !important;
}
.SSN:focus{
  border:solid 0.06rem #d4d4d4  ;
  outline: none !important;
}
.roleExperience-Name-location {
  display: flex;
  justify-content: space-between;
}

.roleExperience-Mobile-Number {
  padding-top: 1.25rem;
}

.roleExperience-Mobile-Number .ant-input-group .ant-input {
  min-height: 2.75rem;
  font-size: 1rem;
  color: #1e1e1e;
  /* width: 21.25rem; */
}

.roleExperience-Mobile-Number .ant-form-item-control-input-content {
  display: flex;
  align-items: center;
}

.roleExperience-Year-Heading {
  font-size: 0.875rem;
  font-weight: 600;
  color: #1e1e1e;
  padding-top: 1.25rem;
}

.roleExperience-Year-Heading > span {
  color: #db2828;
  font-size: 0.875rem;
}

.roleExperience-Year .radio-group {
  grid-row-gap: 0.625rem;
}

.roleExperience-Year .radio-styles {
  align-items: center;
}

.roleExperience-Year .ant-radio {
  top: 0;
}

.roleExperienceNew .ant-divider-horizontal {
  margin-top: 1.813rem;
  margin-bottom: 1.25rem;
}

.roleExperienceNew-Button-section {
  display: flex;
  justify-content: space-between;
}

.roleExperienceNew-Button-Back {
  /* padding: 0.75rem 1.875rem 0.688rem; */
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  align-items: center;
  border-radius: 0.313rem;
  box-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.25);
  border: solid 0.063rem #118936;
  background-color: #fff;
  font-size: 1rem;
  font-weight: 600;
  color: #118936;
  display: flex;
  cursor: pointer;
}

.roleExperienceNew-Button-Back-Next {
  /* padding: 0.75rem 6.688rem 0.688rem 6.625rem; */
  padding-left: 6.688rem;
  padding-right: 6.625rem;
  border-radius: 0.313rem;
  background-color: #118936;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  height: 2.625rem;
}
.back-click-disable {
  text-shadow: none;
  box-shadow: none;
  background-color: #fff;
  border-color: #c4c4c4;
  color: #ddd;
}

.roleExperiencePageNew .ant-input:hover,
.roleExperiencePageNew .ant-input-focused {
  border-color: #d4d4d4;
  box-shadow: none;
}

.roleExperiencePageNew .ant-input:focus,
.roleExperiencePageNew .ant-input-focused {
  border-color: #d4d4d4;
  box-shadow: none;
  height: 2.75rem;
}

.roleExperience-Name-location .ant-input {
  height: 2.75rem;
  border-radius: 00.25rem;
}

.roleExperiencePageNew .ant-radio-checked::after {
  border: 1px solid #118936 !important;
}

.roleExperiencePageNew .ant-radio-checked .ant-radio-inner {
  border: 1px solid #118936 !important;
}

.roleExperiencePageNew .ant-radio-inner::after {
  background-color: #118936 !important;
}

.roleExperienceNew .ant-form-item {
  margin-bottom: unset;
}

.roleExperience-Name-location
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 2.75rem;
}

.roleExperience-Mobile-Number .ant-input:placeholder-shown {
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
}

.roleExperience-Mobile-Number
  .ant-select-focused:not(.ant-select-disabled)
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none !important;
}

.roleExperiencePageNew-location .ant-form-item-control-input-content > input {
  padding: 0.5313rem;
  border-radius: 0.25rem;
  border: solid 0.0625rem #d4d4d4;
  font-size: 1rem;
  color: #1e1e1e;
}

.roleExperiencePageNew-location .ant-select-selection-item {
  color: #1e1e1e;
}

.roleExperiencePageNew .ant-input-group-addon {
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  border: solid 0.0625rem #d4d4d4;
  background-color: #fff;
}

.roleExperiencePageNew-location
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 2.75rem;
  padding: 0.25rem 0.6875rem;
  border-color: unset;
  box-shadow: none;
  border: solid 0.0625rem #d4d4d4;
  border-radius: 0.25rem;
}

.roleExperiencePageNew-location {
  display: flex;
  justify-content: space-between;
}

.roleExperiencePageNew-location .ant-form-item .ant-form-item-label {
  padding: unset !important;
}

.roleExperienceNew .ant-form-item-explain-error {
  color: #db2828;
  padding: 0.5rem 0 0 0;
}

.roleExperiencePageNew,
.roleExperiencePageNew
  .ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input {
  border-color: #db2828;
}

.roleExperiencePageNew
  .ant-input-group-wrapper-status-error
  .ant-input-group-addon {
  border-color: #db2828;
}

.roleExperiencePageNew
  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #db2828 !important;
}

.roleExperienceNew-Button-section .ant-btn-primary:hover,
.roleExperienceNew-Button-section .ant-btn-primary:focus {
  color: #fff;
  border-color: #118936;
  background: #118936;
}

.skilllisting-signup-margin {
  margin-bottom: 1.25rem;
}

.skilllisting-signup-divider {
  margin: 0.8125rem 0 1.25rem 0;
}

.req-roleExperiencePageNew {
  display: none;
}

.roleExperiencePageNew .ant-input-group-addon {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.roleExperiencePageNew .radio-styles {
  color: #1e1e1e;
  font-size: 0.875rem !important;
}

.padding-container {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}

@media screen and (max-width: 768px) {
  .roleExperience-Name-location {
    display: flex;
    flex-direction: column;
  }

  .roleExperienceNewMargin {
    margin-top: -3.25rem;
  }

  .roleExperiencePageNew .roleExperienceNew {
    /* padding: 1.25rem 0.938rem 0.938rem 0.938rem; */
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    margin: 0;
  }

  .roleExperienceNew .ant-form-item {
    flex-direction: unset;
  }

  .roleExperiencePageNew-location .ant-form-item {
    padding-bottom: 1.25rem;
  }

  .roleExperience-Heading {
    font-size: 1rem;
  }

  .roleExperience-Mobile-Number {
    padding: 0rem !important;
  }

  .roleExperience-Mobile-Number .ant-form-item .ant-form-item-label {
    padding: 0px !important;
  }

  .roleExperience-Sub-Heading {
    font-size: 0.75rem;
    font-weight: normal;
    color: #7e7e7e;
    padding-top: 0.5rem;
    margin-bottom: 1.25rem;
  }

  .roleExperienceNew-Button-Back {
    /* padding: 0 2.4375rem 0 2.4375rem; */
    height: 2.63rem;
    justify-content: center;
  }

  .roleExperienceNew-Button-Back-Next {
    padding: 0 5.25rem;
  }

  .roleExperiencePageNew-location {
    flex-direction: column;
    grid-gap: unset !important;
  }

  .roleExperienceNew-Required-sign {
    color: #db2828;
  }

  .roleExperienceNew .ant-form-horizontal .ant-form-item-control {
    width: 20.625rem;
  }

  .req-roleExperiencePageNew {
    display: unset;
    color: #db2828;
  }

  .roleExperienceNew .ant-form-item-label > label {
    font-size: 0.875rem;
  }

  .roleExperiencePageNew-location .ant-form-item-control-input-content > input {
    font-size: 0.875rem;
  }

  .roleExperiencePageNew-location .ant-select-selection-item {
    font-size: 0.875rem;
  }

  .roleExperience-Mobile-Number .ant-input-group .ant-input {
    font-size: 0.875rem;
  }

  .roleExperienceNew .ant-divider-horizontal {
    margin-top: 1.875rem;
    margin-bottom: 0.9375rem;
  }

  .padding-container {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }

  .profileModelRoleExperience .roleExperienceNew-Button-section {
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
  }
}
