@media screen and (max-width: 768px) {
    .Read-rule-text-main-div-mobile {
        display: flex;
        flex-direction: column;
        gap: .4375rem;
    }

    .Read-rule-text-main-imgHeading-mobile {
        display: flex;
        gap: .4375rem;
        align-items: center;
    }

    .Read-rule-text-main-Heading-mobile {
        font-size: .875rem;
        font-weight: bold;
        color: #1e1e1e;
    }

    .Read-rule-text-main-Text-mobile {
        font-size: .75rem;
        font-weight: normal;
        color: #4e4e4e;
    }
}