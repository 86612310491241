.dropdown-profile-divider {
  background-color: #d4d4d4;
  height: 1px;
  /* opacity: 70%; */
  margin: 15px 0;
}

.uploadbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #fff;
  padding: 7px 10px !important;
  border-radius: 4px !important;
  border: 4px solid #118936;
  background-color: #118936;
}

.bannerbtn {
  width: 167px;
  cursor: pointer;
}

.photosbtn {
  width: 109px;
}

.descriptionbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: auto;
}

.employeebtn {
  width: 124px;
}

.postjobbtn {
  width: 103px;
  margin: 15px 0 0;
  padding: 7px 10px;
}

.linked-Accdiv {
  flex-grow: 0;
  margin: 20px 0px;
  padding: 20px;
  /* height: 217px; */
  border: solid 1px #e4e4e4;
  background-color: #fff;
}

.linked-head {
  flex-grow: 0;
  margin: 0 126px 20px 0;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #3e3e3e;
}

.linked-details {
  flex-grow: 0;
  /* margin: px 0 0 10px; */
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #3e3e3e;
  cursor: pointer;
}

.noDataFound {
  flex-grow: 0;
  /* margin: px 0 0 10px; */
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #3e3e3e;
  cursor: default;
}

.compdetails-div1 {
  /* margin: 0 155px 0 0; */

  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.compdetails-div2 {
  /* margin: 0 0 0 155px; */

  font-size: 14px;
  font-weight: normal;
  text-align: right;
  color: #1e1e1e;
}

.compdetails-div2 div {
  margin-bottom: 2%;
}

.bannerdiv {
  flex-grow: 0;
  /* margin: 20px 0; */
  background-color: #f5f5f5;
  width: 100%;
  height: 250px;
  overflow: hidden;
  /* margin: 0px 0px; */
}

.circle-backg {
  width: 80px;
  height: 80px;
  background-color: white;
  border-radius: 50%;
  grid-area: 1 / 1;
}

._img {
  width: 100%;
  height: 300px;
  object-fit: contain;
}

.MarquePic {
  z-index: -1;
  object-position: 50% 50%;
}

.people_img_cont ._img {
  height: 100%;
  width: 105%;
  object-fit: cover;
  object-position: top;
}

.comp-heading1 {
  font-size: 14px;
  font-weight: 600;
  color: #1e1e1e;
}

.comp-heading2 {
  flex-grow: 0;
  cursor: pointer !important;
  font-size: 14px;
  color: #4054b2;
  font-weight: normal;
}

.comp-heading3 {
  flex-grow: 0;
  font-size: 14px;
  color: #4054b2;
  font-weight: normal;
}

.comp-main {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.heading-txt {
  margin: 15px 0 20px;
  font-size: 14px;
  text-align: left;
  color: #3e3e3e;
}

.heading-txt_bold {
  font-weight: 500;
}

.prof-det {
  align-content: flex-end;
  background-color: brown;
}

.home-container {
  justify-content: center;
  align-items: center;
  display: flex;
  padding-top: 1rem;
  /* background-color: #f8f8f8; */
}

.headings {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #1e1e1e;
}

.jobCard-div-company {
  width: 32.3%;
  display: inline-block;
  justify-content: space-between;
  /* margin: 1px 1.3% 6.5px 0px; */
}

.following-detail-num {
  display: "flex";
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
}

.following-detail-txt {
  display: "flex";
  justify-content: center;
  color: #6e6e6e;
  font-size: 14px;
  font-weight: normal;
}

.prof-circle {
  width: 100px;
  height: 100px;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 400px;
  overflow: hidden;
  margin: 20px 0px 0px 0px;
  background-color: #118936;
}

.profdet {
  font-size: 18px;
  font-weight: 600;
}

.searchme {
  margin: 10px 15px 9px 0;
  padding: 5px 167px 5px 10px;
  border-radius: 4px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

.sub-desc {
  font-size: 10px;
  letter-spacing: normal;
  /* text-align: right; */
  color: #7e7e7e;
}

.filter-desc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  margin: 10px 5px 5px 0px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: rgba(17, 137, 54, 0.1);
  color: #118936;
}

.people_img_cont {
  height: 72px;
}

.job_image_gigs {
  width: 100%;
  height: 120px;
}

.jobCardCompnayProfile {
  width: 100%;
  border: solid 1px #e4e4e4;
  background-color: #fff;
  margin-top: 4%;
}

.jobsTileCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headingTitleLight {
  font-size: 12px;
  color: #4e4e4e;
}

.gig_star_cp {
  color: #ffba08;
  font-size: 12px;
}

.gigRateTileCP {
  font-size: 18px;
  font-weight: 600;
  color: #4e4e4e;
}

.paddingGigCardCp {
  padding: 10px;
}

.comp-rating {
  font-size: 12px !important;
  font-weight: normal;
  color: #ffba08;
  margin-left: 5px;
}

.comp-sub {
  color: #7e7e7e;
}

.comp-rate-cp {
  font-size: 10px !important;
  /* object-fit: contain; */
}

.mr {
  margin-right: 2%;
}

.comp-head {
  font-size: 18px;
  font-weight: 600;
}

.head-sub {
  font-size: 14px;
  font-weight: 500;
}

/* .icon-circle{
  padding: 4px;
  border-radius: 22px;
  border: solid 1px #d4d4d4;
  background-color: #fff;

} */
/* .linked-icons {
  width: "12px";
  height: "12px";
 
} */

.linked-icons {
  cursor: pointer;
}

.bannerbtn2 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 12px !important;
  background-color: #118936 !important;
  color: #fff;
  cursor: pointer;
  padding: 7px 30px;
  border-radius: 4px;
  border: solid 4px #118936;
}

.editIcon-sm {
  width: 20px;
  height: 20px;
}

.profile-edit {
  margin-top: -65px;
  margin-left: 78px;
  z-index: 9999;
  cursor: pointer;
}

.deleteIcon-cont {
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  border: solid 1px #d4d4d4;
  background-color: #fff;
  cursor: pointer;
}

.deleteIcon-sm {
  width: 12px;
  height: 12px;
}

.iconsComtainer {
  display: flex;
  /* justify-content:space-evenly; */
  align-items: center;
  /* background-color: #4054b2; */
}

.company-details-outter-div div {
  margin-bottom: 2%;
}

.comp-head-button {
  margin-top: 20px;
  cursor: pointer;
  outline: none;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  padding: 9px 52px;
  border-radius: 30px;
  border: solid 1px #118936;
  background-color: #118936;
}

@media screen and (min-width: 768px) {
  .Main-Profile-Container {
    margin: 0px 0px 0 0px;
  }

  .home-container {
    margin: 0px 40px 0 40px;
  }

  .bannerdiv {
    height: 275px;
    width: 100%;
    z-index: -1;
  }

  .bannerbtn2 {
    height: 40px;
    width: 15%;
    flex-grow: 0;
    margin: 0 5% 0 0;
    color: #fff;
    font-size: 14px;
    object-fit: contain;
    border-radius: 4px;
    background-color: #118936;
    border: none;
  }

  .prof-circle {
    position: absolute;
    width: 130px;
    height: 130px;
    flex-grow: 0;
    margin: -65px 1032px 161px 38px;
    /* padding: 46.8px 10px 10px 52px; */
    border-radius: 80px;
    background-color: #118936;
    z-index: 1;
  }

  .profile_img {
    position: absolute;
    width: 135px;
    height: 135px;
    flex-grow: 0;
    object-fit: cover;
    /* margin: -65px 1032px 161px 38px; */
    /* padding: 46.8px 10px 10px 52px; */
    border-radius: 80px;
    background-color: #118936;
    z-index: 1;
  }

  .comp-head {
    width: 150%;
    height: 226px;
    flex-grow: 0;
    margin: 0px 0 0;
    padding: 85px 45px 24px 38px;
    border: solid 1px #e4e4e4;
    background-color: #fff;
  }

  .comp-head-name {
    width: 50%;
    display: flex;
    align-items: center;
    gap: 30px;
    margin: 0px 0px 20px 2px;
  }

  .comp-head-name > span {
    max-width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 41px;
    flex-grow: 0;
    font-size: 34px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1e1e1e;
  }

  .comp-head-button {
    margin-top: 0px;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    min-width: 12.8125rem;
    padding: 10px 0px;
    border-radius: 30px;
    background-color: #118936;
  }

  .userfollowing {
    border: 1px solid #118936;
    color: #118936;
    background-color: #fff;
  }

  .head-location {
    width: 154px;
    height: 19px;
    flex-grow: 0;
    margin: -15px 104.9px 20px 2.1px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1e1e1e;
  }
  

  .comp-rating {
    width: 177.4px;
    height: 17px;
    flex-grow: 0;
    margin: -18px 0 0 -10px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    /* border: solid 1px #e4e4e4; */
    color: #7e7e7e;
    margin-left: 3px;
  }

  .comp-rating .comp-sub {
    color: #7e7e7e;
    margin-left: 4px;
  }

  .comp-rating .comp-review {
    font-weight: 500;
    color: #ffba08;
    margin-left: 5.3125rem;
  }

  .head-data {
    width: 52%;
    height: 60%;
    /* border:solid 1px #e4e; */
    margin-top: -100px;
    margin-left: 50%;
  }

  .head-data-item {
    width: 80%;
    height: 120%;
    margin: 0 20px 0 0;
    padding: 10% 10% 0 10%;
    border-radius: 4px;
    background-color: #4054b2;
  }

  .head-data-item-number {
    width: 100%;
    height: 30%;
    flex-grow: 0;
    margin: 0px 0 7px 0px;
    font-size: 18px;
    font-weight: 100;
    align-self: center;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    justify-content: center;
    /* border: #118936 solid 1px; */
    color: #fff;
  }

  .head-data-item-name {
    width: 100%;
    height: 30%;
    flex-grow: 0;
    margin: 0 0 0 0px;
    font-size: 14px;
    /* cursor: pointer; */
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    justify-content: center;
    /* border: #ffba08 solid 1px; */
    color: #fff;
  }

  .company-details-container {
    width: 49%;
    /* height: 100%; */
    margin: 2% 1% 0 0;
    padding-top: 20px;
    /* padding: 20px 1px; */
    border: solid 1px #e4e4e4;
    background-color: #fff;
  }

  .comp-heading1 {
    width: 100%;
    height: 8%;
    flex-grow: 0;
    margin: 0px 0 0px 5%;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    justify-content: left;
    /* border: #118936 solid 1px; */
    color: #1e1e1e;
  }

  .comp-heading-2 {
    width: 20%;
    /* height: 6.5%; */
    height: 15px;
    flex-grow: 0;
    cursor: pointer;
    margin: 3px 0 0px 75%;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    /* border: #118936 solid 1px; */
    color: #4054b2;
  }

  .company-det {
    width: 100%;
    height: 10%;
    flex-grow: 0;
    margin: 15px 0 0 10%;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    /* border: #118936 solid 1px; */
    color: #3e3e3e;
  }

  .company-det2 {
    width: 100%;
    height: 10%;
    flex-grow: 0;
    margin: 15px 0 0 -10%;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    /* border: #118936 solid 1px; */
    color: #1e1e1e;
  }

  .linked-Accdiv {
    width: 49%;
    /* height: 36.7vh; */
    /* height: 100%; */
    flex-grow: 0;
    margin: 2% 0 0 1%;
    padding: 0 1px;
    padding-bottom: 23px;
    border: solid 1px #e4e4e4;
    background-color: #fff;
  }

  .comp-second-container {
    display: flex;
    flex-direction: row;
  }

  .linked-head {
    width: 60%;
    height: 21px;
    flex-grow: 0;
    margin: 20px 0 0% 6%;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    justify-content: left;
    /* border: #118936 solid 1px; */
    color: #1e1e1e;
  }

  .linked-account-det {
    margin: -5px 0 12.6px 8%;
  }

  .linked-account-det-icon {
    margin: 20px 0 0 0;
    cursor: pointer;
  }

  .linked-details {
    width: 30%;
    height: 100%;
    flex-grow: 0;
    margin: 20px 0 0 0%;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    /* border: #118936 solid 1px; */
    color: #3e3e3e;
  }

  .iconsComtainer {
    margin-top: 3%;
  }

  .profileDivider2 {
    background-color: #d4d4d4;
    height: 1px;
    margin-top: 15px;
  }

  .profileDivider3 {
    background-color: #d4d4d4;
    height: 1px;
    margin-top: 0px;
  }

  .profileDivider4 {
    background-color: #d4d4d4;
    height: 1px;
    margin-bottom: -30px;
  }

  .dropdown-profile-divider {
    margin-top: 18.5px;
  }

  .culture-container {
    width: 100%;
    height: auto;
    flex-grow: 0;
    margin: 20px 0px;
    border-radius: 4px;
    border: solid 1px #e4e4e4;
    background-color: #fff;
    padding-bottom: 2rem;
  }

  .headings {
    width: 60%;
    height: 21px;
    flex-grow: 0;
    margin: 20px 0 0% 5%;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    justify-content: left;
    /* border: #118936 solid 1px; */
    color: #1e1e1e;
  }

  .job-headings {
    width: 60%;
    height: 21px;
    display: flex;
    flex-grow: 0;
    margin: 0px 0 0% 5%;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    justify-content: left;
    flex-direction: row;

    /* border: #118936 solid 1px; */
    color: #1e1e1e;
  }

  .comp-heading2 {
    width: 20%;
    height: 19px;
    flex-grow: 0;
    margin: 20px 0 17px 74%;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    white-space: nowrap;
    /* border: #118936 solid 1px; */
    color: #4054b2;
  }

  .comp-heading3 {
    width: 20%;
    height: 19px;
    flex-grow: 0;
    margin: 20px 0 17px 74%;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    white-space: nowrap;
    /* border: #118936 solid 1px; */
    color: #4054b2;
  }

  .heading-txt {
    width: 95%;
    /* height: 17px; */
    height: auto;
    margin: 15px 0 20px 2.6%;
    /* border: #118936 solid 1px; */
  }

  .whyweexist-container {
    width: 100%;
    height: auto;
    padding: 4px;
    /* min-height: 200px; */
    /* height: 165px; */
    /* flex-grow: 0; */
    margin: 20px 0px 0 0;
    border-radius: 4px;
    border: solid 1px #e4e4e4;
    background-color: #fff;
    /* overflow: hidden; */
  }

  .heading-txt_bold {
    width: 95%;
    /* height: 17px; */
    margin: 10px 0 20px 2.6%;
    /* border: #118936 solid 1px; */
  }

  .heading-txt-margin {
    height: 25px;
  }

  .people-container {
    width: 100%;
    height: 336px;
    flex-grow: 0;
    margin: 20px 0;
    border-radius: 4px;
    border: solid 1px #e4e4e4;
    background-color: #fff;
  }

  .people_img_cont {
    width: 30%;
    height: 200px;
    flex-grow: 0;
    margin: 0px 2.6% 0 2.6%;
  }

  .people-name {
    width: 30%;
    height: 17px;
    flex-grow: 0;
    margin: 0px 0px 0px 0px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    /* border: #118936 solid 1px; */
    color: #3e3e3e;
  }

  .people-test {
    width: 90%;
    /* height: 163px; */
    flex-grow: 0;
    margin: 20px 0 0 0;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    /* border: #118936 solid 1px; */
    color: #3e3e3e;
  }

  .job-container {
    width: 100%;
    height: auto;
    flex-grow: 0;
    margin: 20px 0px 50px;
    border-radius: 4px;
    border: solid 1px #e4e4e4;
    background-color: #fff;
  }

  .job-heading {
    width: 60%;
    height: 21px;
    flex-grow: 0;
    margin: 0px 0 0% 5%;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    justify-content: left;
    /* border: #118936 solid 1px; */
    color: #1e1e1e;
  }

  .job_image_gigs {
    width: 390px;
    height: 120px;
    flex-grow: 0;
    margin: 0 0 15px;
    padding: 15px 15px 81px 351px;
  }

  .jobCardCompnayProfile {
    width: 390px;
    height: 288px;
    flex-grow: 0;
    margin: 15px 15px 0 5px;
    border-radius: 2px;
    border: solid 1px #e4e4e4;
    background-color: #fff;
  }

  .job-length {
    width: 30px;
    height: 20px;
    object-fit: contain;
    background-color: #4054b233;
    margin: 2px 0 0 5px;
    color: #4054b2;
    font-size: 14px;
    font-weight: 500;
    border-radius: 12px;
    text-align: center;
    padding-top: 2px;
  }

  .uploaddesbtn {
    width: 60px;
    height: 60px;
    border-radius: 40px;
    margin-left: 19px;
    font-size: 30px;
    padding-bottom: 2px;
    color: #fff;
    flex-grow: 0;
    background-color: #118936;
    border: none;
  }

  .add-heading {
    width: 128px;
    height: 21px;
    flex-grow: 0;
    margin: 20px 0 0 -10px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    /* border: #118936 solid 1px; */
    color: #4054b2;
  }
}

@media screen and (max-width: 768px) {
  .button-container {
    width: 98px;
    height: 101px;
    margin: 52px 0 52px 33% !important;
    /* border: #118936 solid 1px; */
  }
  .culture-container {
    padding-bottom: 1rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  /* .profile-divider2{
    margin: 3% 0;
  }
  .linked-head{
    margin: 5% 0 0% 6%;
  }
  .linked-account-det-icon{
    margin: 3.5% 0 3.9% 0;
  }
  .linked-details{
    margin: 3.5% 0 3.9% 0%;
  } */
}

.button-container {
  width: 98px;
  height: 101px;
  margin: 52px 0 52px 46%;
  /* border: #118936 solid 1px; */
}

.add-heading {
  width: 128px;
  height: 21px;
  flex-grow: 0;
  margin: 20px 0 0 -10px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  /* border: #118936 solid 1px; */
  color: #4054b2;
}

.linked-details-col {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  cursor: pointer;
  margin-top: 1rem;
}

.linked-details-add {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4054b2;
  margin-left: 0.6rem;
}

.linked-details-add-icon {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4054b2;
  margin-left: 0.3rem;
}

.linked-details-col {
  cursor: pointer;
}

.add-modal .ant-modal-content {
  margin-top: 20%;
  max-width: 330px;
  width: 100%;
  height: 178px;
  padding: 17.9px 10px 20px;
  border-radius: 5px;
  border: solid 1px #e4e4e4;
  background-color: #fff;
}

.add-modal .ant-modal-title {
  width: 80px;
  height: 17px;
  margin: 0 250px 13.1px 0;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.add-modal {
  width: 589px;
  transform-origin: 30.5px 315px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.add-modal .ant-modal-header {
  padding: 0px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

.add-modal .ant-btn {
  width: 100%;
  height: 42px;
  margin: 10px 0 0;
  border-radius: 4px;
  background-color: #118936;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.add-modal .ant-modal-body {
  padding: 0px;
}

.add-modal .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin-left: -0.9rem;
}

.add-modal .ant-input {
  height: 41px;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  font-size: inherit;
  color: currentColor;
}

.culture-container .ant-carousel .slick-prev,
.culture-container .ant-carousel .slick-prev:hover {
  /* left: 10px; */
  z-index: 2;
  font-size: 20px;
  color: #4e4e4e;
}
.culture-container .ant-carousel .slick-prev,
.culture-container .ant-carousel .slick-prev:hover {
  /* left: 10px; */
  z-index: 2;
  font-size: 20px;
  color: #4e4e4e;
}
.culture-container .ant-carousel .slick-next,
.culture-container .ant-carousel .slick-next:hover {
  /* right: 10px; */
  font-size: 20px;
  z-index: 2;
  color: #4e4e4e;
}
.culture-container .ant-carousel .slick-next,
.culture-container .ant-carousel .slick-next:hover {
  /* right: 10px; */
  font-size: 20px;
  z-index: 2;
  color: #4e4e4e;
}
.culture-container .ant-carousel .slick-dots {
  bottom: -1.2rem !important;
}
.culture-container .ant-carousel .slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #4e4e4e;
}
.culture-container .ant-carousel .slick-dots li.slick-active {
  width: 10px;
}
.culture-container .ant-carousel .slick-dots li {
  width: 10px;
}
.culture-container .ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #4e4e4e;
}

.culture-container .ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #4e4e4e;
}

.carousel-button,
.companyProfile-carousel .ant-btn:hover,
.companyProfile-carousel .ant-btn:focus {
  color: #ffffff !important;
}

.row-container {
  display: flex;
  justify-content: space-between;
}

.custom-col-style {
  margin-bottom: 2%;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e3e3e;
}

.left-col-container {
  margin-left: 5%;
}

.right-col-container {
  margin-right: 5%;
}

.left-col-container-mobile {
  margin-left: 0.1%;
}

.right-col-container-mobile {
  margin-right: 3%;
}
