

.badge{
    margin-left: 6px;
    margin-top: 4px;
    background-color: black;
    padding: 2px 5px;
    text-align: center;
    border-radius: 3px;
    
    font-family: "Inter", sans-serif;
   font-optical-sizing: auto;
   font-weight: 500;
  /* font-style: normal; */
   font-variation-settings:"slnt" 0;
   font-size: 12px;

  color: #FFFFFF;
  width: 50px;
  height: 15px;
  line-height: 12px;
   top: 2px; 
  
}

