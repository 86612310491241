.bidding-cards {
  max-width: 24.38rem;
  padding: 0 0 0.625rem;
  border-radius: 0.126rem;
  border: solid 0.063rem #e4e4e4;
  background-color: #fff;
  position: relative;
  flex: 1;
  flex-basis: 24.38rem;
}

.bidding-cards .divider-gig-card-details {
  width: 100%;
  height: 1px;
  background-color: #eee;
  margin: 1rem 0;
}

.bidding-cards .sgigs-upAbove {
  padding: 7.5rem 0.625rem 0rem;
}

.bid-card-image {
  width: 100%;
  height: 7.5rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  cursor: pointer;
}

.bid-card-detail-combined-section .bid-information .information-right .review {
  color: #7e7e7e !important;
}

.bidding-title {
  width: 100%;
  margin: 0.938rem 8.75rem 0rem 0rem;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.bid-card-detail-combined-section {
  padding: 7.5rem 0.625rem 0rem;
}

.bid-details-below-divider-line {
  padding: 0rem 0.625rem 0rem;
}

.bidding-title {
  width: 100%;
  margin: 0.938rem 8.75rem 0rem 0rem;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.bid-the-facility {
  width: 100%;
  height: 1.063rem;
  margin: 0.625rem 13.625rem 0rem 0rem;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4e4e4e;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.bid-information {
  display: flex;
  justify-content: space-between;
  margin: 0.75rem 0rem 0rem 0rem;
  height: 1.1rem;
  align-items: center;
}

.bid-information .information-left {
  display: flex;
}

.bid-information .information-right {
  display: flex;
  flex-direction: row;
  width: 1.313rem;
  height: 1.063rem;
  font-size: 0.875rem;
  align-items: center;
}

.bid-information .information-right .bid-rating {
  color: #ffba08;
  margin: 0 0 0 5px;
}

.bid-information .information-right .review {
  color: #7e7e7e;
  margin: 0 0 0 5px;
}

.bid-star {
  width: 1rem;
  height: 1rem;
  flex-grow: 0;
  object-fit: contain;
  border-radius: 0.031rem;
  color: #ffba08;
}

.bid-review {
  display: flex;
  font-size: 0.75rem;
  margin: -0.188rem 0rem 0rem 0rem;
  color: #7e7e7e;
  justify-content: space-between;
}

.bid-review .bid-posted-on {
  font-size: 0.75rem;
  color: #7e7e7e;
}

.if_it_then_change {
  position: relative;
}

.if_it_then_change>div:first-child {
  display: block;
}

.if_it_then_change>div:last-child {
  position: absolute;
  top: -0.9375rem;
  right: 0rem;
}

.bid-gigcard-interested-btn {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin: -0.4rem 0 0rem;
  height: 1.5rem;
}


.bid-budgets {
  display: flex;
  width: 6.5rem;
  height: 1.5rem;
  flex-grow: 0;
  margin: 0 0 0rem;
  font-size: 1.25rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--primaryColor);
  display: block;
}

.bid-div1 {
  padding-top: 0.188rem;
  height: 0.875rem;
  flex-grow: 0;
  margin: 0rem 2.25rem 0 0;
  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.Place-bid-detail-status {
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.place-bid-detail-inner-div {
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
}

.Place-bid-detail-status .ant-col-8 {
  display: flex;
  justify-content: center;
}

.Place-bid-detail-status .place-col-border {
  border-left: 1px solid #e4e4e4;
}

.Place-bid-detail-status span {
  color: #5e5e5e;
  font-size: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.4375rem;
}

.Place-bid-detail-status .place-Bid-count-status {
  color: #3e3e3e;
  font-size: 0.75rem;
  font-weight: 500;
}

.edit-place-bid-icon {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  color: aliceblue;
  right: 10px;
  top: 10px;
}

.edit-place-bid-icon-rb {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  color: aliceblue;
  right: 10px;
  top: 60px;
}

.bid-card-disabled {
  max-width: 24.38rem;
  padding: 0 0 0.625rem;
  border-radius: 0.126rem;
  border: solid 0.063rem #e4e4e4;
  background-color: #fff;
  position: relative;
  flex: 1;
  flex-basis: 24.38rem;
}

.bid-card-disabled .divider-gig-card-details {
  width: 100%;
  height: 1px;
  background-color: #eee;
  margin: 1rem 0;
}

.bid-card-disabled .sgigs-upAbove {
  padding: 7.5rem 0.625rem 0rem;
}

.bid-card-disabled .bid-card-image {
  filter: grayscale(100%);
}
.bid-card-disabled .sgigs-image-receiveBids {
  filter: grayscale(100%);
}

.bid-card-disabled .bidding-title {
  color: #7e7e7e;
}

.bid-card-disabled .bid-the-facility {
  color: #7e7e7e;
}

.bid-card-disabled .bid-information .bid-star {
  color: #7e7e7e !important;
}

.bid-card-disabled .bid-information .bid-rating {
  color: #7e7e7e !important;
}

.bid-card-disabled .Place-bid-detail-status span {
  color: #7e7e7e !important;
}

.bid-card-disabled .bid-budgets {
  color: #7e7e7e !important;
}

.Bidstage-Favorites {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.Bidstage-Favorites:hover {
  color: #118936;
}

.iconFontSize-stages {
  font-size: 1.125rem;
}
  
.iconFontSize-stages1 {
  font-size: 1rem;
}

@media screen and (max-width: 768px) {
  .bid-detail-container .bid-header-text-grey {
    font-weight: 500;
  }

  .edit-place-bid-icon-rb {
    top: 50px;
  }

  .bid-card-detail-combined-section .bid-information .information-right .review {
    color: #7e7e7e !important;
  }

  .bidding-title {
    width: 100%;
    margin: 0.938rem 8.75rem 0rem 0rem;
    font-size: 0.875rem !important;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1e1e1e;
    white-space: unset;
  }

  .bid-the-facility {
    width: 100%;
    height: 1.063rem;
    margin: 0.625rem 13.625rem 0rem 0rem;
    font-size: 0.75rem !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4e4e4e;
  }

  .bid-information .information-right .bid-rating {
    color: #ffba08;
    margin: 0 0 0 5px;
    font-size: 0.75rem;
  }

  .bid-information .information-right .review {
    color: #7e7e7e;
    margin: 0 0 0 5px;
    font-size: 0.75rem;
  }

  .bid-review {
    display: flex;
    margin: -0.188rem 0rem 0rem 0rem;
    color: #7e7e7e;
    justify-content: space-between;
  }

  .bid-budgets {
    display: flex;
    width: 6.5rem;
    height: 1.5rem;
    flex-grow: 0;
    margin: 0 0 0rem;
    font-size: 1.125rem !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--primaryColor);
  }

  .bid-div1 {
    height: 0.875rem;
    flex-grow: 0;
    margin: 0rem 2.25rem 0 0;
    font-size: 0.625rem !important;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #7e7e7e;
  }

  .Bidstage-Favorites {
    color: #3e3e3e
  }
  .bid-card-disabled {
    max-width: 100%;
  }
  .bidding-cards{
    max-width: 100%;
  }
}