.ProfileCompletionParent{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 2rem;
}

.ProfileCompletion{
    width: 50%;
    /* background-color: aquamarine; */
}
.ProfileText{
    color: #7e7e7e;
    font-weight: 800;
    font-size: 1.5rem;
}
.completion__Percentage{
    margin-top: 1rem;
    background-color: #eff1f3;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    overflow: hidden;
}
.progress {
    height: 100%;
    background-color: green;
    border-radius: 5px;
}
@media screen and (max-width: 768px) {
  .subtext{
  padding-right: 39px !important;
  }
    .ProfileCompletion{
        width: 90%;
    }
    .ProfileText{
        color: #7e7e7e;
        font-weight: 800;
        font-size: 14px;
    }
}
.InputTitleContainer {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }
  .TextInput{
    color: #212121;
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;
    text-align: left;
    
  }
  .Text-Type {
    color: #7E7E7E;
    margin-left: 0.7rem;
    margin-top: 23px !important;
    font-size: 0.625rem;
    font-weight: 400;
  }
 
.TextInputField {
  margin-top: 45px;
  

}

.InputText{
  width: 100%;
  padding: 10px;
 border-bottom: 1px solid #BDBDBD;
 border-top: none;
 border-left: none;
 border-right: none ;
 outline: none;
  font-size: 16px;
}

.Ssn-Icon{
width: 37px;
height: 37px;




}

.text{
margin-top: 10px;
font-size: 12px;
font-weight: 500;
line-height: 16px;
text-align: left;
color: #4054B2;
background: #ECEEF770;
  display: flex;
  flex-direction: row;
 
}

@media (max-width: 768px) {
    .text {
      display: block;
      text-align: center;
    }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 10px;
  box-sizing: border-box;
}
 
.secure-ssn {
  display: flex;
  align-items: center;
  background-color: #f5f7fb;
  border-radius: 8px;
  padding: 15px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
 
.icon {
  font-size: 24px;
  color: #3b82f6;
  margin-right: 10px;
}
 
.secure-ssn span {
  font-size: 16px;
  color: #1f2937;
}
 
@media (max-width: 600px) {
  .secure-ssn {
    flex-direction: column;
    align-items: flex-start;
  }
 
  .icon {
    margin-bottom: 10px;
  }
  .text{
    display: flex;
  }
}

.gap-back-next-signup-button {
  margin-top: 50px;
 margin-bottom: 0 !important;
 width:65% !important;
 padding-left:53px ;
}
.Sub-ssn{
  padding-top: 23px;
  width: 447px;
height: 17px;
top: 286px;
left: 330px;
color:  #3E3E3E
}

@media (max-width: 786px) {
  .gap-back-next-signup-buttons {
    margin-top: 45%;
    margin-bottom: 0 !important;
    width:310px !important;
    padding-left:0 !important;
  }
  .Sub-ssn{
    padding-top: 23px;
    width: 310px;
  height: 17px;
  top: 286px;
  left: 330px;
  color:  #3E3E3E
  }
  
}

