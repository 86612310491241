.ViewResumeDownloadPDF-cantainer {
    padding-top: 5.3125rem;
    align-items: center;
    justify-content: center;
    display: flex;
}

.ViewResumeDownloadPDF-cantainer-button {
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: .25rem;
    background-color: #5bd3e3;
    cursor: pointer;
}

.ViewResumeDownloadPDF-button {
    font-size: 1rem;
    font-weight: 500;
    font-style: normal;
    color: #fff;
    padding: .625rem 2.1875rem;
}