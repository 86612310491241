.Eduction-resume-cantainer .ViewResume-sub-heading-section {
    display: flex;
    justify-content: space-between;
    padding-top: 1.25rem;
}

.CourseTypes-SchoolName {
    font-weight: 400 !important;
    color: #3e3e3e;
    padding-top: .5rem;
}