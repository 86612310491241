.sgigsguest-receiveBidCard {
    display: flex;
    /* flex: 0 0 33.333333%; */
    flex-direction: row;
    justify-content: space-evenly;
  }
  
  .sgigsguest-thecard {
    width: 30vw;
    /*
    max-width: 30.375rem;
    padding: 0rem;
    border-radius: 0.126rem;
    background-color: #fff;
    /* flex: 1;
    flex-basis: 24.38rem; */
    margin: 10px 0;
    cursor: pointer;
    border-radius: 0.125rem;
    border: solid 0.063rem #e4e4e4;
    border-top: none;
  }
  
  .sgigsguest-thecard .dividerGigCard {
    /* width: 100%; */
    /* height: 1px; */
    border-top: 1px solid #eee;
    min-height: fit-content;
    /* background-color: #eee; */
    /* margin: 1rem -0.625rem; */
  }
  
  .sgigsguest-thecard .sgigsguest-upAbove {
    padding: 0.9375rem 0.625rem 0.756rem;
    /* min-height: 10rem; */
    /* cursor: pointer; */
  }
  
  .sgigsguest-theStatus {
    padding: 0.313rem 0.625rem;
    background-color: var(--statusColor-cream);
    width: fit-content;
    border-radius: 0.188rem;
    color: var(--statusColor-brown);
    font-size: 0.8rem;
    margin-right: 0.313rem;
  }
  
  .GigCard-CompanyName-FacilityName {
    justify-content: unset !important;
  }
  
  .GigCard-Rating-section-job-g {
    padding-left: .9375rem;
  }
  
  .GigCard-CompanyNameIT-FacilityNameHCguest {
    font-size: .875rem;
    font-weight: normal;
    color: #4e4e4e;
  }
  
  .GigCard-CompanyName-FacilityName-wrap {
    word-wrap: normal;
  }
  
  .cand-sgigsguest-image {
    /* width: 389px; */
    /* width: 442px; */
    width: 100%;
    height: 8rem;
    border-radius: 0.125rem 0.125rem 0rem 0rem;
    /* margin: 0 0 15px; */
    /* margin: 0rem 0rem 0rem -0.8rem; */
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    /* right: 0; */
    object-fit: cover;
  }
  
  .sgigsguest-theTitle {
    width: 100%;
    /* height: 1.188rem; */
    /* margin: 0.938rem 8.75rem 0rem 0rem; */
    /* font-family: SFProText; */
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1e1e1e;
  }
  .sgigsguest-theFacility {
    /* font-size: 0.8rem;
    font-weight: 0;
    color: grey; */
    /* width: 18.75rem; */
    width: 100%;
    /* height: 1.063rem; */
    margin: 0.625rem 13.625rem 0rem 0rem;
    /* font-family: SFProText; */
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4e4e4e;
    /* margin: 0.5rem 0; */
  }
  
  .sgigsguest-information {
    display: flex;
    justify-content: space-between;
    margin: 0.75rem 0rem 0rem 0rem;
    align-items: flex-end;
    /* height: 1.1rem; */
  }
  
  .information-left-outer {
    display: flex;
    justify-content: space-between;
  }
  
  .sgigsguest-information .information-left {
    display: flex;
  }
  
  .sgigsguest-information .information-right {
    display: flex;
    flex-direction: row;
    /* width: 1.313rem; */
    height: 1.063rem;
    /* line-height: normal; */
    font-size: 0.875rem;
    align-items: center;
  }
  
  .sgigsguest-information .information-right .sgigsguest-rating {
    color: #ffba08;
    margin: 0 0 0 0.5rem;
    line-height: normal;
  }
  .sgigsguest-information .information-right .review {
    color: #7e7e7e;
    margin: 0 0 0 5px;
    line-height: normal;
  }
  .sgigsguest-star {
    /* width: 0.875rem;
    height: 0.75rem; */
    width: 1rem;
    height: 1rem;
    flex-grow: 0;
    /* margin: 0.75rem 0.5rem 0 0; */
    object-fit: contain;
    border-radius: 0.031rem;
    color: #ffba08;
  }
  
  .sgigsguest-thecard .ant-rate-star-second {
    color: #9e9e9e;
  }
  
  .sgigsguest-review {
    display: flex;
    /* width: 20rem; */
    font-size: 0.75rem;
    /* font-family: SFProText; */
    margin: -0.188rem 0rem 0rem 0rem;
    color: #7e7e7e;
    justify-content: space-between;
  }
  .sgigsguest-review .posted-on {
    font-size: 0.75rem;
    color: #7e7e7e;
  }
  
  .sgigsguest-Budgets {
    display: flex;
    /* justify-content: baseline; */
    width: 6.5rem;
    height: 1.5rem;
    flex-grow: 0;
    margin: 0 0 0rem;
    /* font-family: SFProText; */
    font-size: 1.25rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #118936;
  }
  
  .sgigsguest-gigcard-interestedBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 1rem 0.625rem;
    /* margin: -0.4rem 0 0rem; */
    /* height: 1.5rem; */
    /* width: 17.5rem; */
  }
  .sgigsguest-div1 {
    /* width: 4.25rem; */
    height: 0.875rem;
    flex-grow: 0;
    margin: 0rem 2.25rem 0 0;
    /* font-family: SFProText; */
    font-size: 0.75rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #7e7e7e;
    padding-top: 0.188rem;
  }
  .sgigsguest-gigcard-interestedBtn .sgigsguest-div2 {
    width: 6rem;
    background-color: var(--primaryColor);
    font-size: 0.9rem;
    color: white;
    border-radius: 0.188rem;
    padding-top: 0.125rem;
  }
  .sgigsguest-div2 {
    cursor: pointer;
  }
  
  .sgigsguest-receiveTime {
    font-size: 0.65rem;
    font-style: italic;
    color: #777;
    padding: 0.5rem 1rem;
    width: max-content;
  }
  .span-icon {
    font-size: 0.95rem;
    /* padding-top: .06rem; */
  }
  .span-item {
    font-size: 0.9rem;
    width: 24rem;
    margin-right: 0.7rem;
  }
  
  .messageIcon {
    display: flex;
    align-items: center;
    margin-top: 0.7rem;
    /* justify-content: center; */
  }
  
  .sgigsguest-IT-Experience{
    font-size: 0.875rem;
    color: #1e1e1e;
    font-weight: normal;
    display: flex;
    align-items: flex-end;
  }
  
  @media screen and (max-width: 700px) {
    .sgigsguest-thecard {
      width: -webkit-fill-available;
    }
    .filters-wrapper .location-button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      align-content: center;
      width: max-content;
    }
    .filters-wrapper .location-button .ant-switch-checked {
      background-color: #118936;
      width: fit-content;
    }
    .location-text {
      /* height: 19px; */
      /* margin: 2px 0 2px 12px; */
      /* font-family: SFProText; */
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      /* line-height: normal; */
      letter-spacing: normal;
      text-align: left;
      color: #3e3e3e;
    }
    .sgigsguest-theTitle {
      width: 100%;
      /* height: 1.188rem; */
      /* margin: 0.938rem 8.75rem 0rem 0rem; */
      /* font-family: SFProText; */
      font-size: 0.875rem !important;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #1e1e1e;
    }
    .sgigsguest-theFacility {
      /* width: 18.75rem; */
      width: 100%;
      height: 1.063rem;
      margin: 0.625rem 13.625rem 0rem 0rem;
      /* font-family: SFProText; */
      font-size: 0.75rem !important;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #4e4e4e;
      /* margin: 0.5rem 0; */
    }
    .sgigsguest-div1 {
      width: 4.25rem;
      height: 0.875rem;
      flex-grow: 0;
      margin: 0rem 2.25rem 0 0;
      /* font-family: SFProText; */
      font-size: 0.625rem !important;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #7e7e7e;
    }
    .sgigsguest-Budgets {
      display: flex;
      width: 6.5rem;
      height: 1.5rem;
      flex-grow: 0;
      margin: 0 0 0rem;
      /* font-family: SFProText; */
      font-size: 1.125rem !important;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #118936;
    }
    .sgigsguest-information .information-right > div {
      margin: 0 0 0 5px;
    }
    .sgigsguest-upAbove .sgigsguest-information .information-right .review {
      color: #7e7e7e !important;
    }
    .sgigsguest-review {
      display: flex;
      /* width: 20rem; */
      /* font-size: 0.625rem !important; */
      /* font-family: SFProText; */
      margin: -0.188rem 0rem 0rem 0rem;
      color: #7e7e7e;
      justify-content: space-between;
    }
    .sgigsguest-star {
      width: 0.75rem !important;
      /* height: 0.75rem !important; */
      flex-grow: 0;
      /* margin: 0.75rem 0.5rem 0 0; */
      object-fit: contain;
      border-radius: 0.031rem;
      color: #ffba08;
    }
    .sgigsguest-information .information-right .sgigsguest-rating {
      color: #ffba08;
      margin: 0 0 0 5px;
      font-size: 0.75rem;
    }
    .sgigsguest-information .information-right .review {
      color: #7e7e7e;
      margin: 0 0 0 5px;
      font-size: 0.75rem;
    }
    .sgigsguest-information .information-right {
      display: flex;
      flex-direction: row;
      width: 1.313rem;
      height: 1.063rem;
      /* line-height: normal; */
      font-size: 0.875rem;
      line-height: normal;
    }
    .sgigsguest-review .posted-on {
      font-size: 0.625rem;
    }
    .landing-page-card{
      width: 4rem !important;
    }
    .GigCard-CompanyNameIT-FacilityNameHC {
      width: 2rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .GigCard-Rating-section-job-g {
      padding-left: 0.9375rem !important;
    }
    .GigCard-CompanyNameIT-FacilityNameHCguest {
      width: 14.25rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .place-Bid-btn {
    background-color: #118936;
    color: #fff;
    width: 100%;
    font-weight: 500;
    line-height: 1.13;
    height: 35px;
    border-radius: 5px !important;
    border: 1px solid #118936;
    cursor: pointer;
    padding: 8px 19px;
    /*margin-left: 2%;*/
    font-size: 16px;
  }
  
  @media screen and (max-width: 375px) {
    .GigCard-Rating-section-job-g {
      padding-left: 0rem;
    }
    .sgigsguest-information .information-right .review {
      font-size: 0.65rem;
    }
  } 
  