.ProfileCompletionParentContainer{
    width: 100%;
    display: flex;
    justify-content: center;
}
.ProfileCompletionContainer{
    width: 50%;
    /* background-color: aquamarine; */
}
.Profile-Text{
    color: #7e7e7e;
    font-weight: 800;
    font-size: 1.5rem;
}
.completionPercentage{
    margin-top: 1rem;
    background-color: #eff1f3;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    overflow: hidden;
}
.completion-progress {
    height: 100%;
    background-color: green;
    border-radius: 5px;
}
@media screen and (max-width: 768px) {
    .ProfileCompletionContainer{
        width: 90%;
    }
    .Profile-Text{
        color: #7e7e7e;
        font-weight: 800;
        font-size: 14px;
    }
}