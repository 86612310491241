.company-profile-job-container {
    height: auto;
    width: 100%;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    flex-direction: column;
}

.company-profile-job-container .company-profile-job-gigcard {
    display: grid;
    margin: 0 auto;
    flex-wrap: wrap;
    list-style: none;
    width: 100%;
    flex: 0 0 33.333333%;
    font-size: 1.5em;
    margin: 0rem 0 0 0;
    gap: 0.938rem;
    grid-template-columns: 1fr 1fr 1fr;
}

.company-profile-job-container .gig-loader {
    display: flex !important;
    width: 100%;
    height: 40vh;
    justify-content: center !important;
    font-size: 3rem !important;
    align-items: center !important;
}

.company-profile-job-container .company-profile-job-pagination {
    display: flex !important;
    width: 100%;
    justify-content: center !important;
    font-size: 3rem !important;
    margin: 10vh 0 0 0;
    align-items: center !important;
}

.company-profile-job-title-cont {
    display: flex;
}
.company-profile-job-title {
    color: #1E1E1E;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.company-profile-job-count-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30px;
    height: 20px;
    padding: 0 5px;
    flex-grow: 0;
    margin: 0 0 0 10px;
    border-radius: 12px;
    background-color: rgba(64, 84, 178, 0.2);
}

.company-profile-job-count {
    flex-grow: 0;
    font-family: SFProText;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4054b2;
    text-align: center;
    
}

.Company-profile-no-job-container {
    margin-top: 0.625rem;
    padding: 5.1875rem 0rem 5.3125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-gap: 3.95rem;
    gap: 2.5rem;
    background-color: #fff;
}

.Company-profile-no-job-container .Company-profile-no-job-text {
    display: flex;
    flex-direction: column;
    grid-gap: 0.9375rem;
    gap: 0.9375rem;
}

.Company-profile-no-job-container .no-job-image-icon {
   text-align: center;
}

.Company-profile-no-job-container .no-job-image-icon img {
    width: 60%;
}

.Company-profile-no-job-text .No-data {
    font-size: 1rem;
    font-weight: normal;
    color: #1e1e1e;
}

@media screen and (max-width: 768px) {
    .company-profile-job-container {
        height: 100%;
        width: 100%;
        justify-content: center;
        padding: 0.625rem 0.9375rem;
        background: #fff;
    }

    .company-profile-job-container .company-profile-job-gigcard {
        width: auto;
        padding: 0px 0rem;
        border-radius: 2px;
        display: flex;
    }
    
    .Company-profile-no-job-container .no-job-image-icon img {
        width: 70%;
    }
    .Company-profile-no-job-text .No-data {
        font-size: 0.875rem;
    }
}