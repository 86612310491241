.bidAcceptedBtn{
    background-color: #118936;
    height: 36px;
    text-align: center;
    display: flex;
    align-items: center;   
    border: none;
    color: #fff;
    padding: 0% 3%;
    border-radius: 3px;
    justify-content: center;
    width: 12rem;
}
.Accepted,
.Profile_Submitted {
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #118936;
}

.Accepted_Icon,
.Accepted,
.congratulation,
.acceptContainer,
.accept {
  text-align: center;
}
.reason-container{
    background-color: #fff;
    padding: 4%;
    margin-top: 2%;
    border-radius: 4px;
}
.congratulation-text {
    font-size: 1rem;
    font-weight: bold;
}