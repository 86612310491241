.license-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem; 
}

.license-inner-container {
    width: 50%;
}

.license-title {
    color: #212121;
    font-weight: bolder;
    font-size: 1.5rem;
    margin-right: 1.5rem;
}

.mandatory-text {
    color: #7E7E7E;
    margin-left: 0.7rem;
    margin-top: 10px;
    font-size: 0.625rem;
    font-weight: 400;
    position: absolute;
}

@media screen and (max-width: 768px) {
    .license-inner-container{
        width: 90%;
    }
}


