.socialAccountsBox {
  /* width: 21.25rem; */
  width: 21.25rem;
  height: 9.81rem;
  flex-grow: 0;
  /* margin: 0 0.06rem 0 0;
  padding: 1.25rem 10.31rem 1.63rem 1.25rem; */
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
}

.socialAccountsBox.itbox {
  height: 13.81rem;
}

.socialAccountsBox .linkedAccountsTitle {
  height: 3.438rem;
  border-bottom: 0.06rem solid #d4d4d4;
  padding: 1.25rem 1.25rem 0.875rem;

  /* font */
  font-size: 1.13rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.socialAccountsBox .linkedAccounts {
  padding: 0.938rem 1.25rem 1.625rem;
  height: 6.313rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
}

.linkedAccounts .socialA {
  display: flex;
  align-items: center;
  color: var(--primaryColor-blue);
  width: fit-content;
  cursor: pointer;
}

.linkedAccounts .socialAccountWrap {
  display: flex;
  justify-content: space-between;
}

.socialAccountWrap .socialActive {
  display: flex;
  align-items: center;
  color: #3e3e3e;
}

.socialAccountWrap .socialActive span {
  margin-left: 10px;

  /* font */
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.socialAccountWrap .socialActive .linkedIN {
  width: 1.19rem;
  height: 1.19rem;
  padding-left: 1.5px;
  color: #fff;
  background-color: #3e3e3e;
  border-radius: 50%;
  font-size: 1rem;
}

.socialAccountWrap .socialActive .github {
  width: 1.19rem;
  height: 1.19rem;
  border-radius: 50%;
  font-size: 1.15rem;
}

.socialAccountWrap .socialActive .stackoverflow {
  width: 1.19rem;
  height: 1.19rem;
  padding-left: 1.5px;
  color: #fff !important;
  background-color: #3e3e3e;
  border-radius: 50%;
  font-size: 0.8rem;
  text-align: center;
}

.socialAccountWrap .editDelete {
  display: flex;
  justify-content: space-between;
  width: unset;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}

.socialAccountWrap .editDelete img {
  cursor: pointer;
}

.socialA > span {
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--primaryColor-blue);
  margin-left: 0.625rem;
}

.linkedAccountsModal .ant-modal-content {
  width: 26.88rem;
  height: 10.69rem;
  padding: 0.94rem 0.94rem 1.25rem;
  border-radius: 0.31rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
}

.linkedAccountsModal .linkedAccountsModalContent {
  display: flex;
  flex-direction: column;
}

.linkedAccountsModalContent .add-link {
  font-size: 0.88rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.linkedAccountsModalContent input {
  width: 25rem;
  height: 2.56rem;
  flex-grow: 0;
  margin: 0.94rem 0 1.31rem;
  padding: 0.75rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
}

.linkedAccountsModalContent input:focus {
  outline: none;
}

.linkedAccountsModalContent input::placeholder {
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9e9e9e;
}

.linkedAccountsModalContent .save {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.linkedAccountsModalContent .save button {
  width: 12.5rem;
  height: 2.63rem;
  /* margin: 1.31rem 0 0 8.75rem; */
  text-align: center;
  border-radius: 0.25rem;
  background-color: var(--primaryColor);

  /* font */
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  cursor: pointer;

  border: none;
}

.linkedAccountsModal .disableSocialAcc {
  background-color: #88c49b !important;
  cursor: not-allowed !important;
}

.linkedAccounts .socialLinkInputs {
  width: 100%;
  height: 2.44rem;
  margin: 0 0 1.81rem;
  padding: 0.63rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border: none;
}

.linkedAccounts .socialLinkInputs:focus {
  outline: none;
}
@media only screen and (max-width: 768px) {
  .socialAccountsBox {
    /* width: 21.25rem; */
    width: auto;
  }
  .socialAccountsBox .linkedAccountsTitle {
    font-size: 0.875rem;
    border-bottom: none;
  }
  .socialA > span {
    font-weight: 600;
  }
  .linkedAccountsModalContent input {
    width:auto !important ;
  }
  .linkedAccountsModal .ant-modal-content {
    width:auto !important ;
  }
}
