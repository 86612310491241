.skillChart {
  position: relative;
  min-height: 28vw;
}
.skillChart .skill-0 {
  width: 18.5vw;
  height: 18.5vw;
  border-radius: 50%;
  position: absolute;
  /* background-color: #5bd3e3; */
  background-color: #D44747;
  top: 13vw;
  left: 41vw;
  /* top: 50%;
    left: 51%; */
  transform: translate(-50%, -50%);
  box-shadow: -5px -5px 0 rgba(0, 0, 0, 0.1) inset;
  display: table;
  overflow: hidden;
  cursor: text;
  padding: 0px 10px;
}
.skillChart .skill-0 .name {
  color: #fff;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-weight: 500;
}
.skillChart .skill-1 {
  width: 15.62vw;
  height: 15.62vw;
  border-radius: 50%;
  position: absolute;
  background-color: #e1723d;
  top: 1vw;
  left: 48vw;
  /* top: 14%;
    left: 58%; */
  box-shadow: -5px -5px 0 rgba(0, 0, 0, 0.1) inset;
  display: table;
  overflow: hidden;
  cursor: text;
}
.skillChart .skill-1 .name {
  color: #fff;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-weight: 500;
}
.skillChart .skill-2 {
  width: 13.84vw;
  height: 13.84vw;
  border-radius: 50%;
  position: absolute;
  background-color: #f4cb5b;
  top: 1vw;
  left: 22vw;
  /* top: 12%;
    left: 29%; */
  box-shadow: -5px -5px 0 rgba(0, 0, 0, 0.1) inset;
  display: table;
  overflow: hidden;
  cursor: text;
  padding: 0px 10px;
}
.skillChart .skill-2 .name {
  color: #fff;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-weight: 500;
}
.skillChart .skill-3 {
  width: 13.0625vw;
  height: 13.0625vw;
  border-radius: 50%;
  position: absolute;
  background-color: #5bd3e3;
  top: 8vw;
  left: 58vw;
  /* top: 34%;
    left: 70%; */
  box-shadow: -5px -5px 0 rgba(0, 0, 0, 0.1) inset;
  display: table;
  overflow: hidden;
  cursor: text;
  padding: 0px 10px;
}
.skillChart .skill-3 .name {
  color: #fff;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-weight: 500;
}
.skillChart .skill-4 {
  width: 12.28vw;
  height: 12.28vw;
  border-radius: 50%;
  position: absolute;
  background-color: #26ad85;
  top: 12vw;
  left: 48vw;
  /* top: 50%;
    left: 58%; */
  box-shadow: -0.3125rem -0.3125rem 0 rgba(0, 0, 0, 0.1) inset;
  display: table;
  overflow: hidden;
  cursor: text;
  padding: 0px 14px;
}
.skillChart .skill-4 .name {
  color: #fff;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-weight: 500;
}
.skillChart .skill-5 {
  width: 10.71vw;
  height: 10.71vw;
  border-radius: 50%;
  position: absolute;
  background-color: #d61195;
  top: 11vw;
  left: 23vw;
  /* top: 45%;
    left: 29%; */
  box-shadow: -0.3125rem -0.3125rem 0 rgba(0, 0, 0, 0.1) inset;
  display: table;
  overflow: hidden;
  cursor: text;
}
.skillChart .skill-5 .name {
  color: #fff;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-weight: 500;
}
.skillChart .skill-6 {
  width: 9.93vw;
  height: 9.93vw;
  border-radius: 50%;
  position: absolute;
  background-color: #8166ad;
  top: 8vw;
  left: 15vw;
  /* top: 34%;
    left: 21%; */
  box-shadow: -0.3125rem -0.3125rem 0 rgba(0, 0, 0, 0.1) inset;
  display: table;
  overflow: hidden;
  cursor: text;
}
.skillChart .skill-6 .name {
  color: #fff;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-weight: 500;
}
.skillChart .skill-7 {
  width: 9.15vw;
  height: 9.15vw;
  border-radius: 50%;
  position: absolute;
  background-color: #81d065;
  top: 17vw;
  left: 28vw;
  /* top: 63%;
    left: 35%; */
  box-shadow: -0.3125rem -0.3125rem 0 rgba(0, 0, 0, 0.1) inset;
  display: table;
  overflow: hidden;
  cursor: text;
}
.skillChart .skill-7 .name {
  color: #fff;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-weight: 500;
}
.skillChart .skill-8 {
  width: 8vw;
  height: 8vw;
  border-radius: 50%;
  position: absolute;
  background-color: #524280;
  top: 2vw;
  left: 61vw;
  /* top: 17%;
    left: 76%; */
  box-shadow: -0.3125rem -0.3125rem 0 rgba(0, 0, 0, 0.1) inset;
  display: table;
  overflow: hidden;
  cursor: text;
}
.skillChart .skill-8 .name {
  color: #fff;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-weight: 500;
}
.skillChart .skill-9 {
  width: 8vw;
  height: 8vw;
  border-radius: 50%;
  position: absolute;
  background-color: #138195;
  top: 1.5vw;
  left: 16vw;
  /* top: 17%;
    left: 21%; */
  box-shadow: -0.3125rem -0.3125rem 0 rgba(0, 0, 0, 0.1) inset;
  display: table;
  overflow: hidden;
  cursor: text;
}
.skillChart .skill-9 .name {
  color: #fff;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-weight: 500;
}
.name p {
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-size: 0.75rem;
}

/* @media only screen and (min-width: 767px) and (max-width: 991px) {
  .skillChart .skill-7 {
    top: -3%;
    left: 65%;
  }
  .skillChart .skill-5 {
    top: 5%;
  }
  .skillChart .skill-3 {
    top: 46%;
  }
  .skillChart .skill-8 {
    top: 67%;
    left: 34%;
  }
} */

@media only screen and (max-width: 767px) {
  .skillChart {
    min-height: 65vw;
  }
  .skillChart .skill-0 {
    width: 34vw;
    height: 34vw;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    padding: 0.1875rem;
  }
  .skillChart .skill-1 {
    width: 31vw;
    height: 31vw;
    border-radius: 50%;
    top: 12%;
    left: 3%;
    padding: 0.1875rem;
  }
  .skillChart .skill-2 {
    width: 31vw;
    height: 31vw;
    border-radius: 50%;
    top: 14%;
    left: 62%;
    padding: 0.1875rem;
  }
  .skillChart .skill-3 {
    width: 27vw;
    height: 27vw;
    border-radius: 50%;
    top: 48%;
    left: 10%;
    padding: 0.1875rem;
  }
  .skillChart .skill-4 {
    width: 25vw;
    height: 25vw;
    border-radius: 50%;
    top: 51%;
    left: 63%;
    padding: 0.1875rem;
  }
  .skillChart .skill-5 {
    width: 20vw;
    height: 20vw;
    border-radius: 50%;
    top: 3%;
    left: 28%;
    padding: 0.1875rem;
    z-index: 12 !important;
  }
  .skillChart .skill-6 {
    width: 20vw;
    height: 20vw;
    border-radius: 50%;
    top: 3%;
    left: 47%;
    padding: 0.1875rem;
    z-index: 13 !important;
  }
  .skillChart .skill-7 {
    width: 18vw;
    height: 18vw;
    border-radius: 50%;
    top: -3%;
    left: 67%;
    padding: 0.1875rem;
  }
  .skillChart .skill-8 {
    width: 18vw;
    height: 18vw;
    border-radius: 50%;
    top: 68%;
    left: 33%;
    padding: 0.1875rem;
  }
  .skillChart .skill-9 {
    width: 18vw;
    height: 18vw;
    border-radius: 50%;
    top: 67%;
    left: 51%;
    z-index: 15 !important;
    padding: 0.1875rem;
  }

  .name p {
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    font-size: 10px;
    padding: 0.125rem 0.1875rem 0rem;
  }
}

.skillChart .skill-0 {
  z-index: 10;
}
.skillChart .skill-1 {
  z-index: 9;
}
.skillChart .skill-2 {
  z-index: 8;
}
.skillChart .skill-3 {
  z-index: 10;
}
.skillChart .skill-4 {
  z-index: 9;
}
.skillChart .skill-5 {
  z-index: 8;
}
.skillChart .skill-6 {
  z-index: 4;
}
.skillChart .skill-7 {
  z-index: 9;
}
.skillChart .skill-8 {
  z-index: 11;
}
.skillChart .skill-9 {
  z-index: 1;
}
