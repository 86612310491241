/* App.css */

 
  
  .header-edu {
    text-align: center;
    color: #1E1E1E;
    font-size: 28px;
 margin-top: 50px;
font-weight: 600;
line-height: 42px; /* 150% */
  }
  
  .underlinee {
    max-width: 185px;
    height: 5px;
    background-color: #118936;
    margin: 0 auto;
  }
  
  .boxes-containerrr {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .boxes-containerrr-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding: 0px 20px 0px 30px;
  }
  
  .boxes-roww {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 84%;
    margin-top: 46px;
    gap: 65px;
    padding: 0px 50px 0px 50px;
  }

  .boxes-roww-2 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
    gap: 65px;
    margin-top: 66px;
  }
  
  .centeredd {
    justify-content: center;
  }

  .boxes-roww.edu-screen-three {
    justify-content: space-around;
  }
  
  .box-2 {
    width: calc(33.33% + 57px); /* 3 boxes per row */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .underline-red {
    text-decoration: underline #4054B2;
  }
  
  .background-photo{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../../assets/icons/Edu-pic-1.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 101px;
    height: 101px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  .logo-view{
    display: flex;
    flex-direction: column;
    align-items: center;
}
  .inner-photo{
    position: relative;
    top: 26px;
  }
  
  .box-headerr {
    font-size: 16px;
    font-weight: 700;
    color:#1E1E1E;
  }
  
  .subheaderr {
    font-size: 0.875rem;
    font-weight: 400;
    color:#1E1E1E;
  }

  @media screen and (max-width: 1000px) {
    .header-edu {
        margin-bottom: 6px;
        line-height: 21px;
        font-size: 16px;
        margin-top: 25px;
        font-weight: 600;
        padding: 0px 30px 0px 30px;
        text-align: center;
    }

    .boxes-roww {
        display: flex;
       flex-direction: column;
 
        margin-bottom: .96px;
        margin-top: 39px;
        gap: 28.96px;
       align-items: center;
       padding: 0px 10px 0px 10px;
      }
    
      .boxes-roww-2 {
        display: flex;
        flex-direction: column;
        gap: 28.96px;
        align-items: center;
        margin-top: 28.96px;
      }

      .boxes-containerrr {
        margin-top: 0px;
        align-items: center;
      }

      .boxes-containerrr-1 {
        margin-top: 0px;
        align-items: center;
        padding: 0px 15px 0px 15px;
      }
      .inner-photo{
        max-width: 35.634px;
        max-height: 35.634px;
        top: 12px;
      }

      .background-photo{
         
        max-width: 61px;
       max-height: 61px;
        
         
      }

      .box-headerr {
        font-size: 12px;
        font-weight: 600;
         
      }
      
      .subheaderr {
        
        font-size: 0.75rem;
        font-weight: 400;
        
       
      }
      
      .box-2{
        width:100%;
      }
      
  }