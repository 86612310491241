.tooltip-div {
	display: flex;
	justify-content: space-evenly;
}
.sg-tooltip {
	position: fixed;
	color: black;
}
/* .sg-tooltip > .ant-tooltip-arrow {
	right: 16px;
} */
.sg-tooltip .ant-popover-title {
	padding: 13px;
}
.sg-tooltip .ant-popover-inner-content{
	padding: 0px;
}
.sg-tooltip .ant-popover{
	left: 639px;
    top: 18px;
}
.sg-tooltip .ant-popover-content{
	left: 13px !important;
    top: -13px !important;
}
.SGtext {
	color: black;
	border-bottom: 2px solid #d9d9d9;
	width: 238px;
	font-weight: 600;
	font-size: 16px;
}
.experience-text {
	font-size: 10px;
	font-weight: 400;
	color: #5e5e5e;
    padding-top: 6px;
}
.SG-icons{
	width: 100%;
	display: flex;
    position: relative;
    bottom: 10px;
}