.create-skill-listing-parent {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  /* padding-bottom: 200px; */
  background-color: white;
}

.create-skill-klisting-child {
  width: 57.8%;
}

.choose-listilng-heading {
  color: #7e7e7e;
  font-size: 24px;
  font-style: normal;
  line-height: 40px;
  padding-bottom: 40px;
}

.back-btn-bt {
  color: #7e7e7e;
  font-size: 14px;
  /* font-family: Noto Sans; */
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding-top: 28px;
  cursor: pointer;
}
.create-skill-listing-title-subheader {
  margin-top: 0.7rem;
  width: 284px;
}
.create-skill-listing-title-subheader span{
  margin-top: 0.5rem;
  font-feature-settings: 'clig' off, 'liga';
  font-weight: 400;
  color: #3E3E3E;
  font-size: 0.875rem;
  font-style: normal;
}
.create-skill-listing-subheader-other {
  margin-top: 0.7rem;
}
.create-skill-listing-subheader-other span{
  margin-top: 0.5rem;
  font-feature-settings: 'clig' off, 'liga';
  font-weight: 400;
  color: #3E3E3E;
  font-size: 1rem;
  font-style: normal;
}
.create-skill-listing-title {
  color: #212121;
  font-size: 1.5rem;
  /* font-family: Noto Sans; */
  font-style: normal;
  font-weight: 800;
  line-height: 28px;
  padding-top: 26px;
}

.skill-create-name {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #212121;
  cursor: pointer;
}

.CreateSkillListingHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.CreateSkillListing-text {
  display: flex;
  flex-direction: column;
  justify-content: left;
  max-width: 390px;
}

.SkillListing-des {
  margin: 10px 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  /* width: 390px; */
}

.SkillListing-benefit-des {
  margin: 8px 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}

.create-Benefits {
  margin: 30px 0;
}

.choose-skill-location {
  color: #4e4e4e;
  font-size: 14px;
  /* font-family: SF Pro Text; */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.desired-pay {
  color: #1e1e1e;
  font-size: 14px;
  /* font-family: SF Pro Text; */
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.choose-filter-content {
  color: #3e3e3e;
  font-size: 14px;
  /* font-family: SF Pro Text; */
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  border-radius: 2px;
  background: rgba(17, 137, 54, 0.1);

  display: inline-flex;
  padding: 2px 4px;
  align-items: flex-start;
  gap: 10px;
}

.choose-days-ago {
  color: #7e7e7e;
  text-align: right;
  font-size: 12px;
  /* font-family: SF Pro Text; */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.choose-skill-listing-card {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  background: #fff;
  width: 390px;
  padding: 17px 10px 30px;
  cursor: pointer;
}

.create-skl-lst-card-cnt {
  display: flex;
  flex-direction: column;
  padding-top: 37px;
  gap: 30px;
}

.skillcreate-name-radio-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.next-btn-create {
  display: flex;
  padding-top: 70px;
  /* justify-content: center; */
}
.next-btn-create-page1 {
  padding-top: 70px;
  justify-content: center;
  width: 100%;
}

.next-btn-create-cs {
  display: flex;
  width: 180px;
  height: 50px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #118936;
  color: #ffffff;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  cursor: pointer;
}

.progres-bar {
  padding-top: 40px;
}

.error-msg-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding-top: 20px;
}

.error-text {
  color: #db2828;
  font-size: 14px;
  /* font-family: SF Pro Text; */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #9d9d9d !important;
  background-color: white !important;
}
.ant-radio-inner::after {
  background-color: #118936; /* Replace with your desired color */
}
.not-validate {
  border: 1px solid #db2828;
}

.validate-msg {
  border: 1px solid #118936;
}

.gig-type-Tooltip{
  margin-left: 5px;
  margin-top: 4px;
  color: #7e7e7e;
  font-size: 0.770rem;
  font-weight: 400;
  line-height: 8px;
  letter-spacing: 0em;
}

.Mandatory-Text {
  margin-left: 20px;
  color: #7e7e7e;
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 8px;
  letter-spacing: 0em;
  text-align: left;
  vertical-align: middle;
}

.create-skill-listing-buttons {
  display: flex;
  margin-top: 100px;
}
.create-skill-listing-buttons.bottom-gap.isTitlePage {
  margin-top: 100px !important;
}

.create-skill-listing-buttons .back-button {
  order: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: white;
  width: 10.625rem;
  height: 3.125rem;
  border-radius: 4px;
  border: 1px solid #1e1e1e;
  font-weight: 600;
  font-size: 1rem;
}

.create-skill-listing-buttons .back-button:hover {
  /* color: #6b6b6b; */
  border: 1px solid #6b6b6b;
}

.create-skill-listing-buttons .next-button {
  order: 2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: black;
  width: 10.625rem;
  height: 3.125rem;
  border-radius: 4px;
  border: 0.5px solid #1e1e1e;
  margin-left: 20px;
  color: white;
  font-weight: 600;
  font-size: 1rem;
}

.create-skill-listing-buttons .skip-button {
  order: 2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  width: 10.625rem;
  height: 3.125rem;
  border-radius: 4px;
  margin-left: 20px;
  font-weight: 600;
  font-size: 1rem;
}

.create-skill-listing-buttons .next-button:hover {
  border: 0.5px solid #1e1e1e;
  background:#333333 ;
  color: white;
}

.CreateSkillListing-tip-cont {
  margin-top: 1.5rem;
}

@media screen and (max-width: 768px) {
  .CreateSkillListingHeader {
    display: flex;
    flex-direction: column;
  }

  .create-skill-listing-parent {
    display: flex;
    justify-content: flex-start;
    padding: 30px 26px 0px 26px;
    padding-bottom: 200px;
    background-color: white;
  }

  .CreateSkillListing-text {
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin-top: 65px;
  }

  .Mandatory-Text {
    margin-left: 17px;
    color: #7e7e7e;
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 8px;
    letter-spacing: 0em;
    text-align: left;
    vertical-align: middle;
    margin-top: 0.0625rem;
  }

  .create-skill-klisting-child {
    width: 100%;
  }

  .choose-listilng-heading {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .next-btn-cs {
    display: flex;
    width: 162px;
    justify-content: center;
    align-items: center;

    line-height: 18px;
    font-size: 16px;

    height: 44px;
  }

  .create-skl-lst-card-cnt {
    flex-direction: column;
    padding-top: 25px;
    gap: 20px;
  }

  .choose-skill-listing-card {
    width: 100%;
  }

  .create-skill-listing-title {
    display: flex;
    align-items: center;
    font-size: 1rem;
    padding-top: 20px;
  }

  .skill-slg-name {
    font-size: 14px;
  }

  .choose-skill-location {
    font-size: 12px;
  }

  .choose-skill-klisting-child {
    width: 92%;
  }

  .back-btn-bt {
    padding-top: 32px;
    font-size: 10px;
    line-height: 22px;
  }

  .next-btn-create {
    padding-top: 100px;
    justify-content: center;
    padding-bottom: 100px;
    width: 100%;
  }
  .next-btn-create-page1 {
    padding-top: 45px;
    justify-content: center;
    width: 100%;
  }
  .next-btn-create-cs {
    display: flex;
    width: 100%;
    height: 50px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #118936;
    color: #ffffff;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    cursor: pointer;
  }
  .progres-bar {
    padding-top: 20px;
  }

  .error-text {
    font-size: 12px;
  }

  .error-msg-content {
    padding-top: 23px;
  }

  .error-msg-content > img {
    width: 18px;
    height: 18px;
  }
  .create-skill-listing-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    gap: 1.25rem;
  }

  .create-skill-listing-buttons.bottom-gap {
    margin-top: 10rem;
    gap: 1.25rem;
  }

  .create-skill-listing-buttons .back-button {
    order: 1;
    width: auto;
    /* margin-top: 20px; */
    height: 2.5rem;
    font-size: 0.75rem;
    font-weight: 600;
  }

  .create-skill-listing-buttons .next-button {
    order: 0;
    width: auto;
    margin-left: 0;
    height: 2.5rem;
    font-size: 0.75rem;
    font-weight: 600;
    color: white;
  }
  .create-skill-listing-buttons .skip-button {
    order: 2;
    width: auto;
    margin-left: 0;
    height: 2.5rem;
    font-size: 0.75rem;
    font-weight: 600;
  }
  .skillcreate-name-radio-btn .skill-create-name {
    font-size: 1rem;
    font-weight: 400;
  }
  .CreateSkillListing-tip-cont {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }
  .create-skill-listing-title-subheader {
    margin-top: 0.5rem;
  }
  .create-skill-listing-subheader-other {
    margin-top: 0.5rem;
  }
  .create-skill-listing-subheader-other span{
    font-size: 0.875rem;
  }
}
