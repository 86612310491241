.bhsFltSortContainer .dropdownBox {
  flex: 1;
  position: relative;
}

.bhsFltSortContainer .dropdownBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.4375rem 0.625rem 0.5rem;
  border-radius: 0.25rem;
  border: solid 0.0625rem #e4e4e4;
  background-color: #fff;
  cursor: pointer;
}

.bhsFltSortContainer .dropdownBtn > span {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.bhsFltSortContainer .dropdownOption {
  display: none;
  max-height: 50vh;
  overflow-y: auto;
  width: 100%;
  position: absolute;
  top: 3.125rem;
  left: 0rem;
  border-radius: 0.25rem;
  border: solid 0.0625rem #e4e4e4;
  background-color: #fff;
}

.bhsFltSortContainer .dropdownOption::-webkit-scrollbar {
  width: 0.4375rem;
}

.bhsFltSortContainer .dropdownOption::-webkit-scrollbar-thumb {
  background: #e4e4e4;
  border-radius: 0.75rem !important;
  cursor: pointer !important;
}

.bhsFltSortContainer .dropdownOption::-webkit-scrollbar-track {
  background: #fff;
}

.bhsFltSortContainer .dropdownOptionVisible {
  display: block;
  z-index: 1;
}

.bhsFltSortContainer .dropdownOption .dropdownItem {
  padding: 0.3125rem 0.625rem;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
  cursor: pointer;
}

.bhsFltSortContainer .dropdownOption .dropdownItem:hover {
  background-color: #e4e4e4;
}

/* .Divider-Line-Bid_history {
  display: none;
} */

/********** For Mobile View  Media Query Start **********/
@media screen and (max-width: 768px) {
  .bhsFltSortContainer .dropdownBox {
    width: 100%;
  }

  .bhsFltSortContainer .dropdownBtn {
    height: 40px;
  }

  .bhsFltSortContainer .dropdownBtn > span {
    font-size: 0.875rem;
  }

  .bhsFltSortContainer .dropdownBtn > img {
    margin-right: -2px;
  }

  .Bid-history-Drawer .dropdownItem-filter {
    padding: .5rem 0 .625rem 0rem;
  }

  .Bid-history-Drawer .ant-drawer-body {
    padding: 0 .9375rem .9375rem;
    font-size: .875rem;
    font-weight: normal;
    color: #3e3e3e;
  }

  .Bid-history-Drawer .ant-drawer-title {
    flex: none;
    color: #3e3e3e;
    font-size: 1rem;
    font-weight: 500;
  }

  .Bid-history-Drawer .ant-drawer-header-title {
    flex-direction: row-reverse;
  }

  .Bid-history-Drawer  .ant-drawer-close {
    margin-right: unset;
  }

  .Sort-Button-Bid-history {
    position: absolute;
    bottom: .9375rem;
    left: 1.25rem;
    right: 1.25rem;
  }

  .Bid-history-Drawer  .ant-drawer-header {
    border-bottom: .0625rem solid #e4e4e4;
    padding: .9375rem 1.25rem;
  }

  .Bid-history-Drawer .Bid-History-divider {
    position: absolute;
    left: 0;
    bottom: 3.125rem;
    box-shadow: 0 0 .25rem 0 rgba(0, 0, 0, 0.25);
  }

  .Divider-Line-Bid_history {
    border-top: .0625rem solid #e4e4e4;
    padding-top: .9375rem !important;
    margin-top: .375rem;
  }
}
/********** For Mobile View  Media Query End **********/
