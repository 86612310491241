.main-text {
  text-align: center;
  padding-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.welcome-text {
  width: 466px;
  height: 468px;
  top: 110px;
  left: 407px;
  gap: 0px;
}
.texts {
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  text-align: center;
}
.main-sub {
  width: 131px;
  height: 19px;
  top: 228px;
  left: 475px;
  gap: 0px;
}
.verification-text {
  
  font-size: 16px;
  font-weight: 500;
  line-height: 19.09px;
  text-align: left;
  margin-top: 15px;
  width: 100%;
  margin-left: 43%;
}

.otp-container {
  display: flex;

}
.validation-text {
  width: 20rem;
  padding-top: 20px;
  color: #7e7e7e;
}

input.otp-input {
  width: 60rem;
  height: 60px;
  margin: 0 5px;
  font-size: 24px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 8px;
}
.password-validation-text {
  margin-top: 8px;
  font-size: 14px;
}

.ant-form-item-with-help .ant-form-item-explain {
  min-height: 0px !important;
}
.continue-text {
  width: 330px;
  height: 42px;
  top: 493px;
  left: 475px;
  padding: 12px 0px 12px 0px;
  gap: 10px;
  border-radius: 4px 0px 0px 0px;
  background-color: black;
  text-align: center;
  cursor: pointer;
}
.otp-error {
  width: 22rem;
  padding-top: 10px;
}
.continue-btn-text {
  color: white;
  text-align: center;
  margin-top: -3px;
}
.sub-tittle-text{
  display: block;
}
@media (max-width: 786px) {
  .verification-text {
    margin-left: 22%;
  }
  .texts{
    margin-top: -7%;
  }
  .Tittle-text{
    display: block;
  }
  .main-text {
    padding-top: 0px;
  }
}
