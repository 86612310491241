.scs-screen-container {
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  background: #fff;
  max-width: 550px;
  margin: 100px auto 220px auto; /* Center horizontally */
  padding: 30px 20px 31px 20px;
}

.scs-screen-comp-1 {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
 
.scs-screen-comp-head-1 {
  color: #3e3e3e;
  font-size: 18px;
  font-weight: 600;
  padding: 0px;
}
.scs-screen-comp-head-2 {
  color: #3e3e3e;
  font-size: 18px;
  font-weight: 600;
  padding: 0px;
}
.scs-screen-comp-subhead {
  font-family: "Montserrat";
  color: #5e5e5e;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.scs-screen-comp-2 {
  max-width: 100%;
  height: auto;
  flex-shrink: 0;
  margin: 0 auto; /* Center the image horizontally */
}

.scs-screen-comp-3 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center; /* Center buttons horizontally */
}
.scs-screen-comp-3 .ant-btn {
  font-size: 1rem;
}
.scs-screen-comp-4 {
  font-family: "Montserrat";
  color: #3e3e3e;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  margin-top: -20px;
}

.scs-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  gap: 40px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .scs-screen-container {
    max-width: 90%;
    border: none;
    margin: -31px auto 102px auto;
    padding: 0px;
  }
  .scs-screen-comp-1 {
    gap: 8px;
    align-items: center;
  }
  .scs-screen-comp-subhead {
    font-size: 12px;
  }

  .scs-centered {
    margin-top: 28px;
    gap: 30px;
  }

  .scs-screen-comp-head-1 {
    padding: 0px 10px;
  }
  .scs-screen-comp-3 .ant-btn {
    font-size: .75rem;
  }
  .scs-screen-comp-4 {
    margin-top: -10px;
  }
}
