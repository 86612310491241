.ProfessionalExperience-cantainer {
    padding-top: 4.75rem;
    padding-bottom: 4.375rem;
}

.ProfessionalExperience-cantainer .ViewResume-sub-heading-section {
    display: flex;
    justify-content: space-between;
    padding: 2.125rem 0 2.1875rem 0;
    /* padding-top: 0px !important; */
}
.ProfessionalExperience-Responsibilities {
    margin-bottom: 1rem;
}
.ProfessionalExperience-Responsibilities .ViewResume-sub-heading-section {
    padding: 0rem !important;
}

.No_Data_Present {
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    color: #1e1e1e;
    padding-top: 1.25rem;
}

@media screen and (max-width: 480px) {
    .ProfessionalExperience-cantainer {
        padding-top: 2rem;
        padding-bottom: 1.25rem;
    }

    .ProfessionalExperience-cantainer .ViewResume-sub-heading-section {
        padding: .9375rem 0 1.25rem 0;
    }
}