.box-23 {
  width: calc(33.33% + 57px); /* 3 boxes per row */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  text-align: center;
}

.background-photo-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../../assets/icons/EduThreeBg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 101px;
  height: 101px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
}

.logo-view-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.inner-photo-3 {
  position: relative;
  top: 20px;
}

.box-headerr-3 {
  font-size: 16px;
  font-weight: 700;
  color: #1e1e1e;
}

.subheaderr-3 {
  font-size: 16px;
  font-weight: 400;
  color: #1e1e1e;
}

.sub-header-edu-txt-2 {
  font-size: 12px;
  font-weight: 400;
}

@media screen and (max-width: 1000px) {
  .inner-photo-3 {
    max-width: 39.6px;
    max-height: 39.6px;
    top: 12px;
  }

  .background-photo-3 {
    max-width: 61px;
    max-height: 61px;
  }

  .box-headerr-3 {
    font-size: 12px;
    font-weight: 600;
  }

  .subheaderr-3 {
    font-size: 12px;
    font-weight: 400;
  }

  .sub-header-edu-txt-2 {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-top: 30px;
    padding: 0px 30px 0px 30px;
  }
}
