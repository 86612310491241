.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip {
  position: absolute;
  top: 134%; /* Change this to align with the bottom */
  left: 50%;
  width: 17.25rem;
  transform: translateX(-50%);
  padding: 8px;
  background: rgb(221, 238, 254);
  color: #3e3e3e;
  border-radius: .25rem;
  opacity: 0.9;
  font-size: .875rem;
  hyphens: auto;
  display: none;
  white-space: normal; /* Set to normal or break-spaces for text wrapping */
}

.tooltip:before {
  content: '';
  position: absolute;
  bottom: 100%; /* Change this to align with the bottom */
  left: 10%;
  margin-left: -0.3125rem;
  border-width: .3125rem;
  border-style: solid;
  border-color: transparent transparent rgb(221, 238, 254) transparent;
}

.tooltip-container .tooltip {
    display: block;
}

.custom-tooltip-info-box {
    display: flex;
}

.custom-tooltip-icon {
    padding-right: .375rem;
}

.custom-tooltip-close-button {
    padding-left: 80%;
    cursor: pointer;
}