.any-career-gap-signup-main {
  background-color: white;
  height: 100%;
  width: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;
}

.any-career-gap-signup {
  min-height: 30rem;
  width: 50%;
}

.title-any-career-gap {
  color: #212121;
  font-feature-settings: "clig" off, "liga" off;
  /* font-family: SF Pro Text; */
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 1.75rem; /* 116.667% */

  margin-top: 3.75rem;
  margin-bottom: 2.12rem;
}

.any-career-gap-signup .radio-buttons-yes-no {
  display: flex;
  margin-bottom: 3.37rem;
}

.any-career-gap-signup .ant-radio-checked .ant-radio-inner {
  border: 1px solid var(--primaryColor) !important;
}

.any-career-gap-signup .ant-radio-inner::after {
  background-color: var(--primaryColor) !important;
}

.radio-buttons-yes-no .radio-class {
  display: flex;
  align-items: baseline;
  width: 14.375rem;
  padding: 0.6rem;
  border-radius: 0.25rem;
  border: solid 0.06rem #d4d4d4;
}

.any-career-gap-signup .ant-input {
  padding-bottom: 0;
  padding-left: 0;
  color: #1e1e1e;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.any-career-gap-signup textarea.ant-input {
  min-height: 0;
}

.any-career-gap-signup .gap-filler-red {
  background-color: var(--primaryColor-error) !important;
}

.any-career-gap-signup .gap-validation-300 {
  display: flex;
  color: var(--primaryColor-error);
  gap: 0.31rem;
  align-items: center;
  margin-top: 0.6rem;
}

.any-career-gap-signup .bottom-line-gap {
  width: 100%;
  height: 0.0625rem;
  background-color: #dadada;
  margin-top: 0.62rem;
}

@media screen and (max-width: 1000px) {
  .any-career-gap-signup {
    padding: 0 1.5rem;
    width: 100%;
  }

  .title-any-career-gap {
    margin-top: 2.5rem;
    margin-bottom: 1.56rem;

    font-size: 0.875rem;
    font-weight: 500;
  }

  .any-career-gap-signup .radio-group {
    width: 100%;
    display: flex;
    gap: 2.12rem;
    justify-content: space-between;
  }

  .any-career-gap-signup .ant-radio-wrapper {
    margin-right: 0;
  }

  .radio-buttons-yes-no .radio-class {
    width: 100%;
    height: 2.5rem;
    /* flex-direction: row; */
  }
}
