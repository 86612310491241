.tooltip-class {
  font-size: 12px;
  font-weight: 500;
}

.tooltip-class .ant-tooltip-inner {
  color: #3e3e3e !important;
  padding: 5%;
  width: 14.13rem;
}

@media only screen and (max-width: 769px) {
  .tooltip-class .ant-tooltip-inner {
    width: 280px;
    padding: 4%;
  }
}

.yes-text-style {
  display: 'flex';
  flex-direction: 'row-reverse';
  cursor: 'pointer';
  margin-top: '3%';
}