.ProfessionPage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProfessionPage .profession {
  /* width: 24.5781rem; */
  /* height: 21.3rem; */
  padding: 2.5rem;
  background-color: white;
  flex-grow: 0;
  margin: 2.5rem 20.4rem 3rem;
}

.professionHeader {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 1.8rem;
}

.preferenceHeader {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  width: 300px;
  color: #1e1e1e;
}

.SelectedProfessionCode {
  border: .0625rem solid #118936 !important;
}

.preferenceSub {
  margin-top: 0.75rem;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: #3e3e3e;
}

.healthCarePro:hover, .InfoTPro:hover {
  border: solid 1px var(--primaryColor);
  cursor: pointer;
}

.chooseProfession {
  display: flex;
  justify-content: center;
  gap: 37px;
}
.professionSubText {
  font-size: 0.6rem;
  font-weight: 400;
  margin-top: 0.9125rem;
  max-width: 9.9rem;
}
.healthCarePro {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 9.68rem;
  /* height: 9.62rem; */
  flex-grow: 0;
  /* margin: 30px 40px 0 0; */
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  /* border: solid 1px #118936; */
  border: solid 1px transparent;
  background-color: #fff;
}

.healthCarePro img {
  height: 6rem;
}

.InfoTPro {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 9.68rem;
  /* height: 9.62rem; */
  flex-grow: 0;
  /* margin: 30px 40px 0; */
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  border: solid 1px transparent;
}

.InfoTPro img {
  height: 6rem;
}

.ITFreelancerPro {
  display: flex;
  flex-direction: column;
  min-width: 9.68rem;
  height: 9.62rem;
  flex-grow: 0;
  /* margin: 30px 0 0 40px; */
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
}

.ITFreelancerPro img {
  height: 6rem;
}

.professionDivider {
  height: 1px;
  flex-grow: 0;
  margin: 0.5rem 0 0.43rem;
}

.professionText {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
}

.professiontxt {
  margin-top: 1.375rem;
  display: contents;
}

@media screen and (max-width: 768px) {
  .ProfessionPage .profession {
    /* width: 100%; */
    margin-top: -15px;
    padding: 1.25rem 0rem;
  }

  /* .professionDivider { */
    /* display: none; */
  /* } */

  .professionHeader {
    margin-bottom: 1.25rem;
  }

  /* .alignProfession {
    display: flex;
  } */

  .chooseProfession {
    display: flex;
    /* justify-content: unset; */
    /* flex-wrap: wrap;
    padding-left: 0.4rem; */
    /* display: grid; */
    /* grid-column: 0.125rem; */
    /* grid-template-columns: repeat(auto-fill, minmax(9.375rem, 1fr)); */
    /* grid-column-gap: 1.25rem; */
    /* flex-wrap: wrap; */
    /* padding-left: 0.4rem; */
  }

  .healthCarePro {
    margin: 0 0rem 1.25rem 0;
    display: flex;
    /* flex-direction: inherit; */
  }

  .InfoTPro {
    margin: 0 0rem 1.25rem 0;
    display: flex;
    /* flex-direction: inherit; */
  }

  .professiontxt {
    margin-top: 0.375rem;
  }

  .professionSubText {
    font-size: 0.625rem;
    font-weight: 400;
    margin-top: 0.3125rem;
  }

  /* .professionDivider { */
    /* width: -webkit-fill-available; */
  /* } */
}
