.containerStep3 {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 2rem 1.2rem 0;
  --buttonColor: #e73959;
  --borderColor: #e4e4e4;
  --background1: #fafcff;
  --background: #ffffff;
  --activeborderColor: #3a85e2;
}
.outerbox {
  display: flex;
  /* padding: 42px 12px 15px; */
  padding: 2.5rem;
  flex-direction: column;
  align-items: center;
  /* gap: 2.5rem; */
  gap: 2.6875rem;
  background: #fff;
}
.outerbox .ant-modal-body {
  padding: 0;
}
/* .greenTextSkillListing { */
/* color: var(--primaryColor); */
/* } */

.greenTextSkillListingApplyed {
  color: var(--primaryColor);
}

.outerbox .countSkillCombo {
  display: flex;
  flex-direction: column;
}

.noSkillListingView {
  width: 100%;
}

.Frame-20343 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  /* width: 75rem; */
  height: 28.13rem;
  border-radius: 4px;
  border: solid 1px #d4d4d4;
  background-color: #fff;
}
.nodata {
  width: 209px;
  /* height: 40px; */
  margin: 20px 20px 20px 21px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
}

.dropdownClasskilllisting .ant-dropdown-menu {
  width: 22.5rem;
  height: 5.88rem;
  flex-grow: 0;
  padding: 0.94rem 0;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

.dropdownClasskilllisting .ant-dropdown-menu-item:hover {
  background-color: unset;
  color: var(--primaryColor);
}

.dropdownClasskilllisting .filterMenuPUBD {
  padding: 15px 10px;
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.filterIconSkillListing {
  transform: rotate(180deg);
  font-size: 1.2rem;
  margin-bottom: 4px;
}

.clickSkillListinggreen {
  display: flex;
  /* width: 209px; */
  height: 40px;
  /* margin: 20px 11px 0 30px; */
  padding: 10px 10px 11px;
  border-radius: 4px;
  background-color: #118936;
}
.Create-a-Skill-Listing {
  /* width: 161px; */
  height: 19px;
  flex-grow: 0;
  margin: 0 0 0 21px;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.plussign {
  height: 25px;
  flex-grow: 0;
  margin: 0px -13px 0 0;
  object-fit: contain;
}
.Create-a-Skill-Listing2 {
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.plussign2 {
  width: 1.4rem;
  height: 1.4rem;
  flex-grow: 0;
  /* margin: 0.06rem 0.63rem 0 0; */
  object-fit: contain;
}
.CreateImg {
  width: 250px;
  height: 250px;
  object-fit: contain;
}
.outerbox .filterPubDelete {
  width: max-content;
  height: 2.5rem;
  display: flex;
  align-items: center;
  gap: 0.938rem;
  /* margin: 2.5rem 1.25rem 1.31rem 26.69rem; */
  padding: 0.5rem 1.25rem;
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;

  /* font */
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
  cursor: pointer;
}
.filterpublished {
  margin-top: 2px;
}
.filterpublished {
  margin-top: 2px;
}
.setalign {
  margin: 142px 0px 21px 1252px;
}
.eyeOnYou {
  width: 14px;
  height: 14px;
  flex-grow: 0;
  margin: 1px 5px 2px 0;
  object-fit: contain;
}

.outerbox .skillistingContainerHold {
  max-width: 77rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0.938rem;
  /* flex: 1; */
  /* justify-content: space-between; */
  /* padding-top: 1.25rem; */
}

.skillisting-card-outer-box {
  display: flex;
  /* flex: 1; */
}

.singleSkillListing {
  /* max-width: 45rem;
  min-width: 24.38rem; */
  width: 21.88rem;

  height: 10.5rem;
  padding: 0.7rem 0.94rem 0.63rem;
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
}

.singleSkillListingDeleted {
  /* max-width: 45rem;
  min-width: 24.38rem; */
  width: 24.38rem;

  /* height: 10.5rem; */
  padding: 0.7rem 0.94rem 0.63rem;
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
}

.card-inner-box {
  /* max-width: 45rem;
  min-width: 24.38rem;
  width: 100%; */
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  width: 24.38rem;
  /* height: 10.5rem; */
  /* padding: 0.7rem 0.94rem 0.63rem; */
  padding: 0.9375rem 0.625rem;
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
}

.Rectangle-17523deleted {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  max-width: 45rem;
  min-width: 24.375rem;
  width: 100%;

  /* height: 8.25rem; */
  /* margin: -4.5rem 22rem 8.688rem 1.125rem; */
  /* padding: 0.7rem 0.625rem 0.938rem; */
  padding: 0.9375rem 0.625rem;
  border-radius: 0.25rem;
  border: solid 0.063rem #e4e4e4;
  background-color: #fff;
}

.skillisting-card-outer-box .dropdown-title-skilllisting {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  margin-top: 0rem !important;
  height: 1rem;
}

.dropdown-title-skilllisting .anticon-ellipsis {
  color: #7e7e7e;
  font-size: 1.25rem;
}

.skilllistfont {
  display: flex;
  justify-content: space-between;
  /* flex-direction: column; */
}
.skilllistfont0 {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
}

.itemsfont {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.skillfont {
  /* width: 127px;
  height: 19px; */
  /* margin: 0px 259px 0px 0; */
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  height: 20px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.locationfont {
  width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 1.17rem;
  /* margin: 0rem 0 0.438rem 0; */
  font-size: 0.875rem;
  text-align: left;
  color: #4e4e4e;
}
.salaryfont {
  /* width: 181px; */
  height: 17px;
  /* margin: 10px 127px 10px 0; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}
.skillisting-card-outer-box .contain {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.containdeleted {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.containdeleted {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.publishedfontframe {
  width: 73px;
  height: 21px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  /* margin: 0.63rem 0 0.94rem 0; */
  padding: 0.13rem 0.25rem;
  border-radius: 2px;
  background-color: rgba(17, 137, 54, 0.1);
}
.publishedfontframedeleted {
  width: 60px;
  height: 21px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  /* margin: 9px 0px 10px 0; */
  padding: 2px 3px;
  border-radius: 2px;
  background: rgba(110, 110, 110, 0.1);
}
.publishedfontdeleted {
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}
.publishedfont {
  width: 65px;
  height: 17px;
  flex-grow: 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}
.daysfont {
  /* width: 93px; */
  height: 14px;
  /* margin: 6px 0 0px 0px; */
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #7e7e7e;
}

.daysfontdeleted {
  /* width: 93px; */
  height: 14px;
  /* margin: 6px 0 0px 0px; */
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #7e7e7e;
}

.skillfontdeleted {
  /* width: 111px; */
  overflow: hidden;
  height: 17px;
  /* margin: 0 197px 10px 0; */
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #6e6e6e;
}
.locationfontdeleted {
  width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 17px;
  /* margin: 10px 0 0; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #6e6e6e;
}
.salaryfontdeleted {
  height: 17px;
  /* margin: 10px 0 12px 0 !important; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #6e6e6e;
}

.skilllistfont {
  width: 100%;
  max-width: 77rem;
  display: flex;
  justify-content: space-between;
}
.pubcreate {
  display: flex;
  justify-content: space-between;
  width: 26.5%;
}

.skillisting-card-outer-box .manage-skillisting-status {
  /* width: 5.5rem; */
  margin-top: 0.3125rem;
  display: flex;
  align-items: center;
}

.eyeicon {
  /* width: 9px; */
  /* height: 17px; */
  flex-grow: 0;
  margin: 0rem 0rem 0rem 0rem;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #118936;
  cursor: pointer;
  display: flex;
}

.eyeiconviews {
  /* width: 42px; */
  height: 17px;
  flex-grow: 0;
  margin: 0 10px 0 5px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #118936;
  cursor: pointer;
}

.bidfont {
  /* width: 42px; */
  height: 17px;
  flex-grow: 0;
  /* margin: 15px 228px 0 10px; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #118936;
  cursor: pointer;
}
.Published {
  /* width: 75px;
  height: 19px; */
  flex-grow: 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}
.outerbox .ant-select-single ant-select-show-arrow {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 0px 4px !important;
}

/* never update a third party CSS directly, always use a parent class */
/* .ant-dropdown-menu-title-content {
  flex: auto !important;
  width: fit-content !important;
  margin-right: 51px !important;
  margin-bottom: 13px !important;
  padding: 1px 1px 1px 1px !important;
} */
.outerbox .ant-btn-group > .ant-btn:last-child:not(:first-child),
.outerbox .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border: none !important;
  margin-left: 10px !important;
  color: black !important;
}

.skillisting-card-outer-box
  .ant-btn-group
  > .ant-btn:last-child:not(:first-child),
.skillisting-card-outer-box
  .ant-btn-group
  > span:last-child:not(:first-child)
  > .ant-btn {
  border: none !important;
  margin-left: 10px !important;
  color: black !important;
  box-shadow: none;
}

.dotsbutton {
  width: 20px;
  height: 20px;
  margin-top: -7px;
  margin-left: 42px;
  /* margin: 0 0 11px 169px; */
}

.Frame-19924 {
  display: flex;
  width: 209px;
  height: 40px;
  padding: 10px 10px 11px;
  border-radius: 4px;
  background-color: #118936;
  gap: 10px;
  cursor: pointer;
  margin-left: 2.5rem;
}

.collide {
  width: 97.5%;
  max-width: 77rem;
  display: flex;
  justify-content: space-between;
}
.Skill-Listing {
  /* margin: 0px 0px 0px 39px; */
  margin-bottom: 0.3125rem;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.pencilSkilllisting {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  margin: 0 0.5rem 0 0;
}

.headDiscription {
  font-size: medium;
  color: #666666;
}

.inputLabel {
  display: block;
}

.inputCss {
  padding: 0.5rem 0.6rem;
  margin: 0 !important;
  /* padding: 12px 154px 12px 10px; */
  border-radius: 6px;
  border: solid 1px #e4e4e4;
  background-color: #ffffff;
  color: #2e2e2e;
}

.skillListingOne .ant-form-item-label > label {
  display: unset !important;
}

.skillListingTwo {
  display: flex;
  flex-direction: column;
  align-content: space-around;
  padding: 2rem 1.5rem;
}

.skillListingTwo .ant-form-item .ant-form-item-label {
  padding: 0 0 0;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;
}

.skillListingTwo .ant-input-group-addon {
  background-color: lightgray;
}

/* Edit/delete CSS */
.dropdownClassSkillL .ant-dropdown-menu {
  width: 10rem;
  height: 6.06rem;
  flex-grow: 0;
  padding: 0.75rem 0 0.94rem 0.5rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem 0 rgb(0 0 0 / 25%);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dropdownClassSkillL .ant-dropdown-menu-item:hover {
  background-color: unset;
  color: var(--primaryColor);
}

/* Delete Modal */

.modalConfirmDelete .ant-modal-body {
  padding: 0;
  width: 21.875rem;
  /* height: 12.44rem; */
  /* flex-grow: 0; */
  /* padding: 1.00rem 0 1.25rem; */
  /* box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25); */
  background-color: #fff;
}

.modalConfirmDelete .ant-modal-header {
  padding: 16px 15px 15px;
  /* color: rgba(0, 0, 0, 0.85); */
  background: #fff;
  border-bottom: 0.0838rem solid #e4e4e4;
  /* border-radius: 2px 2px 0 0; */
}

.areYouSureSkillisting {
  padding: 0.625rem 0.9375rem 1.25rem;
}

.deleteSkillisting {
  width: 11.88rem;
  height: 1.19rem;
  /* margin: 0 9.06rem 0.94rem 0.94rem; */
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.skillistingDivider {
  /* width: 21.88rem; */
  height: 0.06rem;
  margin: 0.94rem 0 0.63rem;
  background-color: #e4e4e4;
}

.areYouSureSkillisting .confirm {
  width: 17.25rem;
  height: 2.88rem;
  margin: 0.63rem 0 1.88rem 0;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.areYouSureSkillisting .buttons {
  display: flex;
  justify-content: space-between;
}

.areYouSureSkillisting .no {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9.69rem;
  height: 2.63rem;
  flex-grow: 0;
  /* margin: 0 0.63rem 0 0; */
  border-radius: 0.25rem;
  background-color: #fff;
  color: #9e9e9e;
  cursor: pointer;
}

.areYouSureSkillisting .yes {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9.69rem;
  height: 2.63rem;
  flex-grow: 0;
  /* margin: 0 0 0 0.63rem; */
  border-radius: 0.25rem;
  background-color: #118936;
  color: #fff;
  cursor: pointer;
}
.outerbox .createAndFilter {
  display: flex;
  justify-content: end;
  /* gap: 20px; */
  align-items: center;
}

/*Filter Menu */
.outerbox .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: unset;
}

.outerbox .ant-menu-light .ant-menu-item:hover,
.outerbox .ant-menu-light .ant-menu-item-active {
  color: var(--primaryColor);
}

.outerbox .ant-menu-item-selected {
  color: var(--primaryColor);
}

.outerbox .ant-drawer-body {
  padding: 0.2rem 0;
}

.createAndFilter .filterDrawerTitle {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.filterDrawer .ant-drawer-header {
  background-color: #fff;
  border-bottom: 2px solid #e4e4e4;
  padding: 0.9375rem;
}

.filterDrawer .ant-drawer-close {
  top: 1.125rem;
  right: 0.938rem;
  position: absolute;
  margin: 0rem;
}

.filterDrawer button {
  width: 1rem;
}

.modalEditSkillListing .cancelUpdate {
  display: flex;
  justify-content: flex-end;
  padding: 1.25rem 1.875rem;
  border-top: solid 0.06rem #e4e4e4;
}

.outerbox .ant-picker:hover,
.ant-picker-focused {
  border-color: #fff !important;
}
.outerbox .ant-picker-focused {
  border-color: #fff !important;
}
.cancelUpdate .cancel {
  display: grid;
  place-items: center;
  cursor: pointer;
  width: 12.5rem;
  height: 2.63rem;
  margin: 0 0.625rem 0 0;
  border-radius: 0.31rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
  border: solid 0.06rem #118936;
  background-color: #fff;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #118936;
}

.cancelUpdate .Update {
  display: grid;
  place-items: center;
  cursor: pointer;
  width: 12.5rem;
  height: 2.63rem;
  border-radius: 0.31rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.dropdown-title-skilllisting .ant-btn-group {
  margin-right: -0.5rem;
}

.dropdown-title-skilllisting .ant-btn {
  height: unset;
}

@media screen and (min-width: 768px) {
  .skillListingTwo {
    margin: auto 30%;
  }
}
@media screen and (max-width: 768px) {
  .outerbox .createAndFilter {
    justify-content: space-between;
  }

  .outerbox {
    /* padding: 1.125rem 1.45rem 0.938rem; */
    padding: 0.9375rem;
    align-items: unset;
    gap: 1.25rem;
  }
   .skillisting-card-outer-box{
    max-width: 100%;
  } 
  .itemsfont {
    font-size: 0.75rem;
  }

  .outerbox .filterPubDelete {
    height: 1.75rem;
    padding: 0.31rem;
  }

  .outerbox .skillistingContainerHold {
    justify-content: center;
    padding: 0rem;
    gap: 0.625rem;
    max-width: 100%;
  }

  .skillisting-card-outer-box {
    flex: 1;
    display: unset !important;
  }

  .modalEditSkillListing .ant-modal {
    max-width: 100%;
    max-height: 100%;
    padding: unset;
    margin: auto;
  }

  .modalEditSkillListing .ant-modal-body {
    padding: 0 0.9375rem 1.875rem !important;
  }

  .dropdownClassSkillL .ant-dropdown-menu {
    width: 6.25rem;
    height: 4rem;
    font-size: 0.88rem;
    padding: 0.5rem 0.938rem;
    margin-top: 0.9rem;
  }

  .dropdownClassSkillL .ant-dropdown-menu-item {
    padding: 0;
  }

  .Skill-Listing {
    width: 6.563rem;
    font-size: 1rem;
  }
  .skilllistfont {
    display: flex;
    flex-wrap: wrap;
    width: unset;
  }
  .collide {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .Skill-Listing0 {
    width: 5.688rem;
    height: 1.188rem;
    flex-grow: 0;
    margin: 0rem 0rem 0rem 0.75rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #3e3e3e;
  }
  .itemsfont0 {
    margin-left: 0.75rem;
    margin-top: 0.188rem;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #7e7e7e;
  }
  .nodata {
    width: 11.188rem;
    height: 2rem;
    margin: 0rem 5rem 1.875rem 5.688rem;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #1e1e1e;
  }
  .clickSkillListinggreen {
    width: 11.813rem;
    height: 1.75rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.313rem;
    margin: 0rem 4.875rem 1.813rem 5.375rem;
    padding: 0rem 0.563rem;
    border-radius: 0.25rem;
    background-color: #118936;
  }
  .Create-a-Skill-Listing {
    width: 7.375rem;
    height: 1rem;
    margin-top: 0.188rem;
    margin-left: 0.625rem;
    flex-grow: 0;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .Create-a-Skill-Listing2 {
    width: 7.375rem;
    /* height: 1rem; */
    /* margin-top: 0.125rem; */
    /* margin-left: -0.25rem; */
    flex-grow: 0;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .plussign {
    width: 1.438rem;
    height: 1.75rem;
    margin-top: 0.313rem;
    flex-grow: 0;
    object-fit: contain;
  }
  .plussign2 {
    width: 1.438rem;
    height: 1.75rem;
    margin-top: 0.313rem;
    flex-grow: 0;
    object-fit: contain;
  }
  .Frame-20343 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    /* margin: 1.063rem 2.438rem 0rem; */
    padding: 0rem 0rem;
    border-radius: 0.25rem;
    border: none;
    background-color: #fff;
  }

  .CreateImg {
    width: 9.375rem;
    height: 9.375rem;
    object-fit: contain;
    margin: 1.063rem 6.5rem 1.25rem;
  }

  .card-inner-box {
    width: 100%;
    min-width: unset;
    /* height: 9.5rem; */
    /* padding: 0 0.63rem 0.94rem; */
    border-radius: 0.13rem;
    border: solid 0.06rem #e4e4e4;
    background-color: #fff;
  }

  .Frame-20432 {
    width: 154px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    margin-left: -68px;
    padding: 8px 20px;
    border-radius: 4px;
    border: solid 1px #e4e4e4;
    background-color: #fff;
  }
  .Frame-19924 {
    width: 11.813rem;
    height: 1.75rem;
    margin-top: 1.25rem;
    /* margin-left: 0.75rem; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: 0rem 0.563rem;
    border-radius: 0.25rem;
    background-color: #118936;
    margin-left: 0rem;
  }

  .Frame-19924 > svg {
    margin: 0rem !important;
  }

  .skillfont {
    height: 1.063rem;
    /* margin: 0 0 0.625rem 0; */
    overflow: hidden;
    font-size: 0.875rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  
  .dropdown-title-skilllisting {
    max-width: 100%;
    padding: 0rem;
  }

  .ant-btn-icon-only {
    padding: 0rem;
  }

  /* .locationfont {
    font-size: 0.75rem;
  } */

  .salaryfont {
    height: 0.875rem;
    font-size: 0.75rem;
  }

  .contain {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .publishedfontframe {
    width: 4.375rem;
    height: 1.125rem;
    /* display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.625rem;
    margin: 0.563rem 0rem 0.625rem 0rem;
    padding: 0.125rem 0.125rem;
    border-radius: 0.125rem;
    background-color: rgba(17, 137, 54, 0.1); */
  }

  .locationfont {
    font-size: 0.75rem;
  }
  .publishedfont {
    width: 4.063rem;
    height: 1.063rem;
    flex-grow: 0;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #3e3e3e;
  }
  /* .daysfont {
    width: 5.813rem;
    height: 0.875rem;
    margin: 0.375rem 0 0rem 0rem;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #7e7e7e;
  } */

  .outerbox .manage-skillisting-status {
    /* align-items: flex-start; */
    max-height: 0.875rem;
  }

  .manage-skillisting-status {
    display: flex;
  }

  .eyeicon {
    margin: 0.1rem 0.125rem 0rem 0rem;
    object-fit: contain;
    color: #118936;
    /* display: inline-block; */
  }

  .eyeiconviews {
    /* width: 30px; */
    height: 0.875rem;
    flex-grow: 0;
    margin: 0 0.625rem 0 0.3125rem;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #118936;
  }
  .eyeIconStyle {
    width: 0.875rem;
    height: 0.6rem;
    color: "#118936";
  }
  .bidfont {
    /* flex-grow: 0; */
    font-size: 0.75rem;
    max-height: 0.875rem;
    /* font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #118936; */
  }
  .Rectangle-17523deleted {
    min-width: 20.625rem;
    /* height: 7.69rem; */
    /* margin: 1.25rem 0.94rem 1.81rem; */
    padding: 0.94rem 0.63rem;
    border-radius: 0.25rem;
    border: solid 0.06rem #e4e4e4;
    background-color: #fff;
  }
  /* .dropdownClassskilllisting .ant-dropdown-menu
{

} */
  /* .ant-btn-group > .ant-btn:last-child:not(:first-child),
  .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border: none !important;
    margin-left: 0.625rem !important;
    color: black !important;
    margin-top: 0.688rem;
    width: 2.188rem;
    height: 0rem;
  } */

  .skillfontdeleted {
    width: 19rem;
    height: 1.063rem;
    margin: unset;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #6e6e6e;
  }
  .locationfontdeleted {
    /* width: 4.438rem; */
    /* height: 0.688rem; */
    margin: 0rem;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #6e6e6e;
  }
  .salaryfontdeleted {
    width: 17rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-grow: 0;
    margin: 0rem;
    font-size: 0.75rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #6e6e6e;
  }
  .containdeleted {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .publishedfontframedeleted {
    width: 60px;
    height: 1.125rem;
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; */
    /* gap: 0.625rem; */
    /* margin: 0.563rem 0rem 0.625rem 0rem; */
    /* margin: 0px;
    padding: 1px 3px;
    border-radius: 0.125rem;
    background-color: rgba(110, 110, 110, 0.1); */
  }
  .publishedfontdeleted {
    width: 4.063rem;
    height: 1.063rem;
    flex-grow: 0;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #3e3e3e;

    /* width: 4.063rem;
  height: 1.063rem;
  flex-grow: 0;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e; */
  }
  .daysfontdeleted {
    width: 5.813rem;
    height: 0.875rem;
    /* margin: 0.375rem 0 0rem 0rem; */
    margin: 0rem;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #6e6e6e;
  }

  .singleSkillListingDeleted {
    width: unset;
    height: unset;
  }
  .dropdownClasskilllisting {
    display: none;
  }
  .eyeIconStyle {
    width: 0.75rem;
    height: 0.75rem;
    color: "#118936";
  }

  .filterDrawerTitle {
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #3e3e3e;
  }

  .filterDrawer .ant-menu {
    margin-top: 0.55rem;
  }

  .filterDrawer .ant-menu-item {
    color: #3e3e3e;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-height: 2.0625rem;
    padding: 0.5rem 0.9375rem;
    margin: 0rem 0rem 0.25rem !important;
  }
  .modalConfirmDelete .ant-modal-body {
    width: 100%;
  }
  .Skilllisting-tooltip-div {
    display: flex;
    align-items: center;
  }

  .Skilllisting-tooltip-span {
    margin-top: 1.25rem;
  }
}

@media screen and (min-width: 768px) {
  .pubcreate {
    margin-left: 1.5rem;
  }
}
