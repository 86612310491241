@media only screen and (min-width: 768px) {
  .dashboard-grid {
    display: grid;
    margin-top: 2.5rem;
    margin-bottom: 3.0625rem;
    /* width: 90vw; */
    /* height: 764px; */
    grid-template-areas:
      "profile clinical adskllbtn"
      "info analytics analytics"
      "info onbilling onbilling"
      "messages onbilling onbilling"
      "messages onbilling onbilling"
      "social onbilling onbilling";
    /* gap: 1rem; */
    justify-content: center;
  }
}

/* .dashboard-margintop-email-verification {
  margin-top: 6.938rem !important;
} */

.dashboard-analytics-clinical-IT{
  display: none;
}

.dashboard-analytics-panel-ITmargin{
  margin-top: -5.75rem !important;
}

.dashboard-analytics-panel-ITmargin-completed {
  margin-top: -4.2rem !important;
}

.dashboard-grid .Job_Details_cantainer {
  border: solid 0.063rem #e4e4e4;
  background: white;
  padding-top: 0.825rem;
  margin: 0.875rem 0rem 0.375rem 0rem;
}
.dashboard-profile-details {
  /* background-color: white; */
  grid-area: profile;
  margin-right: 2.125rem !important;
}
.dashboard-profile-info {
  /* background-color: white; */
  grid-area: info;
  margin-top: -1.1rem;
  margin-right: 2.125rem !important;
}
.dashboard-messages-panel {
  background-color: white;
  grid-area: messages;
}
.dashboard-social-links {
  background-color: white;
  grid-area: social;
}
/* .dashboard-clinical {
  background-color: white;
  grid-area: clinical;
} */
.dashboard-analytics-clinical {
  /* background-color: #f8f8f8; */
  grid-area: clinical;
}
.dashboard-analytics-button {
  /* background-color: #f8f8f8; */
  grid-area: adskllbtn;
  margin-bottom: 0rem;
}
.dashboard-analytics-panel {
  /* background-color: #f8f8f8; */
  grid-area: analytics;
  margin-top: -2.125rem;
}
.dashboard-on-billing-panel {
  grid-area: onbilling;
  margin-top: 2.3125rem;
}
.dashboard-upgrade {
  background-color: white;
  grid-area: upgrade;
  font-size: 0.875rem;
  color: #1e1e1e;
  font-weight: 500;
}
.dashboard-carousel {
  max-width: 44.27rem;
  background-color: white;
  grid-area: carousel;
  margin: 1.4rem 0rem 0rem 0rem !important;
}

.Job_Detais_section {
  background-color: white;
  display: flex;
  justify-content: space-between;
  min-height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.125rem 0 rgb(0 0 0 / 25%);
  margin-bottom: -1.5625rem;
  margin-top: 0rem;
}

.ant-modal-body {
  padding: unset !important;
}
.interviewSection {
  display: flex;
  flex-wrap: wrap;
  gap: 1.875rem;
}

.interviewSection .Interview_Details_GigsCard {
  max-width: 20.625rem;
}

.interview-title {
  display: flex;
  gap: 0.938rem;
  margin: 0.938rem 0rem;
}

.dashboard-interview-cards-inside-totalCount {
  width: 1.88rem;
  height: 1.25rem;
  flex-grow: 0;
  border-radius: 0.75rem;
  background-color: rgba(64, 84, 178, 0.2);
  display: grid;
  place-items: center;
  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4054b2;
}

.dashboard-interview-cards-inside {
  font-size: 0.875rem;
  color: #1e1e1e;
  font-weight: 600;
}

.dashboard-upgrade-inside {
  font-size: 0.875rem;
  font-weight: 600;
  color: #1e1e1e;
}

.dashboard-on-billing-panel1 {
  grid-area: interview;
  max-width: 43.125rem;
}

.upgradYourself {
  margin-top: 0.5625rem;
}

.dashboard-analytics-resume100 {
  margin-top: -0.75rem;
}

/* .dashboard-analytics-resume {
  margin-top: -2.125rem;
} */
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .dashboard-grid {
    display: flex;
    flex-direction: column;
    background: #fff;
    margin-bottom: 1.813rem;
    /* width: 90vw; */
    /* height: 764px; */
    /* grid-template-columns: auto; */
    /* "profile"
      "profile analytics analytics"
      "profile onbilling onbilling"
      "messages onbilling onbilling"
      "messages onbilling onbilling"
      "social onbilling onbilling"
      ". upgrade upgrade"
      ". upgrade upgrade"
      ". carousel carousel"; */
    gap: 0.958rem;
    align-items: center;
  }

  .dashboard-margintop-email-verification {
    margin-top: unset !important;
  }

  .dashboard-carousel {
    margin: 1.4rem -0.8125rem 0rem -0.8125rem !important;
  }
  .dashboard-analytics-button {
    /* background-color: #f8f8f8; */
    grid-area: adskllbtn;
    margin-bottom: 00.9375rem;
  }
  .Job_Detais_section {
    flex-direction: column;
    align-items: flex-start;
    box-shadow: none;
    margin-top: -3.25rem;
  }
  .dashboard-grid > div {
    width: 93vw;
    font-weight: 600;
    border-radius: 0.25rem;
  }
  .dashboard-profile-details {
    width: 100vw !important;
    margin-right: 0rem !important;
  }
  .dashboard-profile-info {
    margin-top: 0rem;
    margin-right: 0rem !important;
  }

  .dashboard-interview-cards-inside {
    font-size: 1rem;
    color: #1e1e1e;
    font-weight: 600;
  }

  .interviewSection {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0px !important;
  }

  .dashboard-on-billing-panel1 {
    /* border: solid 0.063rem #e4e4e4; */
    grid-area: interview;
  }

  .interview-title {
    margin-bottom: 0rem !important;
    display: flex;
    padding: 1.594rem 0rem 1.531rem 0.625rem !important;
    align-items: center;
    gap: 0.625rem;
    padding: 0.188rem 0.688rem;
    border-radius: 0.75rem;
  }

  .dashboard-interview-cards-inside-totalCount {
    width: 1.88rem;
    height: 1.25rem;
    flex-grow: 0;
    border-radius: 0.75rem;
    background-color: rgba(64, 84, 178, 0.2);
    display: grid;
    place-items: center;
    font-size: 0.75rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4054b2;
  }
  .dashboard-inbox .Message-box-container-box {
    margin-bottom: 0px;
  }
  .dashboard-inbox .candidate-profile-name {
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1e1e1e;
  }
  .dashboard-analytics-panel-ITmargin {
      margin-top: -2.125rem !important;
  }

  .dashboard-analytics-panel-ITmargin-completed {
    margin-top: -2.125rem !important;
  }
}
