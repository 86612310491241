.landing_main {
    height: auto;
    /* padding: 2rem 1rem; */
    /* padding-left: 2rem;
      padding-right: 1rem; */
    /* padding-top: 2rem;
      padding-bottom: 2rem; */
    /* padding: 0rem 3.813rem 0rem 3.813rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
}

.fav_text {
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    margin: 24px 0 24px 0px;
    text-align: center;
}

.heading_text {
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    margin: 24px 0 24px 0px;
    text-align: center;
}

.heading_text_2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    margin: 24px 0 24px 0px;
    text-align: center;
}

.tick-image {
    height: 85px;
    width: 85px;
}

.building-image {
    height: 35px;
    width: 35px;
    border-radius: 50%;
}

.skill-name {
    margin: 10px;
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.hos-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #4054B2;
    cursor: pointer;
}

.gig-name {
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #626262;
}

.location-name {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin: 5px 0px 5px 0px;
}

.bidStatus-grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.rate-name {

    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}

.data-container {
    padding: 29px;
    border: 1px solid #D4D4D4;
    border-radius: 5px;
    margin: 24px 0 24px 0;
    width: 700px;
}

.last-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.opp-name {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

}

.emp-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin: 15px 0px 15px 0px;
    color: #4E4E4E;

}

.btn-talk {
    width: 200px;
    color: #4054B2;
    font-weight: bold;
    font-size: 17px;
    border: 1px solid #4054B2;
    border-radius: 5px;
    height: 42px;
    line-height: 1.13;
    cursor: pointer;
    padding: 8px 19px;
    /*margin-left: 2%;*/
}

.data-container .image-email-container {
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .data-container .image-email-text {
    color: white;
    font-size: 20px;
    padding-top: 2px;
  }
 .data-container .imageCont {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    background-color: #118936;
    display: flex;
    justify-content: center;
  }

@media screen and (max-width: 900px) {
    .heading_text {
        font-size: 15px;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;

    }

    .heading_text_2 {
        font-size: 15px;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        max-width: 85%;

    }

    .data-container {
        padding: 29px;
        border: 1px solid #D4D4D4;
        border-radius: 5px;
        margin: 24px 0 24px 0;
        width: 100%;
    }

    .opp-name {
        font-size: 15px;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;

    }

    .fav_text {
        font-size: 20px;
        font-weight: 600;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        margin: 24px 0 24px 0px;
    }

    .bidStatus-grid {
        display: 'flex';
        justify-content: 'space-between';
        flex-direction: column;
    }

    .tick-image {
        height: 50px;
        width: 50px;
    }

    .followers_main {
        height: 100%;
        width: 100%;
        justify-content: center;
        padding: 0.625rem;
    }
}