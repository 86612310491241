.Bid-header {
  margin-top: 5px;
  /* font-family: "Lato", sans-serif; */
  text-align: center;
}

h4 {
  font-size: 18px;
}

.panel {
  align-content: center;
}

.panel h4 {
  font-size: 14px;
}

.panel .panel-container {
  /* background-color:blue; */
  margin: auto;
  padding-left: 15px;
  text-align: left;
  border: 1px solid #eaeaea;
}

/* .panel .panel-container .span{
    
} */
.gig-detail {
  margin: auto;
}

.gig-detail h4 {
  font-size: 18px;
}

.bid-form {
  margin: auto;
}

.buttons-row {
  width: 100%;
}

.Ebuttons-row {
  width: 100%;
}

.EditBid-ToolTips {
  display: flex;
  align-items: center;
}

.bid-form .buttons-row .bid-btn {
  background-color: #fc1153;
  width: 100%;
  /* margin-left: 10%; */
  display: block;
  color: white;
  font-size: 18px;
  border-color: #fc1153;
  border: 1px solid transparent;
  line-height: 24px !important;
  height: auto;
  padding: 10px;
  border-radius: 4px !important;
}

.bid-form .Ebuttons-row .bid-btn {
  background-color: #fc1153;
  width: 100%;
  /* margin-left: 10%; */
  display: block;
  color: white;
  font-size: 18px;
  border-color: #fc1153;
  border: 1px solid transparent;
  line-height: 24px !important;
  height: auto;
  padding: 10px;
  border-radius: 4px !important;
}

.bid-form .buttons-row .retract-btn {
  background-color: #5bd3e3;
  width: 100%;
  /* margin-left: 10%; */
  display: block;
  color: white;
  font-size: 18px;
  border: 1px solid #45cddf;
  line-height: 24px !important;
  height: auto;
  padding: 10px;
  border-radius: 4px !important;
}

.bid-form .Ebuttons-row .retract-btn {
  background-color: #5bd3e3;
  width: 100%;
  /* margin-left: 10%; */
  display: block;
  color: white;
  font-size: 18px;
  border: 1px solid #45cddf;
  line-height: 24px !important;
  height: auto;
  padding: 10px;
  border-radius: 4px !important;
}

.bid-form .buttons-row .retract-btn:hover {
  background-color: #2fc7dc;
}

.bid-form .Ebuttons-row .retract-btn:hover {
  background-color: #2fc7dc;
}

.bid-form .buttons-row .bid-btn:hover {
  border-color: rgb(173, 36, 36);
}

.bid-form .Ebuttons-row .bid-btn:hover {
  border-color: rgb(173, 36, 36);
}

.bid-form .buttons-row .cancel-btn {
  width: 100%;
  background-color: #c0c0c0;
  display: block;
  color: #fff;
  border: 1px solid #b3b3b3;
  font-size: 18px;
  line-height: 24px !important;
  height: auto;
  padding: 10px;
  border-radius: 4px !important;
}

.bid-form .Ebuttons-row .cancel-btn {
  width: 100%;
  background-color: #c0c0c0;
  display: block;
  color: #fff;
  border: 1px solid #b3b3b3;
  font-size: 18px;
  line-height: 24px !important;
  height: auto;
  padding: 10px;
  border-radius: 4px !important;
}

.bid-form .buttons-row .cancel-btn:hover {
  background-color: #a7a7a7;
}

.bid-form .Ebuttons-row .cancel-btn:hover {
  background-color: #a7a7a7;
}

.bid-form .Select {
  width: 100%;
}

.bid-form .Select Option {
  color: #eaeaea;
}

.bid-form Input {
  background-color: #f6f6f6;
  color: #555;
}

.bid-form .coverLetter TextArea {
  background-color: #f6f6f6;
  height: 90px;
  border: 1px solid lightgray;
  color: #555;
}

.bid-form TextArea::placeholder,
.bid-card TextArea::placeholder {
  color: #555;
}

.bid-form Select {
  background-color: #f6f6f6;
}

.bid-form .buttons-row {
  padding-top: 25px;
}

.bid-form .input-padding .rate-span {
  color: gray;
  margin-top: 0%;
  font-size: 12px;
}

.bid-form .input-padding Input::placeholder {
  color: #555;
}

.bid-form .client-billed {
  margin-bottom: 10px;
  /* margin-top: 0%; */
}

.bid-form .client-billed Input {
  color: #555;
}

.bid-form .form-bid {
  margin-top: 20px;
}

.bid-to-apply .ant-select-selection {
  background-color: darkgray;
  color: #555;
}

.select-currency .ant-select-single.ant-select-open .ant-select-selection-item {
  color: #3e3e3e !important;
}

.bid-card .select-currency {
  display: flex;
  background-color: white !important;
  color: #3e3e3e !important;
}

.select-currency .ant-select-single .ant-select-open .ant-select-selection-item {
  color: #3e3e3e;
}

select-currency-options .select-currency .ant-input-prefix {
  font-size: 1rem !important;
  color: #1e1e1e !important;
  margin-right: 0.5rem !important;
}

.select-currency .ant-input-affix-wrapper>.input.ant-input {
  background-color: white !important;
}

/* .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
   color: black !important;
   font-weight: normal !important;
   background-color: white !important;
} */
.select-currency-options {
  font-weight: 500 !important;
  background-color: white !important;
  color: #3e3e3e !important;
}

.convert-to-usd {
  margin-top: .5rem;
  color: #118936;
  cursor: pointer;
}

.convert-bill-to-usd {
  margin-top: -1rem;
  color: #118936;
  cursor: pointer;
}

.conversion-pointer {
  cursor: default;
}

@media only screen and (max-width: 750px) {
  .buttons-row {
    /* position: absolute; */
    bottom: 0;
    margin: auto;
    width: 100%;
    /* background-color: #555; */
  }
}

@media only screen and (max-width: 750px) {
  .Ebuttons-row {
    /* position: absolute; */
    bottom: 0;
    margin: auto;
    width: 100%;
    /* background-color: #555; */
    padding: 20px 10px 20px 10px;
    border-top: #ccc 1px solid !important;
  }
}

.site-card-border-less-wrapper {
  background-color: #f8f8f8;
}

.radio-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.25rem;
}

.radio-styles {
  /* margin: 0.94rem 0.75rem 1.25rem 0.13rem; */
  padding: 0.75rem;
  margin-right: 0;
  height: 2.75rem;
  border-radius: 0.25rem;
  border: solid 0.06rem #d4d4d4;
  background-color: #fff;
  color: #3e3e3e;
  font-size: 1rem !important;
}

.radio-styles-rate {
  width: 5rem;
  height: 1.25rem;
  flex-grow: 0;
  font-size: 1rem !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
  height: 19px !important;

  /* margin: 0.5rem 1.38rem 1.38rem 0.25rem; */
}

.rate-input {
  width: 12.5rem;
  height: 2.75rem;
  flex-grow: 0;
  /* margin: 1.25rem 2.81rem 0.63rem 3.75rem;
    padding: 0.81rem 7.81rem 0.75rem 0.75rem; */
  border-radius: 0.25rem;
  border: solid 0.06rem #d4d4d4;
  background-color: #fff;
}

.rate-group {
  display: grid;
  grid-template-columns: 1fr 0.7fr 0.7fr;
  margin-bottom: 2rem;
  align-items: center;
  /* height: 3rem; */
  /* grid-gap: 0.5rem; */
}

.rate-field {
  margin-right: 2.5rem;
  width: 12rem;
  height: 2.75rem;
  border-radius: 0rem 0.25rem 0.25rem 0rem !important;
}

.bid-card .ant-select-selector {
  height: 2.75rem !important;
  padding: 0.313rem !important;
  border-radius: 0.25rem 0rem 0rem 0.25rem !important;
  border-right: 0px solid #d9d9d9;
}

.bid-card .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-right: 0px solid #d9d9d9 !important;
}

.bid-card .ant-form-item-explain-error {
  color: #d42828;
  font-size: 0.75rem;
  margin-top: 0.5rem;
}

.bid-card span.ant-select-selection-item {
  padding-right: 2.25rem !important;
  color: #3e3e3e !important;
  font-size: 1rem !important;
}

.bid-card .ant-card-body {
  padding: 1rem 2rem;
  padding-bottom: 0rem;
}

.billed-client {
  height: 1.19rem;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-bottom: 0.9375rem;
  display: flex;
  align-items: center;
}

.tax-text {
  margin-top: 1rem;
}

.bid-btn-div {
  display: flex;
  justify-content: flex-end;
}

.bid-btn-form {
  width: 12.5rem;
  height: 2.63rem;
  /* margin: 1.25rem 1.88rem 0 1.56rem;
    padding: 0.75rem 4.13rem 0.69rem 4.00rem; */
  border-radius: 0.25rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
  color: white;
  background-color: #118936;
}

.grid-amount {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.bill-amount {
  height: 2.75rem !important;
  color: #1e1e1e !important;
  border-radius: 0.25rem !important;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.15) !important;
  border: solid 0.06rem #d4d4d4 !important;
  background-color: #fff !important;
  max-width: 12.375rem;
  font-size: 1rem !important;
}

.bid-card .ant-input[disabled]:hover,
.bid-card .ant-input[disabled] {
  color: black;
  padding: 0.75rem !important;
}

.empty-bill-amount {
  height: 44px;
  flex-grow: 0;
  /* color: #7e7e7e !important; */
  border-radius: 4px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px #d4d4d4;
  background-color: #fff;
}

.rate-span {
  height: 0.88rem;
  font-size: 0.75rem;
  font-weight: 500;
  margin-top: 0.75rem;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9e9e9e;
}

.bid-card textarea {
  resize: none;
  padding: 1rem;
  /* margin-left: 2rem;
    margin-right: 2rem; */
}

.bid-card {
  /* height: 50.69rem; */
  /* width: 47.5rem; */

  margin-top: 2rem;
  margin-left: 2.5rem;
}

.bid-card .bid-job-title {
  /* width: 193px; */
  height: 28px;
  flex-grow: 0;
  /* margin-left: 0.25rem; */
  margin-right: 0.25rem;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #118936;
}

.bid-card .ant-radio-checked .ant-radio-inner {
  border-color: #118936 !important;
}

.bid-card .ant-radio-checked .ant-radio-inner:after {
  background-color: #118936;
}

.bid-card .ant-radio:hover .ant-radio-inner {
  border-color: #118936;
}

.bid-card .bid-image-title {
  display: flex;
  margin-bottom: 1rem;
  gap: 0.938rem;
  cursor: pointer;
}

.bid-card .bid-heading {
  height: 1.81rem;
  font-size: 1.5rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-bottom: 1.25rem;
}

.bid-card .ant-btn span {
  height: 19px;
  font-size: 1rem;
}

.select-currency input[type="text"]::placeholder {
  color: #1e1e1e !important;
}

.form-bid .ant-input-textarea-show-count.ant-input-textarea-in-form-item::after {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #7e7e7e;
  margin-bottom: 0rem;
}

/* media quries for laptops and big screens */
@media only screen and (min-width: 1000px) {
  .bid-card {
    /* height: 50.69rem; */
    /* width: 47.5rem; */

    margin-top: 2rem;
    margin-left: 2.5rem;
    margin-bottom: 2rem;
  }

  .rate-spans .ant-form-item {
    margin-bottom: 0rem !important;
  }

  .bid-card .bid-job-title {
    /* width: 193px; */
    height: 28px;
    flex-grow: 0;
    /* margin-left: 0.25rem; */
    margin-right: 0.25rem;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #118936;
  }

  .bid-card .bid-image {
    /* margin-right: 1rem; */
    border-radius: 4px;
    object-fit: cover;
  }

  .bid-card .bid-image-title {
    display: flex;
    margin-bottom: 1rem;
    gap: 0.75rem;
  }

  .bid-card .bid-heading {
    height: 1.81rem;
    font-size: 1.5rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1e1e1e;
    margin-bottom: 1.25rem;
  }

  .bid-card .rate-group {
    height: 3rem;
  }

  .bid-card .bid-text {
    height: 1.06rem;
    font-size: 0.88rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1e1e1e;
  }

  .bid-card textarea {
    height: 150px !important;
  }

  .select-currency input[type="text"]::placeholder {
    color: #1e1e1e !important;
  }
}

@media only screen and (max-width: 750px) {
  .bid-form .Ebuttons-row .bid-btn {
    width: 100%;
  }

  .billed-client {
    height: 1.06rem;
    flex-grow: 0;
    font-size: 0.88rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1e1e1e;
    margin-top: 1.25rem;
  }

  .bid-form .coverLetter TextArea {
    border-radius: 0.25rem;
    border: solid 0.06rem #d4d4d4;
    background-color: #fff;
  }

  .radio-styles-rate {
    margin-right: 2.5rem;
    font-size: 0.875rem !important;
    /* margin: 0.5rem 1.38rem 1.38rem 0.25rem; */
  }

  .select-currency .ant-select-single.ant-select-open .ant-select-selection-item {
    color: #3e3e3e !important;
  }

  .select-currency-options {
    font-size: 0.875rem;
    font-weight: 500 !important;
    background-color: white !important;
    color: #3e3e3e !important;
  }

  .select-currency input[type="text"]::placeholder {
    color: #1e1e1e !important;
  }

  .bid-card .bid-heading {
    height: 1.31rem;
    font-size: 1.13rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1e1e1e;
  }

  .bid-card .bid-image {
    height: 80px;
    object-fit: cover;
  }

  .bid-card .bid-job-title {
    height: 1.06rem;
    white-space: nowrap;
    width: 9.063rem;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.88rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #118936;
  }

  .bid-card .bid-text {
    height: 2.75rem;

    font-size: 0.88rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #1e1e1e;
  }

  .rate-group .ant-row .ant-form-item {
    margin: 0rem !important;
  }

  .rate-span {
    margin-top: 3.5rem;
    font-size: 0.63rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: left;
    color: #9e9e9e;
  }

  .site-card-border-less-wrapper {
    /* width: 22.50rem;
    height: 81.50rem; */

    margin: 0rem;
    padding: 0rem;
    /* margin-top: 1rem;
    margin-left: 1rem; */
  }

  .bid-card {
    /* width: 24rem; */
    /* height: 81.50rem; */
    /* width: 100%;
        height: 100%; */

    margin: 0rem;
    /* margin-top: 1rem;
        margin-left: 1rem; */
  }

  .bid-card .ant-card-body {
    padding: 1rem;
  }

  #coverLetters .ant-input {
    padding: 1rem;
  }

  .radio-styles {
    height: 2.75rem;
    border-radius: 0.25rem;
    border: solid 0.06rem #d4d4d4;
    background-color: #fff;
    font-size: 0.875rem !important;
  }

  .radio-group {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
  }

  .rate-group {
    display: grid;
    grid-template-columns: 1fr;
    /* grid-template-rows: 1fr 1fr; */
    margin-bottom: 1.968rem;
    height: 6rem;
    /* grid-gap: 0.5rem; */
  }

  .bid-divider {
    display: none;
  }

  .radio-rate-group {
    display: flex;
    justify-content: start;
  }

  .rate-field {
    width: 100%;
    height: 2.5rem;
    flex-grow: 0;
    /* margin: 0.94rem 0 0;
    padding: 0.75rem 16.38rem 0.69rem 0.75rem; */
    border-radius: 0rem 0.25rem 0.25rem 0rem !important;
    border: solid 0.06rem #d4d4d4;
    background-color: #fff;
  }

  .rate-field {
    margin-right: 0rem;
    width: 15rem;
  }

  .rate-span {
    margin-top: 3.5 rem;
    height: 2.25rem;
    font-size: 0.63rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: left;
    color: #9e9e9e;
  }

  .bid-btn-form {
    width: 100%;
    height: 2.63rem;
    font-size: 1.25rem;
    border-radius: 0.31rem;
    background-color: #118936;
  }

  .bid-btn-div {
    display: block;
    margin-top: .5rem;
  }

  .bid-card .ant-btn span {
    font-size: 0.875rem !important;
  }

  .bill-amount {
    font-size: 0.875rem !important;
    color: #1e1e1e !important;
  }

  .bid-card .ant-input[disabled]:hover,
  .bid-card .ant-input[disabled] {
    font-size: 0.875rem !important;
  }

  .bid-card .ant-select-selector {
    height: 2.5rem !important;
    padding: 0.313rem !important;
    border-radius: 0.25rem 0rem 0rem 0.25rem !important;
    border-right: 0px solid #d9d9d9 !important;
  }

  .bid-card .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding-left: 0.75rem !important;
    border-right: 0px solid #d9d9d9 !important;
  }

  .bid-card .ant-form-item-explain-error {
    color: #d42828;
    font-size: 0.625rem;
  }

  .bid-card span.ant-select-selection-item {
    padding-right: 2.25rem !important;
    font-size: 0.875rem !important;
    color: #3e3e3e !important;
  }

  .select-currency .ant-input-prefix {
    font-size: 0.875rem !important;
    color: #1e1e1e !important;
    margin-right: 0.5rem !important;
  }

  .select-currency .ant-input-affix-wrapper>.input.ant-input {
    background-color: white !important;
  }
}

.bid-form .error-msg {
  height: 50px;
  border-color: #973b48;
  color: #973b48;
  /* display: none; */
}

.cross-icon {
  border: 1px solid #d9d9d9;
  padding: 4px;
  border-radius: 3px;
  height: 32px;
  width: 34px;
  background-color: #fafafa;
  align-content: center;
  text-align: center;
}

.bid-card div [role="alert"]:empty {
  display: none;
}