.License{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 2rem;
}
.license-number{
    width: 50%;
}
  .license-name{
    margin-top: 25px !important;
    color: #212121;
font-size: 24px;
font-style: normal;
font-weight: 800;
line-height: 28px; 


  }
  .Optional-license{
    padding-left: 10px !important;
    color: #7E7E7E;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 8px; /* 80% */
  }
  .license-Text{
    margin-top: 25px !important;
    color: #3E3E3E;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
.TextInput{
    margin-top: 20px;
}
.ant-input:focus {
    outline: none;
    border-bottom: 1px solid #BDBDBD;;
    box-shadow: none;
    }
.Input{
    background: none;
    width: 100%;
    padding: 10px;
   border-bottom: 1px solid #BDBDBD;
   border-top: none;
   border-left: none;
   border-right: none ;
   outline: none;
    font-size: 16px;
}

.sub-text{
    color: #4054B2;
text-align: center;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 10px;
}
.license-text{
    margin-top: 25px;
    display: flex;
width: 300px;
height: 31px;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 6px;
background: rgba(236, 238, 247, 0.44);
}
.success-message{
    margin-top: 25px !important;
    color: #118936;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;

}
.licensebutton{
  width: 100% !important;
  display: flex;
  justify-content: center;
}
.validating{
  font-size: 12px;
  font-weight: 400;
  line-height: 14.32px;
  text-align: left;
  color: #5E5E5E;
      }

@media screen and (max-width: 768px) {
    .license-number{
        width: 90%;
    }
    .license-name{
      width: 278px !important;

    }
    .license-label{
      color: #212121;
      font-size: 16px;
      font-weight: 800;
      line-height: 28px;
      text-align: left;
    }
    .gap-button-skip{
      border: 1px solid #E4E4E4 !important;
    }

    .gap-back-next-signup-buttons{

    position: absolute !important;
    bottom: 14px !important;
    flex-direction: row !important;
    }
    .licensebutton{
      display: flex;
      justify-content: center;
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: #fff;
      box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.25);
      padding: 2.625rem ;
    }
    .gap-button-back{
      width: 50% !important;
    }
}