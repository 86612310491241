.container-auth .login-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: auto;
  align-items: center;
}

/* .ant-divider-horizontal.ant-divider-with-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  color:#888888;;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  border-top: 0;
  border-top-color: rgba(0, 0, 0, 0.06);
}
.ant-divider-horizontal.ant-divider-with-text::before, .ant-divider-horizontal.ant-divider-with-text::after {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  align-items: center;
  top: 50%;
  width: 50%;
  flex: 0.05 0.1;
  border-top: 2px solid transparent;
  border-top-color: inherit;
  border-bottom: 0;
  transform: translateY(50%);
  content: '';
} */

.divider:before,
.divider:after {
  content: "";
  -webkit-box-flex: 1.07;
  -ms-flex: 1.07 0.1;
  flex: 1.07 0.1;
  border-bottom: 1px solid;
  margin: auto;
  background-color: #d4d4d4;
  color: #d4d4d4;
}
.divider:before {
  margin-right: 10px;
}
.divider:after {
  margin-left: 10px;
}

.login-form-forgot {
  float: right;
  font-size: 12px;
  color: #888888;
}

.login-form-button {
  width: 20.6rem !important;
  height: 2.5rem !important;
  margin: 1rem 0 0rem;
  border-radius: 30px !important;
  color: white;
  background-color: #118936 !important;
}
.ant-btn{
  margin:-1px 0 25px 0;
}

.bg-black-imp{
  background-color: black !important;
  margin-top: 1rem;
}
.bg-black-imp:hover{
  background-color:rgba(0, 0, 0, 0.8)  !important;
}
.terms-and-services {
  /* margin: 20px 20px 23px 21px; */
  /* padding: 0 11px 0 0; */
  /* font-family: SFProText; */
  width: 20.6rem;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  /* font-style: normal; */
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
  align-self: flex-start;
  margin-top: 0.7rem;
  margin-bottom: 1.43rem;
  justify-content: flex-start;
}

.subTerm {
  color: #7e7e7e;
  padding-left: 0.3rem;
}
.subTerm :hover {
  color: #0099ff;
}

.signup-form .ant-tabs-tabpane {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  outline: none;
  /* width: 0; */
}

.signup-form .ant-tabs {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* .ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: var(--primaryColor);
  color: #e73959 !important;
} */

.signup-form .ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 3px solid #e73959 !important;
  z-index: 2;
}

.signup-form .ant-form label {
  font-size: 12px;
  color: #888888;
}

.signup-form .ant-tabs-tab-btn {
  color: #888888;
  text-shadow: 0 0 0.25px currentColor;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
}

.signup-form .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: black;
  text-shadow: 0 0 0.25px currentColor;
  font-weight: 500;
}

.show-password {
  position: fixed;
}

.emailInputSignUp {
  width: 20.6rem !important;
  height: 2.5rem !important;
  margin: 0 0 0.2rem !important;
  border-radius: 30px !important;
  border: solid 1px #d4d4d4 !important;
  background-color: #fff !important;
}

.passwordSignUp {
  width: 20.6rem !important;
  height: 2.5rem !important;
  /* margin: 0.8rem 0 0.2rem !important; */
  border-radius: 30px !important;
  border: solid 1px #d4d4d4 !important;
  background-color: #fff !important;
}

.passwordHelp {
  width: 20.6rem;
  margin-top: 0.4rem;
  /* margin-top: 1.5rem; */
  /* margin-top: -1rem; */
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  /* font-style: normal; */
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color:  #7E7E7E;
  
}

.passwordShow {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #4054b2;
}

.container-auth .ant-form-item {
  margin-bottom: 0;
}

.auth-divider-line {
  height: 1px;
  /* margin: 23px 0 0; */
  background-color: #e4e4e4;
  display: none;
}

.bottom-text {
  font-size: 16px;
  font-weight: 500;
  align-self: center;
  margin: 0rem 0rem 0rem 8rem;
  color: #118936;
  padding-top: 10px;
  padding-bottom: 13px;
  cursor: pointer;
}

.account-allready-section {
  padding: 0 6px 0 0;
  font-size: 14px;
  font-weight: 500 !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #7e7e7e;
  position: relative;
  top: -24px;
}

.account-sign-in {
  color: #118936;
}
.static-sign-in {
  color: black !important;
  text-decoration: underline !important;
  margin-left: 5px;

}
.account-allready-section span:hover {
  color: #00b33c;
  cursor: pointer;
}

.usernameForm {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
}

.loginSkillgigs {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.loginSkillgigs .ant-form-item {
  margin-bottom: 0;
}

.remeberForgotCSS {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}
.ant-form-item-control-input{
  margin-bottom: 10px;
}
.remeberForgotWRAP .ant-form-item-control-input-content {
  display: flex !important;
  justify-content: space-between !important;
  margin-bottom: 3px;  
}

.forgotPassCSS {
  /* margin-left: 6rem; */
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--primaryColor);
  cursor: pointer !important;
}
.bl-color{
  color: #7E7E7E !important;
}

/* .forgotPassCSS:hover {
  color: #18c04d;
} */

.enterEmail {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  /* color: #7e7e7e; */
}

.signup-form .ant-input {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

.loginSkillgigs .ant-input {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

.loginSkillgigs .ant-checkbox-checked .ant-checkbox-inner {
  background-color: black;
  border-color: black;
  color: white;
  border: none;
}

.ant-btn-primary{
  border: none !important;
}

.loginSkillgigs .ant-checkbox-checked .ant-checkbox-inner:hover,
.loginSkillgigs .ant-checkbox-checked .ant-checkbox-inner:focus {
    border: #7E7E7E !important;
}

#normal_login_remember:focus,
#normal_login_remember:hover
{
  border: #7E7E7E !important;
}

.ant-form input[type='file']:focus, .ant-form input[type='radio']:focus, .ant-form input[type='checkbox']:focus{
  border: #7E7E7E !important;
}



.displayNoneHelpPass {
  display: none !important;
}

.notValidColor {
  color: var(--primaryColor-error);
}

.notValidInput {
  border-color: var(--primaryColor-error) !important;
  box-shadow: 0 0 0 1px rgb(255 77 79 / 20%) !important;
}

.validInputCSS {
  border-color: var(--primaryColor) !important;
  box-shadow: 0 0 0 1px #6aff999d !important;
}

.authorization-sign-in .ant-form-item-explain,
.authorization-sign-in .ant-form-item-extra {
  color: var(--primaryColor-error) !important;
}

/* .ant-form-item-explain .ant-form-item-explain-error {
  color: #db2828 !important;
} */

/* .ant-row .ant-form-item .ant-form-item-has-success {
  color:  !important;
} */

.authorization-sign-in .ant-form-item-has-success .ant-input:focus,
.authorization-sign-in .ant-input-focused {
  /* border-color: var(--primaryColor); */
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px var(--primaryColor);
}

/* .ant-form-item-explain-error .ant-input:focus,
.ant-input-focused { */
/* border-color: var(--primaryColor); */
/* border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px #db2828 !important;
} */

.authorization-sign-in
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.authorization-sign-in
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:focus,
.authorization-sign-in
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused,
.authorization-sign-in
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper-focused {
  border-color: var(--primaryColor-error) !important;
  border-right-width: 1px !important;
  outline: 10 !important;
  box-shadow: 0 0 0 2px rgb(255 77 79 / 20%);
}
/* .forgotPassCSS span:hover {
  color: #00b33c;
  cursor: pointer;
} */

.form-label {
  /* font-family: Roboto; */
  font-size: 0.9rem;
  font-weight: 500;
}

@media screen and (max-width: 900px) {
  .divider {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    /* color: #888888; */
    width: 20rem;
    font-size: unset;
  }
  .ant-btn{
    margin:0px 0 25px 0;
  }
  
  .bg-black-imp{
    margin-top:1rem ;
  }
  /* .divider:before,
  .divider:after {
    content: "";
    -webkit-box-flex: 0.5;
    -ms-flex: 0.5 0.1;
    flex: 0.5 0.1;
    border-bottom: 1px solid;
    margin: auto;
    color: #5e5e5e;
  } */
  /* .divider:before {
    margin-right: 10px;
  }
  .divider:after {
    margin-left: 10px;
  } */

  .terms-and-services {
    padding-right: 0.5rem;
    display: flex;
    justify-content: flex-start;
  }

  .authorization-sign-in {
    padding: 0.15rem 0 1rem;
    margin-top: -80px;
    margin-bottom: 70px;
  }
}
