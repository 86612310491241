.TextInputParentContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  /* margin-top: 2rem; */
}
.inputContainer{
  display: flex;
  width: 100%;
  border: 0px, 0px, 1px, 0px;
  overflow: hidden;
}
.inputContainerVerified{
  background:  #F2F2F2;
}
.verificationMsg{
  margin-top: 10px;
  color: #118936;
  font-size: 14px;
  font-weight: 400;
}
.cotryCodeContainer{
  /* width: 10%; */
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  margin-top: 2rem;
  padding-left: 0.5rem;
  align-items: center;
  padding-top: 10px;
}

.mobileVerificationText{
  font-size: 14px;
  font-weight: 400;
  color: #3E3E3E;
  margin-top: 12px;
}
.cotryCodeContainerError{
  /* width: 10%; */
  border-bottom: 1px solid #dc2d2d;
  border-color: #dc2d2d;
  border-radius: 0;
  margin-top: 2rem;
  padding-left: 0.5rem;
  padding-top: 10px;
  align-items: center;
}
.cotryCodeContainerinner{
  /* font-weight: bold;
  font-size: medium; */
  align-self: center;
  height: 60%;
  display: flex;
  margin-top: 5%;
  border-bottom-color: #ccc;
}
.cotryCodeContainerinnerError{
  /* font-weight: bold;
  font-size: medium; */
  align-self: center;
  height: 60%;
  display: flex;
  margin-top: 5%;
}
.country-code{
  font-size: 1.5rem;
  font-weight: 800;
  padding-bottom: 0.225rem;
}
.border-class-withnumber{
  border-right: 4px solid #1E1E1E;
  height: 70%;
  margin-top: 0.5rem;
  padding-right: 7px;
  margin-right: 5%;
  padding-left: 0.8rem;
}
.border-class{
  border-right: 4px solid #1E1E1E;
  height: 70%;
  margin-top: 0.5rem;
  padding-right: 7px;
  margin-right: 5%;
  padding-left: 0.8rem;
}
.TextInputTitleContainer {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.TextInputTitle {
  color: #212121;
  font-weight: 800;
  font-size: 1.5rem;
}
.MobileInputStyle::placeholder{
  padding: 0 !important;
  font-size: 1.25rem !important;
}
.MobileInputStyleError::placeholder{
  padding: 0 !important;
  font-size: 1.25rem !important;
}
.MobileInputStyle:focus {
  box-shadow: none; /* Remove focus box shadow */
  border-color: #ccc; /* Set the border color on focus */
  outline: none;
}
.MobileInputStyle:hover {
  box-shadow: none; /* Remove focus box shadow */
  border-color: #ccc; /* Set the border color on focus */
  outline: none;
}
.MobileInputStyle:active {
  box-shadow: none; /* Remove focus box shadow */
  border-color: #ccc; /* Set the border color on focus */
  outline: none;
}
.MobileInputStyleError:active {
  box-shadow: none; /* Remove focus box shadow */
  border-color: #ccc; /* Set the border color on focus */
  outline: none;
}
.MobileInputStyleError {
  border: none;
  font-size: 1.25rem;
  font-weight: 400;
  border-bottom: 1px solid;
  border-color: #dc2d2d;
  border-radius: 0;
  margin-top: 2rem;
  padding-left: 3%;
  padding-bottom: 0.5rem;
}
.MobileInputStyleError:focus {
  box-shadow: none !important; /* Remove focus box shadow */
  border-bottom: 1px solid #DB2828 !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
}
.MobileInputStyleError .ant-select-multiple .ant-select-selection-placeholder{
  left : 0 !important;
}
.MobileInputStyleError .ant-select-multiple .ant-select-selection-search{
  -webkit-margin-start: 0 !important;
  margin-inline-start: 0 !important;
}    
.MobileInputStyle {
  border: none;
  font-size: 1.25rem;
  font-weight: 400;
  border-bottom: 1px solid;
  border-color: #ccc;
  border-radius: 0;
  margin-top: 2rem;
  width: 100%;
  padding-left: 3%;
  /* padding-bottom: 0.5rem; */
  padding-top: 15px;
  padding-bottom: 15px;
}
.TextInputType {
  color: #7E7E7E;
  margin-left: 0.7rem;
  margin-top: 4px;
  font-size: 0.625rem;
  font-weight: 400;
}
.textInputContainer {
  width: 50%;
}
.verificationSVG{
  z-index: 1;
  /* display: block; */
  /* margin-top: 1.9rem; */
  position: absolute;
  right: 15px;
  bottom: 20px;
}


.errorIcon {
  color: #dc2d2d;
  margin-right: 5px;
}
.errorMsg {
  color: #dc2d2d;
  margin-top: 0.8rem;
}
@media screen and (max-width: 768px) {
  .textInputContainer {
    width: 90%;
  }
  .TextInputTitle {
    color: #3e3e3e;
    font-weight: bolder;
    font-size: 1.1rem;
  }
  .MobileInputStyle {
    border: none;
    border-bottom: 1px solid;
    border-color: #ccc;
    font-size: 1rem;
    border-radius: 0;
    margin-top: 2.2rem;
    padding-left: 1rem;
  }
  .border-class-withnumber{
    border-right: 4px solid #1E1E1E;
    height: 60%;
    margin-top: 0.3rem;
    padding-right: 7px;
    margin-right: 5%;
    padding-left: 0.8rem;
  }
  .border-class{
    border-right: 4px solid #1E1E1E;
    height: 60%;
    margin-top: 0.3rem;
    padding-right: 7px;
    margin-right: 5%;
    padding-left: 0.8rem;
  }
  .MobileInputStyleError {
    border: none;
    font-size: 1rem;
    font-weight: 400;
    border-bottom: 1px solid;
    border-color: #dc2d2d;
    border-radius: 0;
    margin-top: 2.2rem;
    padding-left: 1rem;
    /* padding-bottom: 0.5rem; */
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .cotryCodeContainer{
    /* width: 10%; */
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    margin-top: 2.2rem;
    padding-left: 0.5rem;
    align-items: center;
    display: flex;
    justify-content: center;
    padding-top: 4px;
  }
  .verificationSVG{
    bottom:18px
  }
  .cotryCodeContainerError{
    /* width: 10%; */
    border-bottom: 1px solid;
    border-color: #dc2d2d;
    border-radius: 0;
    margin-top: 2rem;
    /* padding-left: 0.5rem; */
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .country-code{
    font-size: 1rem;
    font-weight: 800;
  }
  .MobileInputStyle::placeholder{
    padding: 0 !important;
    font-size: 1rem !important;
  }
  .MobileInputStyleError::placeholder{
    padding: 0 !important;
    font-size: 1rem !important;
  }
}
