.MainContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.subContainer{
  margin-top: 45px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profileCompletionProgress{
  width: 100% !important;
  border: 1px solid green;
}

.MobileNumberOTP-Modal .ant-modal-title {
  font-size: 1.125rem;
  font-weight: 600;
  font-style: normal;
  color: #3e3e3e;
}

.MobileNumberOTP-Modal .ant-modal-header {
  padding: 1.25rem 1.875rem .875rem;
}

.MobileNumberOTP-cantiner {
  padding-top: 32px;
  width: 50%;
  
}

.MobileNumberOTP-Text-section {
  font-size: 24px;
  font-weight: 800;
  font-style: normal;
  color:#212121;
  margin-bottom: .625rem;
  display: ruby;
}
.mandatoryText{
  font-size: 10px !important;
  font-weight: 400 !important;
  color:#7E7E7E !important;
}

.otpErrorText{
  color: #DB2828;
  font-size: 10px;
  font-weight: 400;
}
.Mobile-otp-number-edit {
  font-size: .875rem;
  font-weight: 600;
  font-style: normal;
  color: #3e3e3e;
  display: flex;
  margin-bottom: 1.25rem;
}

.Mobile-otp-number-edit>div {
  font-size: .875rem;
  font-weight: 500;
  font-style: normal;
  color: #4054b2;
  padding-left: .625rem;
  cursor: pointer;
}

.Mobile-code-verification-enter-text {
  font-size: 12px;
  font-weight: 500;
  color: #3E3E3E;
  line-height: 14.32px;
  margin-bottom: .9375rem;
  margin-top: 10px;
}

.Mobile-code-verification-enter {
  margin-bottom: 1.25rem;
}

.OTP-submit-button {
  font-size: 16px;
  font-weight: 600;
  padding: .75rem 1.875rem;
  /* width: 10rem;
  height: 2.625rem; */
  border-radius: .25rem;
  background-color: #118936;
  color: #fff;
  border: none;
  margin-bottom: 1.25rem;
  cursor: pointer;
}
.edit-number{
  font-size: 12px;
  font-weight: 500;
  color:#4054B2;
  cursor: pointer;
  padding: 5px 0px;
  margin: 5px 0px;
  border: none;
  background: transparent;
  align-self: flex-start;
  height: 20px;
}
.resend-edit-container{
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.retryText{
  font-size: 12px;
  font-weight: 400;
  color:#3E3E3E;
  display: flex;
}

.ant-statistic-content{
  font-size: 12px;
  margin: 0 3px 2px 3px;
}


.MobileNumberOTP-Note-text-bottom {
  font-size: .75rem;
  font-weight: normal;
  color: #5e5e5e;
}

.MobileNumberOTP-Note-text-bottom>span {
  font-weight: 500;
  color: #4054b2;
  cursor: pointer;
}

.OtpInput-number {
  width: 4.5rem !important;
  height: 4.5rem;
  border-radius: .25rem;
  border: solid .0625rem rgba(180, 180, 180, 0.6);
  background-color: #fff;
}

.Mobile-code-verification-enter> :nth-child(1) {
  gap: .75rem;
}

.Mobile-otp-error-verification {
  font-size: .75rem;
  font-weight: normal;
  color: #db2828;
  margin-top: .625rem;
  margin-bottom: 1.25rem;
}

.OTP-margin-true {
  margin-bottom: unset !important;
}

.OtpInput-number-failed {
  border: .0625rem solid #db2828 !important;
}

@media screen and (max-width: 768px) {
  .MainContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.subContainer{
  margin-top: 12px;
  width: 100%;
}
.MobileNumberOTP-Text-section{
  font-size: 15px;
}

.OtpInput-number{
  box-sizing: border-box;
  width: 50px !important;
  height: 50px;
}
  .MobileNumberOTP-Modal .ant-modal-header {
      padding: .9375rem .9375rem .5625rem;
  }

  .MobileNumberOTP-Modal .ant-modal-title {
      font-size: 1rem;
      font-weight: 500;
      color: #3e3e3e;
  }

  .MobileNumberOTP-cantiner {
    margin-top: 30px;
    padding: .625rem .9375rem 1.25rem 1.52rem;
    width: 100%;
  }
  .Mobile-code-verification-enter-text{
    font-size: 12px;
  }

  .Mobile-otp-number-edit {
      margin-bottom: .9375rem;
  }

  .Mobile-code-verification-enter-text {
      font-size: .875rem;
  }

  .Mobile-otp-error-verification {
      font-size: .625rem;
  }

  .OTP-submit-button {
      padding: .75rem 1.875rem;
      font-size: .875rem;
      font-weight: 600;
      margin-bottom: .9375rem;
  }
}