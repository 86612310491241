.receive-bid-page {
  padding: 2.5rem;
  display: flex;
  background-color: white;
}

.receive-bid-page .received-bids-main-box {
  width: 75.188rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  /* align-items: center; */
}
.CreateImgNobids {
  width: 300px;
  height:250px;
  object-fit: contain;
}

.Frame-20343Nobids {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  /* width: 75rem; */
  height: 25rem;
  border-radius: 4px;
  border: solid 1px #d4d4d4;
  background-color: #fff;
}
.receive-bid-page .receive-bids-card-section {
  display: flex;
  /* justify-content: space-between; */
  gap: 0.938rem;
  align-items: center;
  flex-wrap: wrap;
}

.receive-bid-page .bid-card-skill-title {
  /* width: 1rem; */
  padding-left: 0.625rem;
  height: 3rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.receive-bid-page .sgigs-image-receiveBids {
  width: 100%;
  height: 7.5rem;
  position: absolute;
  top: 3.063rem;
  left: 0;
  right: 0;
  object-fit: cover;
}

.receive-bid-page .receive-bids-count {
  display: flex;
  flex-direction: column;
}

.receive-bids-count .receive-bid-fonts {
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.receive-bids-count .receive-bid-font-item {
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
  margin-top: 0.313rem;
}

.receive-bid-page .bid-filter-buttons {
  display: flex;
  align-items: center;
}

.receive-bid-page .filter-received-bids {
  width: max-content !important;
  height: 2.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.63rem;
  margin-right: 1.25rem;
  padding: 0.5rem 1.25rem;
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
  cursor: pointer;
}

.filterReceivedBidsDrawer .ant-drawer-header {
  background-color: #fff;
  border-bottom: 2px solid #e4e4e4;
  padding: 16px 15px;
}

.filterReceivedBidsDrawer .ant-drawer-close {
  position: absolute;
  padding: 0;
  width: fit-content;
  top: 16px !important;
  right: 0px;
}

.filterReceivedBidsDrawer .ant-drawer-title {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.filterReceivedBidsDrawer .ant-menu-vertical .ant-menu-item {
  line-height: 1.8rem !important;
  height: 2.06rem;
  margin: 0;
}

.filterReceivedBidsDrawer .ant-menu {
  padding-top: 0.2rem !important;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.receive-bid-page .sort-received-bids {
  width: 2.5rem;
  height: 2.5rem;
  /* margin: 40px 40px 13px 20px; */
  padding: 8px 8px 8px 8px;
  border-radius: 4px;
  border: solid 1px #e4e4e4;
  background-color: #fff;
  cursor: pointer;
}

.sortReceivedBidsDrawer .ant-drawer-header {
  background-color: #fff;
  border-bottom: 2px solid #f0f0f0;
}

.sortReceivedBidsDrawer .ant-drawer-title {
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.sortReceivedBidsDrawer .ant-drawer-close {
  padding: unset;
  width: fit-content;
  right: 15px;
  top: 7px;
}

.sortReceivedBidsDrawer .ant-drawer-body {
  /* padding: 0 15px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.radioSortReceivedBidsGroup {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1.2rem;
}

.radioSortReceivedBidsGroup .radioSortReceivedBids {
  display: flex;
  width: 100%;
  min-width: 22.63rem;
  height: 2.75rem;
  /* margin: 1.25rem 0.94rem 0.63rem; */
  padding: 0.6rem 0 0.75rem 0.75rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.15);
  border: solid 0.06rem #d4d4d4;
  background-color: #fff;
}

.radioSortReceivedBidsGroup .ant-radio-checked .ant-radio-inner {
  border-color: var(--primaryColor);
}

.radioSortReceivedBidsGroup .ant-radio-inner::after {
  background-color: var(--primaryColor);
}

.sortReceivedBidsDrawer .radioSortShowItemsParent {
  display: flex;
  justify-content: center;
  padding: 15px 20px;
  border-top: 1px solid #e4e4e4;
}

.radioSortShowItemsParent .radioSortShowItemsChild {
  min-width: 20rem;
  width: 100%;
  height: 2.63rem;
  text-align: center;
  padding: 0.7rem 0;
  border-radius: 0.31rem;
  background-color: var(--primaryColor);
  cursor: pointer;

  /* font */
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

/* .receive-bid-page .dropdownClassRB {
  width: max-content !important;
  height: 2.5rem;
  display: flex;
  gap: 10px;
  margin: 0 20px 0 0;
  border-radius: 4px;
  border: solid 1px #e4e4e4;
  background-color: #fff;
} */

.dropdownClassRB {
  top: 9rem !important;
  z-index: inherit;
}

.dropdownClassRB-new {
  top: 13.7rem !important;
}

.dropdownClassRB .ant-dropdown-menu {
  margin-top: 0.4rem;
  width: 22.25rem !important;
  /* height: 36.25rem; */
  padding: 0.7rem 0.5rem 1rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

.dropdownClassRB .ant-dropdown-menu-item:hover {
  background-color: unset;
  color: var(--primaryColor);
}

.receive-bid-page .received-bids-filter-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}

.receive-bid-page .parent-div-stagebar {
  padding: 0.5rem 0.5rem 0;
  margin-top: 1.3rem;
}

.receive-bid-page .received-bids-fontfilters {
  padding-top: 0.1rem;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.dropdownSort .ant-dropdown-menu {
  margin-top: 0.4rem;
  width: 18rem;
  /* height: 10.25rem; */
  flex-grow: 0;
  padding: 0.7rem 0 1rem 0.5rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

.receive-bid-page .sortItem-bids {
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.dropdownSort .ant-dropdown-menu-item:hover {
  background-color: unset;
  color: var(--primaryColor);
}

.greenTextRB {
  /* padding-right: 0.938rem; */
  color: var(--primaryColor);
}

.ReceivedStageNameColor{
  color: #3e3e3e;
  display: flex;
  align-items: center;
}

.Divider-talentStages{
  margin: 0.563rem 0.75rem 0.625rem 0.75rem;
  background-color: #e4e4e4;
  width: unset;
  min-width: unset;
}

@media screen and (max-width: 768px) {
  .receive-bid-page .received-bids-main-box {
    /* align-items: center; */
    width: unset;
  }

  .receive-bid-page .received-bids-filter-header {
    width: 100%;
    margin-bottom: 0.625rem;
  }
  .receive-bid-page .receiveBids {
    justify-content: center;
  }

  .receive-bid-page .bid-card-skill-title {
    height: 2.563rem;
  }

  .receive-bid-page .sgigs-image-receiveBids {
    top: 2.563rem;
  }

  .receive-bid-page .filter-received-bids {
    width: max-content !important;
    height: 1.75rem;
    padding: 0.31rem !important;
    margin-right: 0.625rem;
    padding: 0.5rem 1.25rem;
    border-radius: 0.25rem;
    border: solid 0.06rem #e4e4e4;
    background-color: #fff;
  }

  .receive-bid-page .received-bids-fontfilters {
    padding-top: 0.08rem;
    font-size: 0.75rem;
  }

  .receive-bid-page .filter-received-bids img {
    width: 1.13rem;
    height: 1.13rem;
    flex-grow: 0;
    object-fit: contain;
  }

  .receive-bid-page .sort-received-bids {
    width: 1.75rem;
    height: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.31rem;
  }

  .receive-bid-page .sort-received-bids img {
    width: 1.13rem;
    height: 1.13rem;
    flex-grow: 0;
    object-fit: contain;
  }

  /* .sgigs-thecard {
    width: 20.63rem;
  } */

  .receive-bid-page {
    padding: 1.25rem 0.938rem;
    display: inherit;
  }

  .Divider-talentStages {
    margin: 0.375rem 0.938rem 0.438rem 0.938rem;
  }
}

@media only screen and (min-width: 769px) {
  .dropdownSort {
    z-index: inherit;
  }
}