.main-div-read-rules {
  width: 100%;
  background-color: white;
}
.read-rules-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* padding: 0px 0px 100px; */
}

.read-rules-main {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 17px 60px 10px;
  gap: 70px;
}
.read-rules-left-section {
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: .9375rem;
}
.read-rules-left-section-heading {
  color: #1e1e1e;
  font-size: 28px;
  font-weight: 600;
  line-height: 42px;
}
.read-rules-left-section-div {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 29px;
}
.read-rules-left-section-text {
  max-width: 726px;
  font-size: 16px;
  /* display: flex; */
  flex-direction: column;

  color: #1e1e1e;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.read-rules-img {
  padding-top: 20px;
}
.btns-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  padding-top: 61px;
}
.continue-btn {
  display: flex;
  width: 200px;
  height: 42px;
  padding: 12px 64px 12px 65px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  background: #118936;
  color: #fff;
  cursor: pointer;
}
.back-btn {
  color: black;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
}

.btn-senctin-back {
  display: flex;
  max-width: 190px;
  height: 55px;
  padding: 12px 64px 12px 65px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  background: transparent;
  color: black;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  border: 1px solid black;
}

.mobile-view-img {
  display: block;
}
.underlineWithGapReadRule {
  text-decoration: underline;
  text-decoration-color: transparent;
  text-decoration-thickness: 1px;
  text-decoration-line: underline;
  text-decoration-skip-ink: none;
  text-underline-position: under;
  background-image: linear-gradient(to bottom, #4054b2, #4054b2);
  background-repeat: repeat-x;
  background-size: 2px 5px;
  background-position-y: 18px;
}
.continue-btn-read-rules {
  color: white;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}
.read-rules-icons-section img {
  width: 38px;
  height: 38px;
}

@media screen and (max-width: 768px) {
  .read-rules-img-mobile {
    display: contents;
  }

  .read-rule-getSupport {
    font-size: .75rem;
  }

  .read-rule-getSupport-main {
    padding: .625rem;
  }
  .read-rule-cantiner-section {
    align-items: center;
    margin-bottom: 9rem;
  }
  .read-rules-btn-section {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.25);
    padding: .625rem 0;
  }
}

@media screen and (max-width: 1000px) {
  .read-rules-img {
    display: none;
  }
  .read-rules-left-section-heading {
    font-size: 16px;
    /* margin-top: 30px; */
  }
  .read-rules-left-section-text {
    font-size: 14px;
    line-height: 22px;
  }
  .read-rules-main {
    padding: 10px 20px 25px;
    margin-top: -50px;
  }
  .read-rules-left-section-div {
    gap: 14px;
  }
  .read-rules-icons {
    width: 28px !important;
    height: 28px !important;
  }
  .btns-section {
    display: flex;
    flex-direction: column-reverse;
    gap: 19px;
    padding-top: 32px;
  }
  .mobile-view-img {
    min-width: 317px;
    height: 317px;
    margin-top: -17px;
  }
  .underlineWithGapReadRule {
    background-position-y: 15px;
  }
  .btn-senctin-back {
    max-width: 90%;
    width: 90%;
  }
}

.Read-rule-text-main-div {
    display: flex;
    align-items: flex-start;
    gap: 1.5625rem;
}

.Read-rule-text-main-Heading {
    font-size: 1rem;
    font-weight: bold;
    color: #1e1e1e;
    padding-bottom: .375rem;
}

.Read-rule-text-main-Text {
      font-size: 14px;
      font-weight: normal;
      color: #4e4e4e;
}

.Read-rule-text-main-div .read-rules-icons {
  width: 40px;
}

.read-rule-cantiner-section {
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
}

.read-rule-getSupport-main {
    padding: 10px 15px;
    border-radius: 4px;
    background-color: #f8f8f8;
}

.read-rule-getSupport {
  color: #1e1e1e;
  font-size: .875rem;
  font-weight: 600;
  padding-top: .3125rem;
}

.read-rule-getSupport-text {
  color: #4e4e4e;
  font-size: .75rem;
  font-weight: normal;
}

.read-rule-getSupport-text > span {
  font-weight: bold;
  color: #4054b2;
  cursor: pointer;
}

.read-rule-cantiner-section .btns-section {
  padding-top: unset;
}

